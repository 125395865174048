@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.preregistration-form {
  &__inner-fields {
    padding-top: 20px;
    padding-left: 40px;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      padding-left: 26px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      margin-top: 50px;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }

  &__help {
    position: relative;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      justify-content: center;
      margin-bottom: 30px;
      order: -1;
    }

    &:before {
      $size: 20px;

      content: '?';
      display: flex;
      justify-content: center;
      align-content: center;
      width: $size;
      height: $size;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      font-size: 16px;
      background: $color-primary;
      color: $color-white;
      margin-right: 10px;

      @include media-breakpoint-down(xs) {
        $size: 16px;

        width: $size;
        height: $size;
        font-size: 14px;
        padding-top: 2px;
        margin-right: 12px;
      }
    }
  }
}
