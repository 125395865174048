@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.modal {
  max-width: 760px;
  padding: 40px;

  @include media-breakpoint-down(xs) {
    padding: 32px 24px;
  }
}
.content {
  font-size: 20px;
  text-align: center;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }
}
.icon {
  font-size: 72px;
  margin-bottom: 24px;

  @include media-breakpoint-down(xs) {
    font-size: 48px;
  }
}
.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;

  @include media-breakpoint-down(xs) {
    font-size: 28px;
  }
}
.footer {
  margin-top: 40px;

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }
}
.button:not(:last-child) {
  margin-right: 24px;

  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-bottom: 24px;
    margin-right: 0;
  }
}
