@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.chat-message__image {
  &-wrapper {
    width: 100%;
    height: 100%;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    border-radius: inherit;
    cursor: pointer;
  }
  &-modal {
    .image-modal__picture-block {
      width: auto;
      max-width: 850px;
    }
  }
}
