@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.search {
  position: relative;
  height: 50px;
  min-width: 440px;
  @include transition(min-width);

  @include media-breakpoint-down(xl) {
    min-width: 340px;
  }

  @include media-breakpoint-down(xs) {
    height: 33px;
    min-width: auto;
  }

  &__inner {
    display: flex;
    height: inherit;
    align-items: center;
    padding-right: 20px;

    @include media-breakpoint-down(xs) {
      flex-direction: row-reverse;
      padding-right: 0;
      padding-left: 10px;
    }
  }

  &__input {
    z-index: 1;
    flex-grow: 1;

    @include placeholder {
      color: rgba($color-text, 0.5);
    }
  }

  &__mat {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: 100%;
    @include white-sheet;
    @include transition(box-shadow);

    @include media-breakpoint-down(xs) {
      border-radius: 5px;
    }
  }

  &__input:focus ~ &__mat {
    box-shadow: 0 2px 10px rgba($color-primary, 0.3);
  }

  &__icon {
    cursor: pointer;
    z-index: 1;
    display: flex;
    padding: 15px 20px;
    align-items: center;

    .svg-search {
      color: $color-gray-100;
      @include transition(color);
    }

    @include media-breakpoint-down(xs) {
      padding: 8px 10px;

      .svg-search {
        width: 18px;
      }
    }
  }

  &__icon:hover .svg-search {
    color: $color-primary;
  }
}
