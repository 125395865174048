.file-image-cover {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  border-radius: inherit;
  overflow: hidden;
}
