@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.delete-button {
  $size: 20px;
  $margin: 5px;

  position: absolute;
  top: -$margin;
  right: -$margin;
  z-index: 10;
  height: $size;
  width: $size;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: $color-white;
  box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
  @include transition(box-shadow);

  &:hover {
    box-shadow: 0 1px 10px rgba($color-primary, 0.75);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: $color-crimson-100;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
