@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.alert {
  .modal__popup-inner {
    margin-bottom: 30px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 45px;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__button {
    flex: 0 0 auto;
    margin-right: 10px;
    @include last-child(right);

    .button {
      min-width: 120px;
    }

    @include media-breakpoint-down(xs) {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
      @include last-child();

      .button {
        min-width: none;
        width: 100%;
      }
    }
  }
}
