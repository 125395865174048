@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.weight-detail-modal {
  .modal__popup {
    @include media-breakpoint-up(sm) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}
.weight-detail {
  padding-top: 24px;
  padding-left: 10px;
  padding-right: 10px;
  &__main {
    @include media-breakpoint-up(md) {
      display: flex;
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  &__col {
    @include media-breakpoint-up(md) {
      width: 50%;
      padding: 0 30px;
    }
  }
  .data-table__value {
    color: $color-primary;
    white-space: nowrap;
  }
  &__reference {
    margin-top: 8px;
    border-bottom: 1px solid $color-gainsboro-100;

    @include media-breakpoint-up(sm) {
      margin-top: 24px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 40px;
    }
  }
  &__about {
    font-size: 14px;

    @include media-breakpoint-up(xs) {
      font-size: 16px;
    }
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }
  &__mydata {
    margin-top: 24px;
  }
  &__meter {
    position: relative;
    width: 336px;
    margin: -24px auto 50px;
    @include media-breakpoint-down(xxs) {
      position: relative;
      left: 50%;
      transform: scale(0.9) translateX(-50%);
      transform-origin: 0 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(xxxs) {
      transform: scale(0.8) translateX(-50%);
      margin-bottom: -10px;
    }
  }

  &__meter-arrow-area {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -65px;
    width: 130px;
    height: 2px;
  }
  &__meter-arrow {
    position: absolute;
    top: 4px;
    left: -50%;
    background-color: $color-black;
    width: 65px;
    height: 2px;
    border-radius: 1px;
    &::before {
      content: '';
      position: absolute;
      background-color: $color-black;
      width: 8px;
      height: 8px;
      top: calc(50% - 4px);
      border-radius: 50%;
      right: -4px;
    }
  }
  &__meter-text {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
  }
  &__meter-num {
    font-size: 24px;
    font-weight: 700;
  }
}
