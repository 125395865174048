@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.circle-graph {
  display: block;
  padding-top: 100%;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__item {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
    stroke: $color-primary;
    stroke-width: 40px;
  }
  &__item-basis {
    opacity: 0.5;
  }
  &__item-progress {
    transform: rotate(-90deg) translateX(-100%);
    transition: 0.8s stroke-dashoffset ease;
  }
}
