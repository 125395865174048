$gaps: (
  'none': 0,
  'tiny': 8px,
  'xtiny': 16px,
  'xxtiny': 24px,
  'small': 30px,
  'xsmall': 40px,
  'medium': 50px,
  'xmedium': 60px,
  'xxmedium': 70px,
  'xxlarge': 100px,
);

@mixin marginPaddingAround {
  $types: (
    'p': 'padding',
    'm': 'margin',
  );

  @each $name, $gap in $gaps {
    @each $type, $style in $types {
      .-#{$type}__#{$name} {
        #{$style}: #{$gap};
      }

      .-#{$type}__#{$name}-negative {
        #{$style}: -#{$gap};
      }

      @each $media-size, $size in $media-sizes {
        @media (max-width: $size) {
          .-#{$type}__#{$media-size}-#{$name} {
            #{$style}: #{$gap};
          }
          .-#{$type}__#{$media-size}-#{$name}-negative {
            #{$style}: -#{$gap};
          }
        }
        @media (min-width: $size + 1) {
          .-#{$type}__#{$media-size}-#{$name}-up {
            #{$style}: #{$gap};
          }
          .-#{$type}__#{$media-size}-#{$name}-up-negative {
            #{$style}: -#{$gap};
          }
        }
      }
    }
  }
}

@mixin sidesStylesGeneration($sides) {
  @each $name, $gap in $gaps {
    @each $side, $style in $sides {
      .-#{$side}__#{$name} {
        #{$style}: $gap !important;
      }
    }
  }

  @each $name, $gap in $gaps {
    @each $side, $style in $sides {
      .-#{$side}__#{$name}-negative {
        #{$style}: -$gap !important;
      }
    }
  }

  @each $name, $size in $media-sizes {
    @media (max-width: $size) {
      @each $type, $value in $gaps {
        @each $side, $style in $sides {
          .-#{$side}__#{$name}-#{$type} {
            #{$style}: $value !important;
          }
          .-#{$side}__#{$name}-#{$type}-negative {
            #{$style}: -$value !important;
          }
        }
      }
    }

    @media (min-width: $size + 1) {
      @each $type, $value in $gaps {
        @each $side, $style in $sides {
          .-#{$side}__#{$name}-#{$type}-up {
            #{$style}: $value !important;
          }
          .-#{$side}__#{$name}-#{$type}-negative-up {
            #{$style}: -$value !important;
          }
        }
      }
    }
  }
}

@mixin blockViewsGeneration($types) {
  @each $type in $types {
    .-d__#{$type} {
      display: #{$type} !important;
    }
  }

  @each $type in $types {
    @each $name, $size in $media-sizes {
      @media (max-width: $size) {
        .-d__#{$name}-#{$type} {
          display: #{$type} !important;
        }
      }
      @media (min-width: $size + 1) {
        .-d__#{$name}-#{$type}-up {
          display: #{$type} !important;
        }
      }
    }
  }
}

@mixin marginAutoGenerator {
  $sides: (
    'ml': 'margin-left',
    'mr': 'margin-right',
  );

  @each $side, $style in $sides {
    .-#{$side}__auto {
      #{$style}: auto;
    }

    @each $name, $size in $media-sizes {
      @media (max-width: $size) {
        .-#{$side}__#{$name}-auto {
          #{$style}: auto;
        }
      }
      @media (min-width: $size + 1) {
        .-#{$side}__#{$name}-auto-up {
          #{$style}: auto !important;
        }
      }
    }
  }
}

//Modificators

.-text__center {
  text-align: center;
}

@each $name, $size in $media-sizes {
  @media (max-width: $size) {
    .-text__center-#{$name} {
      text-align: center;
    }
  }
}

.-center {
  align-items: center;
  justify-content: center;
}

.-column {
  flex-direction: column;
}

@each $name, $size in $media-sizes {
  @media (max-width: $size) {
    .-column__#{$name} {
      flex-direction: column;
    }
  }
}

.-justify__center {
  justify-content: center;
}

.-justify__around {
  justify-content: space-around;
}

.-justify__between {
  justify-content: space-between;
}

.-justify__end {
  justify-content: flex-end;
}

.-align__center {
  align-items: center;
}

.-align-self__end {
  align-self: flex-end;
}

.-grow__1 {
  flex-grow: 1;
}

$types: (
  'block',
  'flex',
  'grid',
  'inline',
  'inline-block',
  'inline-flex',
  'none'
);

@include blockViewsGeneration($types);

$lengths: (25, 50, 75, 100);

@each $length in $lengths {
  $percent: '%';

  .-width__#{$length} {
    width: #{$length}#{$percent};
  }
}

@each $name, $size in $media-sizes {
  @media (max-width: $size) {
    @each $length in $lengths {
      $percent: '%';

      .-width__#{$name}-#{$length} {
        width: #{$length}#{$percent};
      }
    }
  }
}

.-mx-none {
  margin-right: 0;
  margin-left: 0;
}

$margins: (
  'mt': 'margin-top',
  'mr': 'margin-right',
  'mb': 'margin-bottom',
  'ml': 'margin-left',
);

$paddings: (
  'pt': 'padding-top',
  'pr': 'padding-right',
  'pb': 'padding-bottom',
  'pl': 'padding-left',
);

@include sidesStylesGeneration($margins);
@include sidesStylesGeneration($paddings);
@include marginAutoGenerator;
@include marginPaddingAround;

$textColors: (
  'gray': $color-darkgray-200,
  'red': $color-crimson-100,
  'green': $color-limegreen-100,
  'blue': $color-mediumturquoise-100,
  'purple': $color-mediumslateblue-100,
);

@each $color, $value in $textColors {
  .-text__#{$color} {
    color: $value !important;
  }
}

$fontWeights: (400, 500, 700);

@each $fontWeight in $fontWeights {
  .-font__#{$fontWeight} {
    font-weight: $fontWeight !important;
  }
}
