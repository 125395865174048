@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.health-index-scale {
  $checkpointMarginX: 20px;
  $checkpointWidth: 116px;
  $tickWidth: 1px;
  $tickHeight: 19px;
  $tickMargin: -31px;
  $color: $color-primary;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color;
  line-height: 1.3;

  &__checkpoint {
    position: absolute;
    font-size: 12px;
    line-height: 1.2;
    @include media-breakpoint-up(xxxs) {
      font-size: 14px;
    }
    @include media-breakpoint-up(xxs) {
      font-size: 16px;
    }
    @include media-breakpoint-up(xs) {
      font-size: 21px;
    }
  }

  &__top {
    top: 29px;
  }
  &__bottom {
    bottom: 27px;
  }
  &__left {
    left: $checkpointMarginX;
    width: $checkpointWidth;
    text-align: right;
    transform: translateX(-100%);
    @include media-breakpoint-down(xxs) {
      transform: translateX(-115%);
    }
    @include media-breakpoint-down(xxxs) {
      transform: translateX(-120%);
    }
  }
  &__right {
    right: $checkpointMarginX;
    width: $checkpointWidth;
    text-align: left;
    transform: translateX(100%);
    @include media-breakpoint-down(xxs) {
      transform: translateX(115%);
    }
    @include media-breakpoint-down(xxxs) {
      transform: translateX(120%);
    }
  }
  &__values {
    text-align: center;
    padding-top: 6px;
  }
  &__text {
    font-size: 14px;
    @include media-breakpoint-up(xxs) {
      font-size: 18px;
    }
    @include media-breakpoint-up(xs) {
      font-size: 24px;
    }
  }
  &__value {
    font-size: 30px;
    font-weight: bold;
    padding: 6px 0 3px;
    line-height: 1;
    @include media-breakpoint-up(xxs) {
      padding: 20px 0 10px;
      font-size: 40px;
    }
    @include media-breakpoint-up(xs) {
      font-size: 50px;
    }
  }

  &__tick {
    position: absolute;
    z-index: 10;
    background: $color;
  }
  &__tick-vertical {
    width: $tickWidth;
    height: 5%;
    left: 50%;
  }

  &__tick-horizontal {
    width: 5%;
    height: $tickWidth;
    top: 50%;
  }
  &__tick-top {
    top: -8%;
  }
  &__tick-right {
    right: -8%;
  }
  &__tick-bottom {
    bottom: -8%;
  }
  &__tick-left {
    left: -8%;
  }
}
