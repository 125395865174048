@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.medicines-list {
  @include media-breakpoint-up(xs) {
    padding: 22px 10px 22px 20px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 40px;
    padding-right: 16px;
  }
  &__list {
    @include media-breakpoint-up(xs) {
      padding-right: 20px;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 26px;
    }
  }
  &__scrollable {
    @include media-breakpoint-up(lg) {
      max-height: 660px;
      overflow: auto;
    }
  }
  &__item {
    padding: 14px 0;
    border-bottom: 1px solid $color-gainsboro-100;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
