@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.handbook {
  &__titleline .titleline__title {
    @include media-breakpoint-down(xs) {
      order: 2;
      padding-right: 0;
      margin-bottom: 0;
    }
  }

  &__search {
    @include media-breakpoint-down(xs) {
      order: 1;
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
