@import './../variables.scss';
@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.tariffs {
  margin-top: -#{$marginTopGrid};
  align-items: stretch;

  @include media-breakpoint-down(xs) {
    margin-top: -#{$marginTopGridMobile};
  }
}
