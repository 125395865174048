@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.header {
  color: $color-white;
  background: $color-white;
  box-shadow: 0 2px 10px rgba($color-black-200, 0.3);
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 55px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none;
    min-height: 60px;
    background: linear-gradient(
      90.97deg,
      $color-lightskyblue-100 0.19%,
      $color-orchid-100 98.52%
    );
  }

  &__inner,
  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__inner {
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      line-height: 18px;
      font-size: 16px;
    }
  }

  &__left {
    flex-grow: 1;
  }

  &__logo {
    width: 70px;
  }

  &__logo-mobile {
    font-size: 0;
    line-height: 1;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__app-links {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__support-tel {
    flex-grow: 0;
    margin-right: 150px;

    @include media-breakpoint-down(xl) {
      margin-right: 50px;
    }

    @include media-breakpoint-down(xs) {
      margin-right: 30px;
    }
  }
}

[data-sidebar-short='false'] .header {
  .header__app-links {
    @media (max-width: $media-lg - 100px) {
      display: none;
    }
  }
}
