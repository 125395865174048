@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.page--main-template {
  .page__wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }
  }

  // TODO: make sidebar sticky
  .page__aside {
    grid-column: 1;
    grid-row: 1 / 3;
    z-index: 20;

    @include media-breakpoint-down(md) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      transform: translateX(100%);
    }
  }

  .page__header {
    grid-column: 2;
    grid-row: 1;
    position: relative;
    z-index: 30;
  }
  .page__footer {
    @include media-breakpoint-up(md) {
      display: none;
      // position: absolute;
      // top: 0;
      // right: 0;
      // bottom: 0;
      // visibility: hidden;
      // transform: translateX(100%);
    }
  }

  .page__container {
    grid-column: 2;
    grid-row: 2 / 3;
    position: relative;
    z-index: 10;
    overflow: hidden;
    background: $color-ghostwhite-100;
    padding-top: 40px;
    padding-bottom: 32px;

    @include media-breakpoint-down(md) {
      padding-top: 26px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .grid__container {
    @include media-breakpoint-up(md) {
      max-width: $block-max-width;
    }
  }
  .reset-container-paddingX {
    @include media-breakpoint-down(lg) {
      left: -$grid-gap;
      width: calc(100% + #{$grid-gap} * 2);
    }
  }
  &-tabs {
    position: relative;

    &__tab {
      width: 200px;
    }
  }
}
