@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.chat {
  $doctorAvatarSize: 40px;
  $doctorAvatarSizeMobile: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  overflow-x: hidden;

  &__wrapper {
    position: relative;
    z-index: 10;
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(100vh - 280px);

    @include media-breakpoint-down(md) {
      max-height: calc(100vh - 120px);
    }
  }

  &__area {
    $marginY: 20px;
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    // overflow-y: auto;
    overflow-y: hidden;
    margin-bottom: 5px;

    &-scroll {
      display: flex;
      flex-direction: column;
      padding-right: 25px;

      @include media-breakpoint-down(xl) {
        padding-right: 10px;
      }
    }
    &-button {
      display: none;
    }
  }
  &__message {
    position: relative;
    width: 480px;
    font-size: 18px;
    line-height: 1.3;
    padding: 14px 5px 26px 20px;

    @include media-breakpoint-down(xs) {
      width: 260px;
      font-size: 14px;
      line-height: 16px;
      padding: 8px 10px 17px;
    }

    &-group {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &:not(:last-child) {
        margin-bottom: 30px;

        @include media-breakpoint-down(xs) {
          margin-bottom: 25px;
        }
      }

      &-outgoing {
        align-self: flex-end;
        align-items: flex-end;
        color: $color-white;

        & .chat__message {
          background: $color-mediumslateblue-200;
          margin-bottom: 8px;
          border-radius: 16px 16px 0 16px;

          @include media-breakpoint-down(xs) {
            margin-bottom: 10px;
          }

          // &:nth-child(odd) {
          //   border-radius: 16px 16px 0 16px;
          // }

          // &:nth-child(even) {
          //   border-radius: 16px 0 16px 16px;
          // }
        }

        & .chat__message-time {
          color: rgba($color-white, 0.6);
        }

        & .chat__message-file-download {
          color: rgba($color-white, 0.6);

          &:hover {
            color: $color-white;
          }
        }
      }
      &-incoming {
        align-self: flex-start;
        align-items: flex-start;
        color: $color-text;
        padding-left: $doctorAvatarSize / 2;

        @include media-breakpoint-down(xs) {
          padding-left: $doctorAvatarSizeMobile / 2;
        }

        & .chat__message {
          background: $color-white;
          box-shadow: 0 2px 10px rgba($color-black-200, 0.25);
          border-radius: 16px 16px 16px 0;
          margin-bottom: $doctorAvatarSize / 2 + 10px;

          @include media-breakpoint-down(xs) {
            margin-bottom: $doctorAvatarSizeMobile / 2 + 15px;
          }
        }

        & .chat__message-time {
          color: rgba($color-text, 0.6);
        }

        & .file__icon {
          color: $color-primary;
        }

        & .chat__message-file-download {
          color: rgba($color-text, 0.6);

          &:hover {
            color: $color-text;
          }
        }
        & .audio-player {
          background: rgba($color-primary, 0.5);
        }
        & .audio-player__progressbar {
          background: rgba($color-primary, 1);
        }
      }
    }

    &-time {
      position: absolute;
      bottom: 0;
      right: 0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      font-size: 14px;
      line-height: 16px;
      padding: 5px 10px;

      @include media-breakpoint-down(xs) {
        font-size: 10px;
        line-height: 11px;
      }
    }

    &-avatar {
      $size: $doctorAvatarSize;
      $margin: $size / 2;

      position: absolute;
      bottom: -$margin;
      left: -$margin;
      width: $size;
      height: $size;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: $color-white;
      box-shadow: 0 2px 6px rgba($color-black-200, 0.25);
      padding: 3px;

      @include media-breakpoint-down(xs) {
        $size: $doctorAvatarSizeMobile;
        $margin: $size / 2;

        width: $size;
        height: $size;
        bottom: -$margin;
        left: -$margin;
      }

      &-image {
        width: 100%;
        height: 100%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        -webkit-background-size: cover;
        background-size: cover;
        background-color: $color-cornflowerblue-100;
      }
    }
    &-photo {
      width: 120px;
      height: 120px;
      display: block;
      position: relative;
      margin-bottom: 20px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-text {
      max-width: 390px;
      overflow: hidden;
    }
    &-text-title {
      font-weight: bold;
      margin-bottom: 12px;
    }
    &-text-p {
      padding: 8px 0;
    }

    &-admin {
      position: absolute;
      bottom: -4px;
      left: $doctorAvatarSize / 2 + 16px;
      color: rgba($color-text, 0.6);
      font-size: 14px;
      line-height: 16px;
      transform: translateY(100%);

      @include media-breakpoint-down(xs) {
        left: $doctorAvatarSizeMobile / 2 + 10px;
        font-size: 10px;
        line-height: 11px;
      }
    }

    &-image {
      $size: 310px;

      width: $size;
      height: $size;
      padding: 5px;

      @include media-breakpoint-down(xs) {
        $size: 196px;

        width: $size;
        height: $size;
        padding: 1px;
      }

      & .chat__message-time {
        right: 5px;
        bottom: 5px;
        border-top-left-radius: 16px;
        border-bottom-right-radius: 0;
        color: $color-white;
        background: rgba($color-black, 0.4);
      }
    }

    &-file {
      padding-bottom: 8px;

      &-inner {
        overflow: hidden;
        padding-right: 0;
        margin-right: 29px;
      }
      & .file__title {
        font-size: 18px;
        line-height: 21px;
        min-height: 22px;

        @include media-breakpoint-down(xs) {
          font-size: 14px;
          line-height: 16px;
          min-height: 18px;
        }
      }

      & .file__icon {
        flex-shrink: 0;
        width: 23px;
        margin-right: 20px;

        @include media-breakpoint-down(xs) {
          width: 21px;
          margin-right: 12px;
        }
      }

      &-download {
        position: absolute;
        top: 5px;
        right: 10px;
        z-index: 10;
        @include transition(color);
      }
    }
    &-audio-playing-file {
      padding-right: 55px;

      & .svg-pause {
        width: 17px;

        @include media-breakpoint-down(xs) {
          width: 13px;
        }
      }
    }
  }
  &__date-divider {
    color: $color-darkgray-200;
    font-size: 14px;
    text-align: center;
    padding: 0 0 30px;

    @include media-breakpoint-down(md) {
      padding: 0 0 16px;
    }
  }
  &__message-author {
    color: $color-darkgray-200;
    font-size: 14px;
    position: absolute;
    left: 30px;
    top: calc(100% + 3px);

    @include media-breakpoint-down(xs) {
      font-size: 12px;
      left: 20px;
    }
  }
}

.chat-page .page__wrapper {
  height: 100vh;
}
.chat-page .page__container {
  padding-bottom: 30px;

  @include media-breakpoint-down(md) {
    padding-top: 15px;
  }
}
.page--main-template.chat-page .page__container {
  overflow: auto;
}
.chat-page .titleline {
  margin-bottom: 20px;

  @include media-breakpoint-down(md) {
    margin-bottom: 15px;
  }
  @include media-breakpoint-down(xs) {
    margin-bottom: 5px;
  }
}
