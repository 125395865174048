@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.health-main {
  @include media-breakpoint-up(xs) {
    &__titleline {
      min-height: 50px;
    }
  }
  @include media-breakpoint-down(xs) {
    &__titleline {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(xs) {
    &-tabs__tab-list {
      margin-bottom: 55px;
    }
  }
}
