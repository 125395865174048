@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.health-index {
  &__chart-block#{&}__chart-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 20px 20px;
    @include media-breakpoint-up(xxs) {
      padding-top: 50px;
      padding-bottom: 30px;
    }
    @include media-breakpoint-up(xs) {
      padding-top: 70px;
      padding-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
      justify-content: space-around;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &__graph {
    width: 400px;
    max-width: 68%;
    margin: 0 auto;
    @include media-breakpoint-up(xl) {
      width: 450px;
    }
  }
  &__graph-inner {
    position: relative;
  }
  &__description {
    font-weight: 500;
    max-width: 560px;
    font-size: 14px;
    line-height: 1.35;
    text-align: center;
    color: $color-primary;
    margin: 40px auto 0;
    @include media-breakpoint-up(xxxs) {
      font-size: 16px;
    }
    @include media-breakpoint-up(xxs) {
      font-size: 18px;
      margin-top: 50px;
    }
    @include media-breakpoint-up(xs) {
      font-size: 20px;
      margin-top: 60px;
    }
  }
}
