@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.logout {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  color: $color-primary;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    color: $color-white;
  }

  &__icon {
    color: currentColor;
    margin-right: 10px;
  }
}
