@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.item {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    $margin: 15px;

    border-bottom: 2px solid $color-lavender-200;
    padding-bottom: $margin;
    margin-bottom: $margin;
  }
}
.wrapper {
  flex-grow: 1;

  &__with-margin {
    padding-right: 10px;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.name {
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }
}
.text {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.info {
  flex-grow: 1;
  margin-right: 10px;
}
.limit {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  color: $color-palevioletred-100;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
    line-height: 14px;
  }
}
.description {
  font-size: 14px;
  line-height: 140%;
  color: $color-darkslateblue-100;
  margin-bottom: 10px;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }

  &__with-margin {
    margin-top: 10px;
  }
}
.button {
  flex-shrink: 0;
  padding: 10px;
  max-width: 84px;
}
.expand {
  $color: $color-deepskyblue-100;

  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  cursor: pointer;
  color: $color;
  @include transition(color);

  &:hover {
    color: darken($color, 15);
  }
}
