@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-viewer {
  $min-height-sm: 550px;
  $min-height-lg: 750px;
  position: relative;

  &__left {
    max-width: 100%;
    padding: 10px 10px 10px 0;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 0;
      bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      width: 45%;
      padding: 20px 10px 20px 0;
    }
    @include media-breakpoint-up(lg) {
      width: 35.7%;
      padding: 30px 10px 30px 0;
    }
  }

  &__right {
    margin-left: auto;
    display: none;
    position: relative;
    @include media-breakpoint-up(sm) {
      width: 55%;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      min-height: $min-height-sm;
    }

    @include media-breakpoint-up(lg) {
      width: 64.3%;
      padding-left: 24px;
      min-height: $min-height-lg;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 40px;
    }
  }

  &__right-empty-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: $color-darkslategray-100;
  }

  &__modal-detail {
    .modal__popup {
      padding-top: 64px;
      padding-bottom: 20px;

      @include media-breakpoint-up(xxs) {
        padding-bottom: 40px;
      }
    }
    .modal__content {
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 10px;
      margin-right: -10px;
    }
  }

  &__detail-block {
    display: flex;
    flex-direction: column;
    & + & {
      margin-top: 20px;
    }

    @include media-breakpoint-up(sm) {
      padding: 20px;
    }

    @include media-breakpoint-up(lg) {
      padding: 40px;
    }
    .paginated-list {
      flex-grow: 1;
    }
  }
  &__detail-section + &__detail-section {
    margin-top: 20px;
  }

  &__detail {
    display: flex;
    flex-direction: column;
  }

  &__detail-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-primary;
    margin-bottom: 10px;
  }
  &__detail-subtitle {
    font-weight: bold;
    margin-bottom: 6px;
  }

  &__detail-tooltip {
    flex-shrink: 0;
    margin-left: 10px;
    margin-bottom: 3px;

    & .explanation__tooltip {
      width: 425px;
    }
  }

  &__detail-tooltip-icon {
    width: 2px;
  }

  &__detail-description {
    @include media-breakpoint-up(sm) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }

    font-size: $font-size;
    color: $color-text;

    &--empty {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-darkgray-200;
    }
  }

  &__detail-scrollable {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px 0 0;
    margin-right: -20px;
    max-height: 366px;
    overflow-y: auto;

    @include media-breakpoint-down(lg) {
      margin-right: -12px;
      padding-right: 12px;
    }
  }

  &__detail-scrollable--full {
    max-height: 510px;
    @include media-breakpoint-up(lg) {
      max-height: 670px;
    }
  }

  &__detail-scrollable--full#{&}__detail-paginated {
    max-height: 460px;
    @include media-breakpoint-up(lg) {
      max-height: 620px;
    }
  }

  &__detail-paginated {
    margin-bottom: auto;
  }
  .paginated-list__pager {
    margin-top: 20px;
  }
  &__detail-image-ok {
    display: block;
    width: 384px;
    max-width: 72.5%;
    margin: 0 auto;
  }
  &__detail-pic-btn {
    margin-top: 30px;

    @include media-breakpoint-up(xl) {
      margin-top: 40px;
    }
  }

  &__detail-error-text {
    width: 596px;
    max-width: 90%;
    margin: auto;
    text-align: center;
    color: $color-darkgray-200;

    @include media-breakpoint-down(sm) {
      padding: 24px 0;
      text-align: left;
      max-width: 100%;
    }
  }
  &__detail-error-p + &__detail-error-p {
    margin-top: 20px;
  }
  &__modal-detail-image {
    margin-top: 20px;
  }

  &__modal-detail-label {
    font-size: 12px;
    margin-bottom: 10px;
  }
  &__modal-detail-info {
    margin-top: 40px;
  }
  &__modal-detail-title {
    font-size: 16px;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__modal-detail-subtitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  &__modal-detail-text {
    font-size: 16px;
  }
}
