@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.list-mode-toggle {
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      cursor: pointer;
      color: $color-darkgray-100;
      @include transition(color);

      &:hover,
      &-checked {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    &__icon {
      width: 24px;
    }
  }
}
