@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.img-preview {
  width: 100px;
  @include media-breakpoint-up(lg) {
    width: 227px;
  }
  &__img-wrap {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: $color-darkgray-100;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -300px;
      z-index: -1;
      width: 150px;
      opacity: 0;
      pointer-events: none;
      background: linear-gradient(
        to right,
        transparent 0%,
        #c4c4c4 50%,
        transparent 100%
      );
    }

    img {
      display: block;
      max-width: 100%;
    }

    &--square {
      padding-top: 100%;
      img {
        cursor: pointer;
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.4s;
        will-change: transform;
        &:hover {
          transform: scale(1.03);
        }
      }
    }

    &--loading {
      img {
        z-index: 5;
      }
      &::after {
        opacity: 1;
        z-index: 2;
        animation: loading 1.1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }
    }
  }
}

@keyframes loading {
  from {
    left: -150px;
  }
  to {
    left: 110%;
  }
}
