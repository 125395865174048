@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

@mixin buttonSolid($code, $primaryColor, $secondaryColor) {
  &__#{$code} {
    background: $primaryColor;
    color: $secondaryColor;

    &:hover {
      $boxShadowColor: $primaryColor;

      -webkit-box-shadow: 0 4px 10px rgba($boxShadowColor, 0.3);
      -moz-box-shadow: 0 4px 10px rgba($boxShadowColor, 0.3);
      box-shadow: 0 4px 10px rgba($boxShadowColor, 0.3);
    }
  }
}

@mixin buttonSolidTransparent(
  $code,
  $primaryColor,
  $secondaryColor,
  $hovercolor
) {
  &__#{$code} {
    background: $primaryColor;
    color: $secondaryColor;

    &:hover {
      background: $hovercolor;
    }
  }
}

@mixin buttonOutline($code, $primaryColor, $secondaryColor, $hoverColor) {
  &__#{$code}-outline {
    border: 2px solid;
    background: transparent;
    color: $primaryColor;
    border-color: currentColor;

    &:hover {
      color: $hoverColor;
      border-color: currentColor;
    }
  }
}

.button {
  $paddingX: 29px;
  $paddingY: 17px;

  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  cursor: pointer;
  // outline: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: $transition-main;
  padding: $paddingY $paddingY;

  &__outline {
    padding: ($paddingY - 2) ($paddingX - 2);
  }

  //button sizes
  &__short {
    $paddingX: 28px;
    $paddingY: 10px;

    font-weight: 500;
    text-transform: none;
    padding: $paddingY $paddingX;

    &.button__outline {
      padding: ($paddingY - 2) ($paddingX - 2);
    }
  }

  &--no-uppercase {
    text-transform: none;
  }

  &--full-width {
    width: 100%;
  }

  //button themes
  @include buttonSolid('primary', $color-primary, $color-white);
  @include buttonSolid(
    'mediumslateblue',
    $color-mediumslateblue-100,
    $color-white
  );
  @include buttonSolidTransparent(
    'transparent',
    $color-mediumslateblue-transparent-200,
    $color-primary,
    $color-primary-transparent
  );

  @include buttonSolid('crimson', $color-crimson-100, $color-white);
  @include buttonOutline(
    'mediumslateblue',
    $color-mediumslateblue-100,
    $color-white,
    $color-slateblue-100
  );

  &__textual {
    $color: $color-darkgray-200;

    color: $color;
    @include transition(color);

    &:hover {
      color: darken($color, 15);
    }
  }

  &--is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__file {
    position: relative;

    & ._react-file-reader-input {
    }
  }
}

.text-button {
  @include reset-button;
}
