@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.health-index-note {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;

  &#{&} {
    padding: 20px;

    @include media-breakpoint-up(xxs) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include media-breakpoint-up(xs) {
      padding-top: 36px;
      padding-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @include media-breakpoint-up(xxs) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xs) {
    font-size: 18px;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 20px;
  }

  @include media-breakpoint-between(lg, xl) {
    margin-top: 10px;
  }

  &__title {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  &__warning {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $color-crimson-100;
    color: $color-white-100;
    @include media-breakpoint-up(xxs) {
      width: 21px;
      height: 21px;
    }
  }
}
