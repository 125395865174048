.grid {
  &__root {
    width: 100%;
    padding-left: $grid-gap;
    padding-right: $grid-gap;
    @include media-breakpoint-down(xxxs) {
      padding-left: $grid-gap-small;
      padding-right: $grid-gap-small;
    }
    @include media-breakpoint-up(xxs) {
      padding-left: $grid-gap-medium;
      padding-right: $grid-gap-medium;
    }
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gap-large;
      padding-right: $grid-gap-large;
    }
  }

  &__container {
    width: 100%;
    max-width: $container-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gap;
    margin-right: -$grid-gap;
    @include media-breakpoint-down(sm) {
      margin-left: -$grid-gap-small;
      margin-right: -$grid-gap-small;
    }
  }

  &__item {
    padding-left: $grid-gap;
    padding-right: $grid-gap;
    @include media-breakpoint-down(sm) {
      padding-left: $grid-gap-small;
      padding-right: $grid-gap-small;
    }

    @for $i from 0 through $grid-columns-count {
      &-#{$i}x {
        flex: 0 0 ($i / $grid-columns-count) * 100%;
        max-width: ($i / $grid-columns-count) * 100%;
      }
    }

    @each $name, $size in $media-sizes {
      @media (max-width: $size) {
        @for $i from 0 through $grid-columns-count {
          &-#{$name}-#{$i}x {
            flex: 0 0 ($i / $grid-columns-count) * 100%;
            max-width: ($i / $grid-columns-count) * 100%;
          }
        }
      }
    }
  }
}
