.dev-ui {
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  &__row:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__row > * {
    margin: 0.5rem 1rem;
  }
}

.dev-form {
  &__fields {
    margin-bottom: 30px;
  }
}
