@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.page--auth-template {
  background: linear-gradient(
    180deg,
    $color-lightskyblue-100 0%,
    $color-orchid-100 100%
  );

  .page__wrapper {
    flex-direction: column;
    z-index: 1;
    position: relative;
  }

  .page__container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;

    @include media-breakpoint-down(sm) {
      justify-content: flex-start;
    }

    @include media-breakpoint-down(xs) {
      padding: 20px;
    }
  }
}
