@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.chat {
  &__control {
    $clipButtonWidth: 26px;
    $clipButtonPadding: 11px;
    $clipButtonSize: $clipButtonWidth;

    $clipButtonWidthMobile: 19px;
    $clipButtonPaddingMobile: 10px;
    $clipButtonSizeMobile: $clipButtonWidthMobile + $clipButtonPaddingMobile * 2;

    @mixin inputPaddings {
      padding: 12px $clipButtonSize 13px 20px;
    }

    @mixin inputPaddingsMobile {
      $paddingY: 10px;

      padding: $paddingY $clipButtonSizeMobile $paddingY 11px;
    }

    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      padding-left: 15px;
    }

    &-text {
      flex-grow: 1;
      position: relative;
      max-width: 91.196%;
      margin-right: 5px;

      @include media-breakpoint-up(xs) {
        margin-right: 30px;
      }
    }

    &-textarea {
      z-index: 10;
      line-height: 0;
      border-bottom: none;

      & .form__text {
        border-bottom: none;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        background: $color-white;
        -webkit-box-shadow: 0 2px 10px rgba($color-black-200, 0.25);
        -moz-box-shadow: 0 2px 10px rgba($color-black-200, 0.25);
        box-shadow: 0 2px 10px rgba($color-black-200, 0.25);

        @include inputPaddings;

        @include media-breakpoint-down(xs) {
          @include inputPaddingsMobile;
        }
      }
      & .field__error {
        display: none;
      }
    }
    &-buttons {
      flex-shrink: 0;
      position: relative;
    }
    &-button {
      $size: 50px;

      position: static;
      width: $size;
      height: $size;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      opacity: 1;
      pointer-events: auto;
      font-size: 40px;
      font-weight: 400;
      line-height: 1;
      padding: 0;

      & .button__icon {
        opacity: 1;
        @include transition(opacity);

        @include media-breakpoint-down(xs) {
          width: 18px;
        }
      }

      &-send {
        font-size: 24px;
        padding-left: 7px;

        @include media-breakpoint-down(xs) {
          font-size: 16px;
          padding-left: 5px;
        }
      }

      @include media-breakpoint-down(xs) {
        $size: 36px;

        width: $size;
        height: $size;
      }
    }
    &-clip {
      position: absolute;
      top: 50%;
      right: 11px;
      bottom: 0;
      width: $clipButtonSize;
      height: $clipButtonSize;
      transform: translateY(-50%);
      opacity: 1;
      pointer-events: auto;
      z-index: 10;
      cursor: pointer;
      color: $color-primary;
      @include transition(color, opacity, z-index);

      &:hover {
        color: darken($color-primary, 15%);
      }

      @include media-breakpoint-down(xs) {
        width: $clipButtonSizeMobile;
        padding-right: $clipButtonPaddingMobile;
        padding-left: $clipButtonPaddingMobile;
      }

      &-icon {
        width: 100%;
      }
    }
  }
  &__messaging {
    width: 100%;
    border-top: 1px solid;
    border-top-color: $color-gainsboro-100;
    padding-top: 30px;
    margin-bottom: 10px;

    @include media-breakpoint-down(xs) {
      padding-top: 20px;
    }
  }
}

@keyframes chatControlButtonRecordPulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-crimson-100, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color-crimson-100, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-crimson-100, 0);
  }
}
