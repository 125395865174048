@import '@styles/abstracts/mixins.scss';
@import '@styles/abstracts/variables.scss';

.add-user-form {
  &__fields {
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }
  }
}
