.page {
  font-family: $font-main;
  font-size: 16px;
  line-height: 1.3;
  color: $color-text;
  position: relative;
  overflow: hidden;

  &__wrapper {
    overflow: hidden;
    display: flex;
    min-height: 100vh;
  }

  & {
    ::-webkit-scrollbar {
      width: $scrollbar-width;
      -webkit-border-radius: $scrollbar-width;
      -moz-border-radius: $scrollbar-width;
      border-radius: $scrollbar-width;
      background: $color-gainsboro-100;
    }
    ::-webkit-scrollbar-thumb {
      background: $color-primary;
      border-radius: $scrollbar-width;
    }
  }
}
