@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.select-card {
  $imageSize: 280px;
  $imageSizeMobile: 204px;

  position: relative;
  max-width: $imageSize;

  @include media-breakpoint-down(xxs) {
    max-width: $imageSizeMobile;
  }

  &__image {
    width: $imageSize;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(xxs) {
      width: 100%;
      max-width: $imageSizeMobile;
    }
  }

  &__image[data-disabled='false']:hover .card {
    box-shadow: 0 2px 10px rgba($color-primary, 0.3);
  }
  &__image[data-disabled='false'] {
    cursor: pointer;
  }

  &__info {
    padding-right: 38px;

    @include media-breakpoint-down(lg) {
      order: 3;
      width: 100%;
    }
  }

  &__list {
    padding-right: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 35px;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid;
      border-bottom-color: $color-gainsboro-100;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      justify-content: space-between;

      &:not(:last-child) {
        padding-bottom: 30px;
      }
    }

    @include media-breakpoint-down(xs) {
      padding-bottom: 30px;
    }
  }

  &__item-selected {
    padding-right: 0;

    &:after {
      $borderSize: 3px;
      position: absolute;
      content: '';
      right: 7px;
      height: 28px;
      width: 20px;
      border-style: solid;
      border-width: 0 $borderSize $borderSize 0;
      border-color: $color-primary;
      transform: rotate(45deg);
    }
  }

  &__card {
    width: 100%;
    max-width: 200px;
    margin-right: 40px;

    @include media-breakpoint-down(lg) {
      margin-right: 30px;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 21px;
    }
  }

  &__button {
    width: 200px;
  }

  &__navigation {
    $navigationMargin: -44px;
    $navigationMarginMobile: -20px;

    &-el {
      top: 84px;

      @include media-breakpoint-down(xxs) {
        top: 60px;
      }
    }

    &-prev {
      left: $navigationMargin;

      @mixin prevMobile {
        left: $navigationMarginMobile;
      }

      @include media-breakpoint-down(xxs) {
        @include prevMobile();
      }

      @include media-breakpoint-between(md, xl) {
        @include prevMobile();
      }
    }

    &-next {
      right: $navigationMargin;

      @mixin nextMobile {
        right: $navigationMarginMobile;
      }

      @include media-breakpoint-down(xxs) {
        @include nextMobile();
      }

      @include media-breakpoint-between(md, xl) {
        @include nextMobile();
      }
    }
  }
}

.select-card-modal {
  .modal__popup {
    max-height: 800px;
    height: 100%;
  }

  .modal__content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.get-consultation .info__item:not(:last-child) {
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }
}
