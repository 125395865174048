@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.consultations {
  &__header {
    margin-bottom: 40px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 35px;
    }
  }

  &__list-mode-toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  &__list {
    padding-bottom: 40px;

    @include media-breakpoint-down(xs) {
      padding-bottom: 20px;
    }
  }

  &-tabs__tab-list {
    margin-bottom: 35px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 15px;
    }
  }

  &__plug {
    max-width: 632px;
  }
}

.consultations-header {
  &__search {
    max-width: 440px;
    @include media-breakpoint-down(xxl) {
      max-width: 340px;
    }
    @include media-breakpoint-down(lg) {
      max-width: unset;
    }
  }

  &__inner {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-down(xs) {
      display: block;
    }
  }

  &__item {
    padding-left: 10px;
    padding-right: 10px;
    &--btn {
      display: flex;
      align-items: center;
    }
    @include media-breakpoint-down(sm) {
      .consultations-get {
        margin-left: 0;
      }
      .consultation-get__go-to-questions {
        margin-left: 20px;
      }
    }
  }
  &__item--title {
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      margin-bottom: 35px;
      width: 100%;
    }
  }

  &__item--search {
    padding-top: 20px;
    padding-bottom: 20px;
    // flex-grow: 1;
    order: 1;

    @include media-breakpoint-down(lg) {
      order: -1;
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
