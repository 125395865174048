@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.image-modal {
  $borderRadius: 10px;

  & .modal__popup {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 852px;
    -webkit-border-radius: $borderRadius;
    -moz-border-radius: $borderRadius;
    border-radius: $borderRadius;
    font-size: 0;
    line-height: 0;
    text-align: center;
    padding: 0;
  }

  & .modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: 100%;
  }

  &__picture {
    position: relative;
    z-index: 10;
    pointer-events: auto;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    transition: opacity 0.3s linear 0.3s;
  }

  &__picture-block {
    position: relative;
    height: auto;
    min-height: 0;
    width: 378px;
    display: flex;
    overflow: hidden;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    -moz-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    background-color: $color-darkgray-100;
    @include transition(width, height, min-height);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -300px;
      z-index: -1;
      width: 150px;
      opacity: 0;
      pointer-events: none;
      background: linear-gradient(
        to right,
        transparent 0%,
        #c4c4c4 50%,
        transparent 100%
      );
    }

    &--loading {
      min-height: 300px;
      &::after {
        opacity: 1;
        z-index: 2;
        animation: loading 1.1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }
      .image-modal__picture {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        position: fixed;
      }
    }
  }

  &__buttons {
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 16px;

    @include media-breakpoint-up(xs) {
      padding: 40px 20px;
    }
  }

  &__button {
    margin: 6px 16px;
    flex-grow: 1;
  }
}

@keyframes loading {
  from {
    left: -150px;
  }
  to {
    left: 110%;
  }
}
