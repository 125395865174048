@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.profile {
  max-width: 800px;
  margin-bottom: 60px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 24px;
  }

  &__wrapper {
    @include media-breakpoint-up(xl) {
      padding-right: 160px;
      padding-left: 120px;
    }

    @include media-breakpoint-up(xs) {
      padding-top: 46px;
      padding-bottom: 50px;
    }
  }

  &__form {
    @include media-breakpoint-up(md) {
      max-width: 440px;
    }
  }
}

.edit-profile {
  @include media-breakpoint-up(xs) {
    $paddingY: 60px;

    padding-top: $paddingY;
    padding-bottom: $paddingY;
  }

  @include media-breakpoint-up(xl) {
    $paddingX: 220px;

    padding-right: $paddingX;
    padding-left: $paddingX;
  }

  @include media-breakpoint-between(lg, xl) {
    $paddingX: 114px;

    padding-right: $paddingX;
    padding-left: $paddingX;
  }

  & .block__columns {
    @include media-breakpoint-up(xl) {
      justify-content: flex-start;
    }
  }

  & .block__column-left {
    @include media-breakpoint-up(xl) {
      margin-right: 160px;
    }
  }

  &__active {
    @include media-breakpoint-between(lg, xl) {
      padding-right: 30px;
      padding-left: 144px;
    }

    & .block__column-right {
      @include media-breakpoint-between(lg, xl) {
        max-width: 426px;
      }
    }
  }

  &__info {
    margin-bottom: 50px;
  }
}

.titleline--profile {
  justify-content: space-between;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    align-items: unset;

    .titleline__button {
      margin-left: 0;
    }
  }
}
