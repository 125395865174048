@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.item {
  $borderColor: $color-lavender-200;

  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 30px;
  border: 1px solid $borderColor;
  background-color: $color-ghostwhite-200;
  padding: 20px 30px;

  @include transition(border-color);

  &:not(:has(.delete:hover)):hover {
    border-color: darken($borderColor, 15);
  }

  @include media-breakpoint-down(xs) {
    padding: 15px 20px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 15px;
    }
  }
}
.avatar {
  @include squareSize(48px);
  margin-right: 24px;

  @include media-breakpoint-down(xs) {
    @include squareSize(40px);
  }
}
.info {
}
.name {
  font-size: 14px;
  line-height: 145%;
  margin-right: 10px;
}
.delete {
  $size: 16px;
  $line: 22px;

  position: relative;
  @include squareSize($size);
  cursor: pointer;
  margin-left: auto;

  &:hover::before,
  &:hover::after {
    background-color: $color-crimson-100;
  }

  @mixin pseudo {
    content: '';
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -#{($line - $size) / 2};
    width: $line;
    height: 1px;
    background-color: $color-darkgray-100;
    @include transition(background-color);
  }

  &:before {
    @include pseudo();
    transform: rotate(45deg) translateY(-50%);
  }

  &:after {
    @include pseudo();
    transform: rotate(-45deg) translateY(-50%);
  }
}
