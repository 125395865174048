@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.labels-header {
  display: flex;

  &__item {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
  }
}
