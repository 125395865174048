@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.edit-medcard__anamnesis {
  &-text {
    display: inline-block;
    margin-right: 20px;
  }
  &-explanation {
    display: inline-flex;

    & .explanation__tooltip {
      width: 267px;

      @include media-breakpoint-up(xl) {
        width: 500px;
      }
    }
  }
}
