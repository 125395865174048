.input-text {
  font-size: 18px;
  line-height: 21px;
  color: $color-black;
  padding: 10px 0;

  @include placeholder() {
    @include placeholderStyles();
  }

  @include media-breakpoint-down(xs) {
    font-size: 14px;
    line-height: 16px;
  }
}
