@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.explanation {
  $size: 20px;
  $color: $color-gainsboro-100;

  position: relative;
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: $color-white;
  background-color: $color;
  @include transition(background-color);

  &:hover {
    background-color: lighten($color, 5%);

    .tooltip {
      pointer-events: auto;
      z-index: 10;
      opacity: 1;
    }
  }
}

.symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  $backgroundColor: $color-darkslategray-200;

  position: absolute;
  top: 50%;
  right: -23px;
  z-index: -1;
  opacity: 0;
  // width: 100%;
  width: 264px;
  pointer-events: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
  text-transform: none;
  background-color: $backgroundColor;
  backdrop-filter: blur(12px);
  color: $color-white;
  @include transition(opacity);
  padding: 10px 15px;
  transform: translate(100%, -50%);

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }

  @include media-breakpoint-down(lgs) {
    top: auto;
    right: -16px;
    bottom: -18px;
    transform: translateY(100%);
  }

  &:after {
    $size: 24px;

    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: $size;
    height: $size;
    background-color: $backgroundColor;
    transform: translate(-50%, -50%) rotate(45deg);

    @include media-breakpoint-down(lgs) {
      top: 0;
      right: 14px;
      left: auto;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
