@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.analyses-list {
  list-style: none;
  padding: 0;

  @include media-breakpoint-up(md) {
    max-height: 480px;
    overflow-y: auto;
    padding-right: 20px;
  }
  @include media-breakpoint-up(xl) {
    max-height: 569px;
    padding-right: 26px;
  }

  &__category + &__category {
    margin-top: 20px;

    @include media-breakpoint-up(xs) {
      margin-top: 30px;
    }
  }
  &__category-title {
    margin-bottom: 10px;
    text-transform: uppercase;
    color: $color-primary;
    font-size: 14px;
    font-weight: 700;
    @include media-breakpoint-up(xs) {
      font-size: 16px;
    }
  }

  &__item {
    border-bottom: 1px solid $color-gainsboro-100;
    &:last-child {
      border-bottom: 0;
    }
  }
}
