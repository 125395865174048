@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.testimony__item {
  position: relative;
  display: block;
  height: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-decoration: none;
  padding: 20px 16px;

  @include media-breakpoint-up(xs) {
    padding: 20px;
  }

  &:not(.testimony__item-developing) {
    background: $color-white;
    -webkit-box-shadow: 0 2px 10px rgba($color-black-200, 0.33);
    -moz-box-shadow: 0 2px 10px rgba($color-black-200, 0.33);
    box-shadow: 0 2px 10px rgba($color-black-200, 0.33);

    .testimony__item-value {
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      color: $color-primary;
      margin-bottom: 2px;

      @include media-breakpoint-up(xs) {
        font-size: 30px;
        line-height: 35px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .testimony__item-description {
      text-transform: lowercase;
    }

    .testimony__item-additional {
      display: block;
      opacity: 0.5;
      font-size: 12px;
      line-height: 14px;
      text-transform: lowercase;

      @include media-breakpoint-up(xs) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 22px;
      right: 0;
      z-index: 10;
      width: 90px;
      height: 116px;
      background-repeat: no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      background-position: center;

      @include media-breakpoint-up(sm) {
        $size: 13.105vw;

        top: 50%;
        width: $size;
        height: $size;
        -webkit-background-size: cover;
        background-size: cover;
        transform: translateY(-50%);
      }
      @include media-breakpoint-up(md) {
        $size: 9.028vw;

        width: $size;
        height: $size;
      }
      @include media-breakpoint-up(xl) {
        $size: 130px;

        top: 15px;
        width: $size;
        height: $size;
        transform: none;
      }
    }
    &.testimony__item-index:after {
      background-image: url('/static/img/health-main/index.png');
    }
    &.testimony__item-pressure:after {
      background-image: url('/static/img/health-main/pressure.png');
    }
    &.testimony__item-accurate_pressure:after {
      background-image: url('/static/img/health-main/accurate_pressure.png');
    }
    &.testimony__item-pulse:after {
      background-image: url('/static/img/health-main/pulse.png');
    }
    &.testimony__item-oxygen:after {
      background-image: url('/static/img/health-main/oxygen.png');
    }
    &.testimony__item-ecg:after {
      background-image: url('/static/img/health-main/ecg.png');
    }
    &.testimony__item-sleep:after {
      background-image: url('/static/img/health-main/sleep.png');
    }
    &.testimony__item-temperature:after {
      background-image: url('/static/img/health-main/temperature.png');
    }
    &.testimony__item-medicines:after {
      background-image: url('/static/img/health-main/medicines.png');
    }
    &.testimony__item-activity:after {
      background-image: url('/static/img/health-main/activity.png');
    }
    &.testimony__item-weight:after {
      background-image: url('/static/img/health-main/weight.png');
    }
    &.testimony__item-birthmark:after {
      background-image: url('/static/img/health-main/birthmark.png');
    }
    &.testimony__item-analyzes:after {
      background-image: url('/static/img/health-main/analyzes.png');
    }
    &.testimony__item-diet:after {
      background-image: url('/static/img/health-main/diet.png');
    }
    &.testimony__item-womens-calendar:after {
      background-image: url('/static/img/health-main/womens-calendar.png');
    }
    &.testimony__item-x_ray:after {
      background-image: url('/static/img/health-main/x-ray.svg');
      background-size: contain;
    }
    &.testimony__item-cough:after {
      background-image: url('/static/img/health-main/cough.png');
    }
    &.testimony__item-water-balance:after {
      background-image: url('/static/img/health-main/water-balance.png');
    }
    &.testimony__item-workouts:after {
      background-image: url('/static/img/health-main/workouts.png');
    }
    &.testimony__item-food-diary:after {
      background-image: url('/static/img/health-main/food-diary.png');
    }
    &.testimony__item-caffeine:after {
      background-image: url('/static/img/health-main/caffeine.png');
    }
    &.testimony__item-blood-sugar:after {
      background-image: url('/static/img/health-main/blood-sugar.png');
    }
    &.testimony__item-breath:after {
      background-image: url('/static/img/health-main/breath.png');
    }
    &.testimony__item-tests:after {
      background-image: url('/static/img/health-main/tests.png');
    }
  }

  &-developing {
    opacity: 0.5;
    border: 1px dashed $color-black-100;

    .testimony__item-value,
    .testimony__item-additional {
      display: none;
    }
  }

  &-text {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    height: inherit;
    font-size: 14px;
    line-height: 16px;
    color: $color-black;

    @include media-breakpoint-up(xs) {
      font-size: 18px;
      line-height: 21px;
    }
  }
  &-wrapper {
    $paddingX: 7.5px;

    height: 150px;
    width: 100%;
    padding-right: $paddingX;
    padding-left: $paddingX;
    margin-top: 15px;

    @include media-breakpoint-up(xxxs) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include media-breakpoint-up(xs) {
      $paddingX: 10px;

      height: 161px;
      padding-right: $paddingX;
      padding-left: $paddingX;
      margin-top: 20px;
    }

    @include media-breakpoint-up(sm) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  &-title {
    display: block;
    font-weight: 500;
    margin-bottom: 3px;
  }
  &-footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &-description {
    opacity: 0.5;
  }
}
