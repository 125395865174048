@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.modal {
  @include media-breakpoint-up(sm) {
    min-height: 447px;
  }

  & :global(.modal__text) {
    display: flex;
    flex-direction: column;
  }
}

.form {
  height: 100%;

  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    justify-content: space-between;
    align-items: flex-start;
  }
}

.header {
  @include media-breakpoint-up(sm) {
    margin-bottom: 16px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;

    & :global(.h2) {
      padding-right: 0
    }
  }
}

.code-description {
  @include media-breakpoint-up(sm) {
    margin-bottom: 32px;
  }

  @include media-breakpoint-down(sm) {
    align-self: start;
    margin-bottom: 24px;
  }
}

.content {
  padding-right: 0;

  @include media-breakpoint-up(sm) {
    flex: 1;
    justify-content: space-between;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.image {
  display: block;
  width: unset;

  @include media-breakpoint-down(sm) {
    max-width: 151px;
    padding: 11px 14px;
    margin-bottom: 32px;
    align-self: center;
  }

  & :global(img) {
    width: 100%;
  }
}

.input {
  @include media-breakpoint-down(sm) {
    margin-bottom: 32px
  }
}

.send-code-button {
  align-self: flex-start;
}

.input-code {
  @include media-breakpoint-up(sm) {
    margin-bottom: 24px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}

.code-form {
  @include media-breakpoint-up(sm) {
    align-items: flex-start;
  }
}
