@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.app-links {
  font-size: 0;
  line-height: 0;
  display: flex;

  &__item {
    @include last-child(right);
    max-width: 120px;
    text-decoration: none;
    margin-right: 30px;

    @include media-breakpoint-down(lg) {
      margin-right: 10px;
    }
  }
}
