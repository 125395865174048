@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.auth {
  &__logo {
    margin-bottom: 40px;
    color: $color-white;

    @include media-breakpoint-down(xs) {
      margin-bottom: 30px;
      width: 112px;
    }
  }

  &__block {
    max-width: $block-max-width;
    @include media-breakpoint-up(xs) {
      padding-top: 46px;
      padding-bottom: 50px;
    }
  }
}

.auth-block {
  $paddingX: 0;

  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 19px;
  color: rgba($color-text, 0.5);
  padding-right: $paddingX;
  padding-left: $paddingX;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
    line-height: 14px;
    // padding-bottom: 34px;
  }

  @include media-breakpoint-up(sm) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      z-index: 10;
      width: 1px;
      background: $color-gainsboro-100;
    }
  }
  &__content {
    min-height: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      max-width: 372px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    flex-grow: 1;

    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }
  }

  .notifications {
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.auth-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__footer {
    margin-top: auto;
    width: 100%;
    padding-top: 40px;
  }

  &__link {
    font-size: 18px;
    line-height: 21px;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.auth-tabs {
  &__tab-panel {
    min-height: 100%;
    width: 50%;
    padding-left: 120px;

    @include media-breakpoint-down(lg) {
      $paddingX: 5.085%;

      padding-right: $paddingX;
      padding-left: $paddingX;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(sm) {
      display: block;
    }

    @include media-breakpoint-down(xs) {
      $paddingX: 20px;

      padding-right: $paddingX;
      padding-left: $paddingX;
    }
  }

  &__tab-list {
    $color: $color-white;

    font-size: 12px;
    line-height: 14px;
    color: $color;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  &__tab {
    width: 50%;
  }
}
