@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-chart {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
    min-height: 650px;
  }

  &__block {
    max-width: 100%;
    padding: 20px 15px 30px;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      width: 61.99%;
      max-width: 760px;
      padding: 40px 30px 0;
      margin-right: 20px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      $paddingX: 40px;
      width: 61.29%;
      padding-right: $paddingX;
      padding-left: $paddingX;
      margin-right: 40px;
    }
  }

  &__container {
    width: 100%;
    height: 200px;

    @include media-breakpoint-up(xs) {
      height: 269px;
    }

    @include media-breakpoint-up(sm) {
      height: 378px;
    }
    @include media-breakpoint-up(lg) {
      height: 400px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;

    @include media-breakpoint-up(lg) {
      flex-grow: 1;
    }
  }

  &__info-empty {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.3;
    color: rgba($color-text, 0.5);

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }

  @include media-breakpoint-down(md) {
    &__info-tabs {
      position: static;
    }
  }
}
