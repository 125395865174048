@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.breadcrumbs {
  $marginX: 20px;
  $marginXMobile: 10px;

  &__list {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }
  }
  &__item {
    font-size: 20px;
    line-height: 23px;
    color: rgba($color-text, 0.5);
    margin-right: $marginX;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
      margin-right: $marginXMobile;
      margin-bottom: 6px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  &__text {
    display: inline-block;
    font-weight: 500;
    color: $color-primary;
  }
  &__link {
    font-weight: 400;
    text-decoration: none;
    color: currentColor;
    @include transition(color);
    margin-right: $marginX;

    @include media-breakpoint-down(xs) {
      margin-right: $marginXMobile;
    }

    &:hover {
      color: rgba($color-text, 1);
    }
  }
}
