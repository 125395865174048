@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.titleline {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;

  @include media-breakpoint-down(xs) {
    margin-bottom: 24px;
  }

  &__title {
    padding-right: 40px;
    flex-grow: 1;

    @include media-breakpoint-up(xs) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  &__right {
    margin-left: auto;

    @include media-breakpoint-down(lg) {
      .titleline__button {
        display: none;
      }
    }
  }
  &__button {
    position: relative;
    z-index: 1;
    margin-left: auto;

    &:not(:first-child) {
      margin-left: 20px;
    }

    @include media-breakpoint-down(xs) {
      margin-left: 0;
      width: 100%;
    }
  }
}
