@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.input-select {
  $selectShift: -20px;
  $selectShiftMobile: -10px;

  position: relative;

  &__control {
    width: 100%;
    position: relative;
    cursor: pointer;
    display: flex;
    max-width: 100%;

    &:after {
      $shift: $selectShiftMobile;

      content: '';
      position: absolute;
      left: $shift;
      right: $shift;
      height: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid #777ff9;
      border-bottom: none;
      display: none;

      @include media-breakpoint-up(sm) {
        $shift: $selectShift;

        right: $shift;
        left: $shift;
      }
    }
    &--menu-is-open:after {
      display: block;
    }
  }

  &__value-container {
    flex-grow: 1;
    z-index: 1;
    line-height: 0;
    font-size: 0;
    font-size: 18px;
    line-height: 21px;
    max-width: 100%;
    min-width: 0; // to avoid overflow
    min-height: 41px;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
      min-height: 36px;
    }

    &--is-multi {
      display: flex;
      align-items: center;
      min-height: 39px;
      font-size: 14px;
      line-height: 1.16;
      overflow: hidden;
      padding-right: 6px;
      @include media-breakpoint-up(xs) {
        font-size: 18px;
        min-height: 44px;
      }
    }
  }
  &__multi-value#{&}__multi-value {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 3px 0 5px;
    border-radius: 3px;
    background-color: $color-palesteelblue-100;
  }

  &__multi-value__remove#{&}__multi-value__remove {
    padding: 4px;
  }

  &__multi-value__remove#{&}__multi-value__remove:hover {
    color: $color-primary;
    background-color: transparent;
  }

  &__multi-value__label#{&}__multi-value__label {
    padding: 0;
    line-height: 1.2;
  }

  &__input {
    max-width: 100%;
    width: 100%;

    input {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      opacity: 1 !important;
    }
  }

  &__placeholder {
    color: $color-gray-100;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__indicators {
    position: relative;
    margin-left: auto;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
  }

  &__clear-indicator#{&}__clear-indicator {
    position: relative;
    padding: 6px;
    width: 26px;
    height: 26px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 25%;
      left: 25%;
      width: 2px;
      height: 16px;
      background-color: $color-gray-100;
      transition: $transition-main;
    }
    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover::before,
    &:hover::after {
      background-color: $color-black;
    }

    svg {
      display: none;
    }
  }

  &__dropdown-indicator#{&}__dropdown-indicator {
    transform: rotate(45deg);
    transition: $transition-main;
    padding: 6px;
    margin-left: 6px;

    &::before {
      content: '';
      display: block;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      border-color: $color-primary;
    }
    svg {
      display: none;
    }
  }

  &__clear-indicator + &__indicator-separator {
    border-left: 1px solid $color-gainsboro-100;
    height: 14px;
    margin-top: 2px;
  }

  &__control--menu-is-open &__dropdown-indicator {
    transform: rotate(-135deg);
    top: calc(50% - 4px);
  }
  &__loading-indicator {
    display: flex;
    align-items: center;
    margin-right: 6px;
    span {
      width: 5px;
      height: 5px;
    }
    span:not(:first-child) {
      margin-left: 4px;
    }
  }
  &__single-value + input {
    position: absolute;
  }

  &__menu {
    $shift: $selectShiftMobile;
    font-size: 16px;

    position: absolute;
    top: 100%;
    left: $shift;
    z-index: 99;
    right: $shift;
    border: 1px solid #777ff9;
    background-color: $color-white-100;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }

    @include media-breakpoint-up(sm) {
      $shift: $selectShift;

      right: $shift;
      left: $shift;
    }

    &-list {
      // scrollbar
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 200px;

      &::-webkit-scrollbar {
        width: $scrollbar-width;
        -webkit-border-radius: $scrollbar-width;
        -moz-border-radius: $scrollbar-width;
        border-radius: $scrollbar-width;
        background: $color-gainsboro-100;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-primary;
        border-radius: $scrollbar-width;
      }
    }
  }

  &__option {
    cursor: pointer;
    padding: 15px 20px;
    position: relative;
    transition: background-color 0.3s;

    &:not(:first-child) {
      border-top: 1px solid $color-gainsboro-100;
    }

    &--is-selected {
      cursor: default;

      &:after {
        $borderSize: 2px;

        content: '';
        position: absolute;
        top: calc(50% - 14px);
        right: 24px;
        z-index: 10;
        width: 12px;
        height: 17px;
        border-style: solid;
        border-width: 0 $borderSize $borderSize 0;
        border-color: $color-primary;
        transform: rotate(45deg);
      }
    }
  }
  &__option:hover {
    background-color: $color-palesteelblue-100;
  }

  &__value-container--is-multi input {
    position: absolute;
  }
}
