@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.data-table {
  width: 100%;
  font-size: 14px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  tr {
    border-bottom: 1px solid $color-gainsboro-100;
  }

  tr:last-child {
    border-color: transparent;
  }

  &__label {
    padding: 10px 10px 10px 0;
  }

  &__value {
    padding: 10px 0 10px 10px;
    text-align: right;
    font-weight: 500;
  }
  &__clickable {
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-end;
    color: inherit;
    &::after {
      content: '';
      align-self: center;
      margin-left: 14px;
      margin-right: 10px;
      width: 10px;
      height: 10px;
      flex-shrink: 0;
      border-top: 1px solid $color-primary;
      border-right: 1px solid $color-primary;
      transform: rotate(45deg);
    }
  }
  &__clickable-arrow {
    align-self: center;
  }
}
