@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.pagination-mobile {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-primary;
  padding: 13px 18px;
  border-radius: 28px;
  color: #fff;
  min-width: 120px;
  font-weight: 700;
  font-size: 14px;

  &__link {
    display: block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    &--prev {
      transform: rotate(-135deg);
      margin-top: 2px;
    }
    &--next {
      transform: rotate(45deg);
      margin-top: 2px;
    }
  }
  &__link[disabled] {
    opacity: 0.25;
  }
}
