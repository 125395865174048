@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

$borderColor: $color-gainsboro-100;
$checkedColor: $color-primary;

.input-checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &__label {
    display: inline-block;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    color: $color-black;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__check {
    $size: 18px;
    $borderColor: $color-gainsboro-100;

    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    display: inline-block;
    width: $size;
    height: $size;
    border: 1px solid;
    border-color: $borderColor;
    transition: $transition-main;
    margin-right: 22px;

    @include media-breakpoint-down(xs) {
      $size: 16px;

      width: $size;
      height: $size;
      margin-right: 10px;
    }
  }

  &__check:after {
    $borderSize: 2px;

    content: '';
    position: absolute;
    top: -1px;
    left: 4px;
    display: inline-block;
    height: 14px;
    width: 8px;
    opacity: 0;
    border-style: solid;
    border-width: 0 $borderSize $borderSize 0;
    transform: rotate(45deg);
    border-color: $checkedColor;
    transition: $transition-main;
  }

  &__original:checked ~ .input-checkbox__check:after {
    opacity: 1;
  }

  &--is-disabled {
    cursor: default;

    .input-checkbox__check {
      background-color: #e9ecef;
    }
  }
}
