@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.block {
  position: relative;
  width: 100%;
  max-width: 502px;
  border-radius: 20px;
  overflow: hidden;
  background-color: $color-white;
  padding: 40px;

  @include media-breakpoint-down(sm) {
    padding: 32px 24px;
  }
}
