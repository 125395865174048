@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.buttons {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 30px;
}
.button {
  align-self: flex-start;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}
