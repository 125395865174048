@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.analyses-list-page {
  &__block,
  &__block.block {
    padding: 20px;
    @include media-breakpoint-up(md) {
      padding-top: 30px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      min-height: 500px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 740px;
      padding: 40px 10px 30px 40px;
    }
  }
  &__mob-filter-line {
    padding-bottom: 14px;
    margin-top: -16px;
    display: flex;
  }
  &__mob-filter-btn {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: auto;
    color: $color-primary;
    &._active::after {
      content: '';
      position: absolute;
      z-index: 5;
      top: -3px;
      left: 12px;
      width: 9px;
      height: 9px;
      background-color: $color-limegreen-100;
      border-radius: 50%;
    }
  }
  &__mob-filter-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  &__mob-filter {
    .modal__overlay {
      overflow-y: auto;
    }
    .modal__popup {
      padding-top: 84px;
      overflow: visible;
      max-height: initial;
      max-height: auto;
    }
    .modal__content {
      overflow: visible;
    }
  }
  &__filter-btn-apply {
    width: 100%;
    margin-top: 16px;
  }
}
