@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.questions-go-btn {
  display: flex;
  align-items: center;
  padding: 11px 27px 10px 27px;
  margin-right: 10px;
  &__text {
    margin-right: 10px;
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    order: 2;
    margin-left: 10px;
    margin-right: 0;
  }
  &.titleline__button {
    @include media-breakpoint-down(xs) {
      width: auto;
    }
  }
}

.svg-questions {
  position: relative;
  width: 25px;
  height: 25px;
  &-with-label {
    &::after {
      position: absolute;
      top: -2px;
      right: -7px;
      display: block;
      width: 14px;
      height: 14px;
      content: '';
      border-radius: 50%;
      background-color: $color-crimson-100;
    }
  }
}
