@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.medicine-item {
  font-size: 16px;
  @include media-breakpoint-up(xxs) {
    font-size: 18px;
  }
  &__title {
    display: flex;
    align-items: center;
  }
  &__dot {
    flex-shrink: 0;
    margin-right: 20px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: currentColor;
  }
  &__name {
  }
  &__info {
    display: flex;
    margin-top: 10px;
    font-size: 0.9em;
    color: $color-lightslategray-100;
  }
  &__dates {
    width: 45%;
  }
  &__dose {
    width: 25%;
  }
  &__takings {
    width: 30%;
    text-align: right;
  }
}
