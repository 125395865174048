@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.audio-player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 6px;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: rgba($color-white, 0.5);
  margin-top: 11px;
  margin-bottom: 9px;
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  pointer-events: auto;
  opacity: 1;

  // &__active{
  //     position: relative;
  //     top: auto;
  //     left: auto;
  //     z-index: 1;
  //     pointer-events: auto;
  //     opacity: 1;
  // }

  &__tag {
    display: none;
  }

  &__icon {
    cursor: pointer;
  }

  &__progressbar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    border-radius: inherit;
    background: rgba($color-white, 1);
  }
  &__indicator {
    $size: 12px;

    position: absolute;
    z-index: 10;
    top: 50%;
    width: $size;
    height: $size;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: $color-crimson-100;
    transform: translate(-50%, -50%);
  }
}

.na-audio-player {
  display: flex;
  flex: 1;
  padding-top: 20px;
  box-sizing: border-box;
}

.na-audio-player__source {
  display: none;
}

.na-audio-player__info {
  display: flex;
  margin-right: 10px;
  align-items: center;
}

.na-audio-player__info .info__icon {
  margin-right: 10px;
}

.na-audio-player__info .info__text {
  max-width: 220px;
}

.na-audio-player__ui {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.na-audio-player .btns {
  width: 25px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.na-audio-player__timeline {
  position: relative;
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
}

.na-audio-player__timeline .lines {
  position: relative;
  width: 100%;
}

.na-audio-player__timeline .lines__line {
  width: 100%;
  height: 6px;
  border-radius: 3px;
}

.na-audio-player__timeline .lines__line--all {
  background: #d8d8d8;
}

.na-audio-player__timeline .lines__line--past {
  position: absolute;
  width: 100%;
  height: 6px;
  background: var(--color-primary);
  transform-origin: left;
  left: 0;
}

.na-audio-player__timeline .lines__line--predict {
  position: absolute;
}

.na-audio-player__timeline .times {
  user-select: none;
  left: 0;
  right: 0;
  padding: 8px 10px;
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: -25px;
}

.na-audio-player__timeline .times__left {
  left: 0;
}

.na-audio-player__timeline .times__right {
  right: 0;
}

.na-audio-player__timeline .drag {
  will-change: transform;
  position: absolute;
  left: -10px;
  top: -10px;
  margin-top: 3px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #da4040;
  z-index: 2;
  cursor: pointer;
}
