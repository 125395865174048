@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.form {
  $iconSize: 30px;

  width: 100%;

  &__field {
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    &_input-file {
      ._react-file-reader-input {
        display: inline-block;
      }
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    @include placeholderStyles();
    margin-bottom: 30px;

    @include media-breakpoint-down(xxs) {
      font-size: 14px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 21px;
    border-bottom: 1px solid;
    color: $color-black;
    border-bottom-color: $color-gainsboro-100;
    transition: border-bottom-color $transition-main;
    padding-bottom: 10px;

    &:hover {
      border-bottom-color: $color-primary;
    }

    [data-error] & {
      border-bottom-color: $color-error;
    }

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__file {
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: $color-mediumslateblue-200;
    color: $color-white;

    &-document {
      width: 50%;
      margin-top: 8px;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        width: 33%;

        &:nth-child(odd) {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 50%;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-top: 15px;
      }

      &-row {
        margin-top: -8px;

        @include media-breakpoint-down(xs) {
          margin-top: 15px;
        }
      }
      & .form__file {
        $paddingY: 9px;

        padding: $paddingY 0 $paddingY 20px;
      }

      & .file__title {
        font-size: 14px;
        line-height: 16px;
      }
      & .file__detail {
        font-size: 10px;
        line-height: 12px;
      }
      & .file__icon {
        flex-shrink: 0;
        width: 21px;
        margin-right: 12px;
      }
    }
    &-image {
      $marginTop: 8px;
      $marginTopMobile: 16px;
      $size: 138px;

      height: $size;
      width: $size;
      margin-top: $marginTop;

      &:not(:last-child) {
        margin-right: 15px;
      }

      @include media-breakpoint-down(lg) {
        margin-top: $marginTopMobile;
      }

      @include media-breakpoint-down(xs) {
        max-width: none;
      }

      &-row {
        display: flex;
        flex-wrap: wrap;
        margin-top: -$marginTop;

        @include media-breakpoint-down(lg) {
          margin-top: -$marginTopMobile;
        }
      }

      & .form__file {
        $padding: 2px;

        height: 100%;
        width: 100%;
        padding: $padding;
      }
    }
  }

  .notifications {
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
