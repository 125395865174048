@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.item {
  $paddingY: 30px;

  border-radius: 20px;
  border: 2px solid $color-lavender-200;
  background-color: $color-white;
  padding: $paddingY 40px $paddingY 20px;

  @include media-breakpoint-down(xs) {
    padding: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 25px;
    }
  }
}
.header {
  $paddingY: 10px;

  display: flex;
  align-items: center;
  padding-top: $paddingY;
  padding-bottom: $paddingY;
  margin-bottom: 10px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 15px;
  }
}
.tariff {
  font-size: 24px;
  font-weight: 500;
  color: $color-mediumslateblue-300;

  @include media-breakpoint-down(xs) {
    font-size: 18px;
    margin-right: 10px;
  }
}
.limit {
  align-self: flex-start;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-crimson-100;
  background-color: $color-lavender-blush-100;
  padding: 5px 15px;
  margin-left: 20px;

  @include media-breakpoint-down(xs) {
    $paddingX: 10px;

    font-size: 12px;
    padding-right: $paddingX;
    padding-left: $paddingX;
    margin-left: auto;
  }
}
.detail {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}
.services {
  flex-grow: 1;
  max-height: 502px;
  overflow-y: auto;
  padding-right: 20px;

  @include media-breakpoint-down(lg) {
    max-height: none;
    padding-right: 10px;
  }
}
.one-time {
  max-width: 390px;
}
