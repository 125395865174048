@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';
@import './../variables';

.list {
  padding-top: 20px;
  border-top: 2px dashed $color-lavender-200;
  margin-top: 20px;

  @mixin hideBorderMedia() {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }

  @include media-breakpoint-between(lgs, lg) {
    @include hideBorderMedia();
  }

  @include media-breakpoint-between(sm, md) {
    @include hideBorderMedia();
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.count {
  color: $color-darkgray-200;
  margin-bottom: 20px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 16px;
  }
}
.users {
  display: flex;

  &__wrapper {
    display: flex;
    align-items: center;
  }
}
.empty-user {
  $size: $avatarSize;

  @include userItemMargin();

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 120%;
  font-weight: 500;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 2px dashed $color-gainsboro-100;
  color: $color-darkgray-200;

  @include media-breakpoint-down(xs) {
    $size: $avatarSizeMobile;

    width: $size;
    height: $size;
  }
}
.manage {
  $color: $color-darkgray-200;

  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color;
  margin-left: auto;
  @include transition(color);

  @include media-breakpoint-down(lg) {
    margin-left: 10px;
  }

  &:hover {
    color: darken($color, 15);
  }

  &__icon {
  }

  &__text {
    margin-left: 8px;

    @include media-breakpoint-down(xxxs) {
      display: none;
    }
  }
}
.control {
  max-width: 556px;
  padding: 50px 40px;

  @include media-breakpoint-down(xs) {
    padding: 20px;
  }
}
.button {
  $backgroundColor: $color-lavender-200;

  display: flex;
  align-items: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: $color-primary;
  background-color: $backgroundColor;
  padding: 16px 24px;
  margin-top: 40px;
  @include transition(background-color);

  &:hover {
    background-color: darken($backgroundColor, 2);
  }
}
.icon {
  margin-right: 10px;
}
.members {
  width: 100%;
}
