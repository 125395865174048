@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.button {
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 10px;

  &-icon {
    @include squareSize(20px);
    margin-right: 14px;
  }
}
