@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.special {
  color: $color-primary;
  font-weight: 500;
}
.row {
  display: flex;
  align-items: center;
}
.field {
  flex-grow: 1;
  margin-right: 12px;

  @include media-breakpoint-down(sm) {
    margin-right: 8px;
  }
}
.tooltip {
  flex-shrink: 0;
}
.buttons {
  display: flex;
  flex-direction: column;
}
.button:not(:last-child) {
  margin-bottom: 10px;
}
