@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.restore {
  display: inline-block;
  padding-top: 43px;
  padding-bottom: 13px;

  @include media-breakpoint-down(sm) {
    padding-top: 23px;
    padding-bottom: 43px;
  }
}
