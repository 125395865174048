@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.get-consultation {
  position: relative;

  &__inner {
    &.block__content {
      padding-bottom: 80px;
    }

    @include media-breakpoint-between(md, xl) {
      &.block__content {
        $paddingX: 30px;

        padding-right: $paddingX;
        padding-left: $paddingX;
      }
      & .block__column-left {
        margin-right: 62px;
      }
    }

    @include media-breakpoint-down(md) {
      &.block__content {
        padding-bottom: 40px;
      }
    }
  }

  &__submit {
    margin-top: 60px;
  }

  &__info {
    margin-top: 30px;

    @include media-breakpoint-down(xs) {
      margin-top: 20px;
    }
  }
}
