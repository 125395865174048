@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $color-darkgray-200;
}
.description {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}
.footer {
  margin-top: auto;

  & :global(button):not(:last-child) {
    margin-bottom: 14px;
  }
}
.repeat {
  $color: $color-primary;

  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  color: $color;
  @include transition(color);

  &:hover {
    color: darken($color, 15);
  }
}
