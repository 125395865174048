@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.add-card-success {
  &__image.modal__image {
    position: relative;
  }
  &__image#{&}__image-card {
    border-radius: 16px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
