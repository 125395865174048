@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.modal {
  $paddingTop: 40px;
  $paddingTopMobile: 20px;
  $paddingXTablet: 80px;
  $paddingXMobile: 20px;
  $paddingRightInner: 11px;
  font-family: $font-main;
  font-size: 16px;
  line-height: 1.2;
  color: $color-text;

  & ::-webkit-scrollbar {
    width: $scrollbar-width;
    -webkit-border-radius: $scrollbar-width;
    -moz-border-radius: $scrollbar-width;
    border-radius: $scrollbar-width;
    background: $color-gainsboro-100;
  }

  & ::-webkit-scrollbar-thumb {
    background: $color-primary;
    border-radius: $scrollbar-width;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
    max-height: 100vh;
    color: $color-black-200;
    background-color: rgba(0, 0, 0, 0);
    transition: $transition-main;
    padding: 20px;

    @include media-breakpoint-down(xxxs) {
      padding: 6px;
    }

    &--after-open {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &--before-close {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &__popup {
    @include white-sheet;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 916px;
    width: 100%;
    overflow: hidden;
    pointer-events: auto;
    background: $color-white;
    padding: 40px 120px 60px;
    max-height: calc(100vh - 12px);

    @include media-breakpoint-down(xl) {
      max-width: 880px;
    }

    @include media-breakpoint-down(md) {
      padding-right: 80px;
      padding-left: 80px;
    }

    @include media-breakpoint-down(sm) {
      padding: 20px;
      padding-bottom: 30px;
    }

    @include media-breakpoint-down(xxxs) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__popup--is-short {
    max-width: 600px;

    @include media-breakpoint-down(xl) {
      max-width: 654px;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    line-height: 1.35;
    padding-right: 4px;

    @include media-breakpoint-down(xl) {
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 14px;
    }

    @include media-breakpoint-up(xs) {
      padding-right: 12px;
    }
  }

  &__content-p+&__content-p {
    margin-top: 1em;
  }

  &__popup--with-image &__content {
    display: flex;
    align-items: center;
  }

  &__close {
    position: absolute;
    z-index: 20;
    right: 30px;
    top: 30px;

    @include media-breakpoint-down(sm) {
      right: 13px;
      top: 13px;
    }
  }

  &.is-open {
    transform: translateY(0);
  }

  &__header {
    margin-bottom: 36px;
    word-wrap: break-word;

    .h2:not(.modal__title) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {

      .h1,
      .h2,
      .h3,
      p {
        padding-right: 30px;
      }

      margin-bottom: 30px;
    }
  }

  &__title {
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }

  &__footer {
    margin-top: 50px;

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }

  &__text p {
    font-size: 16px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 16px;
    }

    margin-bottom: 18px;
    @include last-child;

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }

  &__popup--with-image &__text {
    align-self: stretch;
    padding-right: 30px;
    width: 60%;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
      width: 100%;
    }
  }

  &__image {
    flex-shrink: 0;
    right: 0;
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .notifications {
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.modal-close {
  @include reset-button;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: $color-gainsboro-100;
    transition: background-color $transition-main;
    width: 2px;
    // sqrt(23*23 + 23*23)
    height: 32px;

    @include media-breakpoint-down(sm) {
      // sqrt(19*19 + 19*19)
      height: 27px;
    }
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover:before,
  &:hover:after {
    background: darken($color-gainsboro-100, 15%);
  }
}
