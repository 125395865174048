@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

$medColors: (
  'red': #ff0000,
  'orange': #ff8000,
  'yellow': #f2f20d,
  'green': #00ff00,
  'aqua': #00d5ff,
  'blue': #0055ff,
  'violet': #aa00ff,
  'magenta': #ff00ff,
  'light-salmon-pink': #ff9999,
  'peach-orange': #ffcc99,
  'golden-sand': #f0db7d,
  'pale-green': #99ff99,
  'blue-lagoon': #99eeff,
  'pastel-blue': #99bbff,
  'lilac': #dd99ff,
  'lavender-rose': #ff99ff,
  'crimson-red': #990000,
  'rich-gold': #994d00,
  'brown-yellow': #b29705,
  'irish-green': #009900,
  'ocean': #008099,
  'congress-blue': #003399,
  'indigo': #4b0082,
  'dark-magenta': #990099,
  'dusty-red': #b34d4d,
  'coffee': #a6814c,
  'dusty-yellow': #b3b34d,
  'dusty-green': #4db34d,
  'hippie-blue': #4da2b3,
  'flat-blue': #4d6fb3,
  'deep-lilac': #914db3,
  'medium-orchid': #b24db3,
);

@each $color, $value in $medColors {
  .med-color--#{$color} {
    color: $value;
  }
}
