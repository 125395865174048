@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.change-avatar {
  &__avatar {
    margin-bottom: 30px;
    &--loading {
      opacity: 0.6;
      transition: opacity 0.15s;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__buttons > *:not(:last-child) {
    margin-bottom: 10px;
  }

  &__buttons > *,
  &__buttons .button {
    width: 100%;

    @include media-breakpoint-up(xs) {
      width: 200px;
      margin: 0 auto;
    }
  }

  &__modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin-top: 50px;
    }
  }

  &__crupper-wrap {
    width: 100%;
  }
}
