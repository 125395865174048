@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.health-index-dates {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  @include media-breakpoint-up(xxs) {
    font-size: 16px;
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 20px;
  }
  &__item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-gainsboro-100;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    &--empty {
      display: flex;
      align-items: center;
      padding-top: 22px;
      padding-bottom: 22px;
      @include media-breakpoint-up(md) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
  }

  &__title {
    color: $color-primary;
    font-weight: bold;
  }
  &__content {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  &__date {
    &--old {
      opacity: 0.5;
    }
  }
  &__date-time {
    display: inline-block;
  }
  &__date-day {
    display: inline-block;
    margin-left: 10px;
    @include media-breakpoint-up(xxs) {
      margin-left: 20px;
    }
  }

  &__warning {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $color-crimson-100;
    color: $color-white-100;
    @include media-breakpoint-up(xxs) {
      width: 21px;
      height: 21px;
    }
  }
}
