@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.consultation-end {
  $borderSize: 10px;
  $paddingXMobile: 20px;

  position: relative;
  border-radius: $borderSize $borderSize 0 0;
  text-align: center;
  background-color: $color-white;
  box-shadow: 0 2px 10px rgba($color-black-200, 0.3);
  padding-top: 40px;
  padding-bottom: 78px;
  margin-bottom: -30px;

  @include media-breakpoint-down(xl) {
    $paddingY: 20px;

    padding-top: $paddingY;
    padding-bottom: $paddingY;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 16px;
    padding-bottom: 0;
  }
  &__title {
    margin-bottom: 30px;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 23px;
    }
  }
  &__detail {
    &-list {
      display: flex;
      justify-content: space-around;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }

    &-item {
      width: 320px;
      text-align: center;
      padding-right: $paddingXMobile;
      padding-left: $paddingXMobile;

      @include media-breakpoint-down(xs) {
        $paddingY: 20px;

        display: flex;
        width: 100%;
        border-top: 1px solid;
        border-top-color: $color-gainsboro-100;
        padding-top: $paddingY;
        padding-bottom: $paddingY;
      }
    }
    &-button {
      width: 256px;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
  &__label {
    text-align: left;
  }
}
