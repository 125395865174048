@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.loader-bar {
  @include transition(opacity);
  height: 100%;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    $color-primary 25%,
    $color-cornflowerblue-100 25%,
    $color-cornflowerblue-100 50%,
    $color-primary 50%,
    $color-primary 75%,
    $color-cornflowerblue-100 75%,
    $color-cornflowerblue-100
  );
  animation: animate-stripes 0.5s linear infinite;
  opacity: 0;
}

.loader-bar[data-visible='true'] {
  opacity: 1;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
