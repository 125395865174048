@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.modules-manager__modal {
  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .modal__popup {
    height: 100%;
    max-height: 800px;
  }
  .modal__header {
    flex-shrink: 0;
  }
  .modal__content {
    flex-grow: 1;
    overflow: hidden;
  }
  .modules-manager__list {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  @include media-breakpoint-up(md) {
    .modal__popup {
      padding-right: 85px;
    }
  }
}
