@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.edit-medcard {
  $rowPaddingX: 60px;

  padding: 20px 0;

  @include media-breakpoint-up(xs) {
    padding: 40px 0 60px;
  }

  &__paddingX {
    $paddingX: 20px;

    padding-right: $paddingX;
    padding-left: $paddingX;

    @include media-breakpoint-up(xs) {
      padding-right: 5.085%;
      padding-left: 5.085%;
    }
    @include media-breakpoint-up(lg) {
      $paddingX: 114px;

      padding-right: $paddingX;
      padding-left: $paddingX;
    }

    @include media-breakpoint-up(xl) {
      $paddingX: 160px;

      padding-right: $paddingX;
      padding-left: $paddingX;
    }
  }

  &__section {
    padding-bottom: 40px;
    @include media-breakpoint-up(xs) {
      padding-bottom: 60px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $color-gainsboro-100;
      margin-bottom: 30px;

      @include media-breakpoint-up(xs) {
        margin-bottom: 40px;
      }
    }
  }
  &__row {
    display: flex;

    @include media-breakpoint-up(xl) {
      $marginX: $rowPaddingX;

      margin-right: -$marginX;
      margin-left: -$marginX;
    }

    &:last-child .edit-medcard__item:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  &__item {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
      $paddingX: $rowPaddingX;

      padding-right: $paddingX;
      padding-left: $paddingX;
    }
  }
  &__text {
    opacity: 0.5;
    font-size: 14px;
    line-height: 16px;
    color: $color-text;
    margin-bottom: 30px;

    @include media-breakpoint-up(xs) {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-down(xs) {
    &__label {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
