@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.analyses-filter {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-bottom: 44px;
  }
  &__date {
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-right: 30px;
      width: 200px;
    }
  }
  &__category {
    @include media-breakpoint-up(md) {
      width: 280px;
    }
    @include media-breakpoint-up(lg) {
      width: 330px;
    }
    @include media-breakpoint-up(xl) {
      width: 440px;
    }
  }
  &__reset {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    color: $color-error;
    cursor: pointer;
    margin-top: 36px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(md) {
      align-self: center;
      margin-top: 20px;
    }
  }
}
