@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.add-user {
  padding: 60px 120px;
  @include white-sheet;

  @include media-breakpoint-down(xl) {
    padding: 40px 30px 60px;
  }

  @include media-breakpoint-down(xs) {
    padding: 40px 20px 30px;
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    max-width: $block-max-width;
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__card {
    margin-right: 100px;

    @include media-breakpoint-down(xl) {
      margin-right: 60px;
    }

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 30px;
      max-width: 280px;
      width: 100%;
    }
  }

  &__card .card {
    min-width: 250px;
  }

  &__form {
    flex-grow: 1;
    max-width: 480px;

    @include media-breakpoint-down(xl) {
      max-width: none;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__info {
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: center;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 20px;
      display: block;
    }
  }

  &__card .card {
    width: 100%;
    max-width: 250px;

    @include media-breakpoint-down(md) {
      max-width: 280px;
    }
  }
}
