@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.info {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
.left {
  display: flex;
  flex-direction: column;
  padding-right: 15px;

  @include media-breakpoint-down(sm) {
    justify-content: center;
    padding-right: 0;
    margin-bottom: 30px;
  }
}
.image {
  $size: 199px;

  width: $size;
  height: $size;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
  }

  &__block {
    @include media-breakpoint-up(sm) {
      flex-grow: 1;
    }
  }
}

.right {
  @include media-breakpoint-between(sm, lg) {
    width: 55%;
  }

  & :global(.info__item:not(:last-child)) {
    margin-bottom: 20px;
  }
}

.name {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: $color-primary;
  margin-bottom: 30px;

  @include media-breakpoint-up(sm) {
    max-width: 246px;
  }
}
