.hide-check {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
}

.image > img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
}
