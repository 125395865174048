@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';


.modal {
  & :global(.modal__text) {
    padding-top: 106px;
    padding-right: 66px;
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: 40px;
    padding-right: 40px;
    min-height: 447px;
  }
}

.form {
  height: 100%;

  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    justify-content: space-between;
    align-items: flex-start;
  }
}

.header {
  @include media-breakpoint-up(sm) {
    margin-bottom: 12px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;

    & :global(.h2) {
      padding-right: 0
    }
  }
}

.content {
  padding-right: 0;
  flex: 1;
}

.image {
  display: block;
  width: unset;
  max-width: 318px;

  @include media-breakpoint-up(sm) {
    padding: 24px 24px 64px;
  }

  @include media-breakpoint-down(sm) {
    padding: 19px 42px;
    margin-bottom: 32px;
    align-self: center;
  }

  & :global(img) {
    width: 100%;
  }
}

.input {
  @include media-breakpoint-down(sm) {
    margin-bottom: 32px
  }
}
