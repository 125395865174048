@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.questions {
  &__breadcrumbs {
    flex-grow: 1;
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }

  &__title-line {
    .consultations-header__item--search {
      flex-grow: unset;
    }
  }
  &__item--btn {
    .titleline__button {
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      .titleline__button:first-child {
        margin-left: 0;
      }
    }
  }
}

.plug__image_question {
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
    .plug__image {
      display: none;
    }
  }
}
