@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.field {
  position: relative;
  border-bottom: 1px solid;
  border-bottom-color: $color-gainsboro-100;
  transition: border-bottom-color $transition-main;

  &:focus-within:not(&--has-error):not(&--is-disabled) {
    border-bottom-color: $color-primary;
  }

  &--has-error {
    border-bottom-color: $color-error;
  }

  &--no-border {
    border-bottom: none;
  }

  &__label {
    margin-bottom: 2px;
  }
  &__label--disabled {
    color: $color-gray-50;
  }

  &__input {
    position: relative;
  }

  &__error {
    position: absolute;
    top: 100%;
    padding-top: 2px;
    left: 0;
    z-index: 10;
  }
}
