@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.chat-buttons {
  width: 480px;

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }

  &__btn-item {
    padding: 5px;
    flex-grow: 1;
  }

  &__btn {
    width: 100%;
  }
}
