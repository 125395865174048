@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-chart__info-values {
  flex-shrink: 0;
  margin-top: -10px;
  margin-bottom: 10px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 20px;
    margin-top: -20px;
  }

  @include media-breakpoint-between(lg, xl) {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    min-height: 82px;
    margin-top: 10px;
    margin-right: -5px;
    margin-left: -5px;

    @include media-breakpoint-up(sm) {
      min-height: 99px;
      margin-top: 20px;
      margin-right: -10px;
      margin-left: -10px;
    }
    @include media-breakpoint-between(lg, xl) {
      margin-top: 10px;
      margin-right: -5px;
      margin-left: -5px;
    }
  }
}
