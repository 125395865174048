@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.congrats__block {
  display: flex;
  padding: 0px;
  flex-direction: row;

  @include media-breakpoint-down(md) {
    // sqrt(19*19 + 19*19)
    height: 100%;
    padding: 0px;
    flex-direction: column-reverse;
  }
}

.congrats__block-image {
  position: absolute;
  left: 490px;
  top: -200px;
  background: linear-gradient(180deg, #8da0ff 0%, #b982ff 100%);
  height: 1105px;
  width: 757px;
  transform: rotate(27.98deg);
  border-radius: 378.658px;

  @include media-breakpoint-down(md) {
    display: flex;
    position: inherit;
    justify-content: center;
    width: 100%;
    height: 244px;
    border-radius: 0px;
    transform: rotate(0deg);
    top: 0px;
    left: 0px;
    // margin-left: -35px;
    // margin-top: -40px;
  }
}

.congrats__block-text {
  display: flex;
  height: 500px;
  width: 460px;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
  margin-right: 40px;

  @include media-breakpoint-down(md) {
    // sqrt(19*19 + 19*19)
    padding: 20px;
    max-height: 280px;
    width: 100%;
  }
}

.congrats__text-header {
  text-transform: uppercase;
  color: #777ff9;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;

  @include media-breakpoint-down(md) {
    // sqrt(19*19 + 19*19)
    font-size: 18px;
  }
}

.congrats__text {
  font-size: 17px;
  margin-bottom: 78px;
  color: $color-black-100;

  @include media-breakpoint-down(md) {
    font-size: 15px;
    margin-bottom: 30px;
  }
}

.congrats__text-footer {
  font-size: 17px;

  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
}

.congrats__image {
  position: absolute;
  left: 70px;
  top: 400px;
  transform: rotate(332.02deg);
  max-height: 250px;

  @include media-breakpoint-down(md) {
    display: flex;
    position: inherit;
    transform: rotate(0deg);
    max-width: 180px;
    align-self: center;
    justify-content: center;
  }
}

.congrats__modal__content {
  overflow: hidden;
  padding: 0px;
}
