@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.consultations-item {
  position: relative;
  height: 100%;

  &__toggle {
    $margin: -5px;

    position: absolute;
    top: $margin;
    right: $margin;
    z-index: 10;
  }

  &__toggle .favorite {
    $size: 45px;
    width: $size;
    height: $size;

    @include media-breakpoint-down(xs) {
      $size: 34px;

      width: $size;
      height: $size;

      &-icon {
        width: 18px;
      }
    }
  }
  &__toggle .favorite-icon {
    @include media-breakpoint-down(xs) {
      width: 18px;
    }
  }
}
