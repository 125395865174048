@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.status {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;

  @include media-breakpoint-down(xs) {
    font-size: 10px;
    line-height: 11px;
  }
}

.status--new {
  color: $color-mediumturquoise-100;
}

.status--inwork {
  color: $color-mediumorchid-100;
}

.status--finished {
  color: $color-limegreen-100;
}

.status--canceled {
  color: $color-crimson-100;
}
