@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.back {
  $color: $color-darkgray-200;

  color: $color;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  @include transition(color);

  &:hover {
    color: darken($color, 15);
  }
}
.arrow {
  flex-shrink: 0;
  margin-right: 14px;
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    transform: rotate(135deg);
  }
}
