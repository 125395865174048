@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.not-found {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__message {
    padding-top: 20px;
    padding-bottom: 20px;
    color: $color-white-100;
    font-weight: bold;
    text-align: center;
  }

  &__code {
    font-size: 96px;
    margin-bottom: 60px;

    @include media-breakpoint-down(xs) {
      font-size: 56px;
      margin-bottom: 30px;
    }
  }

  &__message {
    font-size: 32px;

    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
  }
}

[class$='main-template'] .not-found__message {
  color: $color-cornflowerblue-100;
}
