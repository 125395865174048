@font-face {
  font-family: 'Roboto';
  src: local('Roboto Regular'), local('Roboto-Regular'),
    url('/static/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('/static/fonts/Roboto/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('/static/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
    url('/static/fonts/Roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('/static/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
    url('/static/fonts/Roboto/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Regular'), local('Open Sans-Regular'),
    url('/static/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
    url('/static/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans SemiBold'), local('Open Sans-SemiBold'),
    url('/static/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
    url('/static/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('Open Sans-Bold'),
    url('/static/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
    url('/static/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
