@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.chart {
  & .recharts {
    $linesColor: $color-primary;

    &-cartesian {
      &-axis {
        & line {
          stroke: $linesColor;
        }
        &-tick-value {
          font-size: 10px;
          line-height: 12px;
          fill: $color-primary;

          @include media-breakpoint-up(xs) {
            font-size: 14px;
            line-height: 16px;
          }

          @include media-breakpoint-up(sm) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      &-grid {
        &-vertical line:nth-last-child(-n + 2) {
          display: none;
        }
        line {
          stroke: $linesColor;
        }
      }
    }
    &-xAxis .recharts-cartesian-axis-ticks g text {
      fill: $color-primary;
      font-size: 9px;
      line-height: 11px;

      @include media-breakpoint-up(xs) {
        font-size: 12px;
        line-height: 14px;
      }

      @include media-breakpoint-up(sm) {
        font-size: 16px;
        line-height: 19px;
      }
    }
    &-reference-line line {
      stroke: $color-primary;
    }
  }
  .axis-y-line__text {
    font-size: 10px;
    fill: $color-primary;

    @include media-breakpoint-up(xs) {
      font-size: 12px;
    }

    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
  }
  &__shape {
    &-fill {
      fill: $color-primary;
    }
    &-nofill {
      fill: none;
    }
    &-stroke {
      stroke: $color-primary;
    }
  }
  &__stitch-line {
    & path {
      stroke: $color-primary;
    }
    & .recharts-line-dot {
      fill: $color-primary;
    }
  }
}
