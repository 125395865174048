@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-chart__info-value {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: $color-primary;

  @include media-breakpoint-up(sm) {
    line-height: 35px;
    font-size: 30px;
  }

  &-item {
    width: 50%;
    min-height: 100%;
    padding: 15px 20px 20px 15px;
    margin-right: 5px;
    margin-left: 5px;

    @include media-breakpoint-up(xl) {
      &:not(.metric-chart__info-empty) {
        padding-left: 40px;
      }
    }
    @include media-breakpoint-up(sm) {
      margin-right: 10px;
      margin-left: 10px;
    }

    @include media-breakpoint-between(lg, xl) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &-description {
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    opacity: 0.5;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
      line-height: 19px;
      margin-top: 5px;
    }
  }
}
