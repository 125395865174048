@import '@styles/abstracts/variables.scss';
.react-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  $color: $color-primary;
  display: flex;
  align-items: stretch;
  width: 88%;
  border-bottom: 1px solid;
  font-size: 18px;
  line-height: 21px;
  color: $color;
  border-bottom-color: currentColor;
  transition: color $transition-main;
  margin: 0 auto 30px;

  @include media-breakpoint-up(xs) {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
  }
}

.react-tabs__tab {
  cursor: pointer;
  text-align: center;
  padding-bottom: 10px;
  list-style: none;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.5;
    padding-bottom: 8px;
  }
}

.react-tabs__tab--selected {
  opacity: 1;
  border-bottom: 3px solid;
  font-weight: 500;
}
.react-tabs__tab--long {
  width: 386px;
}

.react-tabs__tab--disabled {
  color: $color-lightsteelblue-100;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  flex-grow: 1;
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
