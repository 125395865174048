@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.accordion {
  $paddingX: 40px;
  $paddingXTablet: 20px;

  &__item {
    @include reset-button;
    @include transition(height);

    $padding: 0px;
    background-color: $color-white-100;

    font-size: 18px;
    line-height: 21px;
    padding: $padding;
    width: 100%;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;

      @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
      }
    }
  }
  &__title {
    $paddingY: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 81px;
    color: $color-primary;
    padding: $paddingY $paddingX;

    @include media-breakpoint-down(xl) {
      padding-right: $paddingXTablet;
      padding-left: $paddingXTablet;
    }

    @include media-breakpoint-down(xs) {
      height: auto;
      min-height: 56px;
    }
  }
  &__question {
    flex-grow: 1;
    max-width: 90%;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 15px;
  }
  &__toggle {
    $size: 18px;
    $borderSize: 2px;

    flex-shrink: 0;
    width: $size;
    height: $size;
    cursor: pointer;
    border-style: solid;
    border-width: 0 0 $borderSize $borderSize;
    border-color: currentColor;
    margin-top: -14px;
    @include transition(transform, margin-top);
    transform: rotate(-45deg);

    @include media-breakpoint-down(xs) {
      $size: 12px;

      width: $size;
      height: $size;
      margin-top: -8px;
    }

    &.toggler-checked {
      transform: rotate(135deg);
      margin-top: 7px;

      @include media-breakpoint-down(xs) {
        margin-top: 5px;
      }
    }
  }
  &__answer {
    border-top: 1px solid;
    border-top-color: $color-gainsboro-100;
    padding: 20px $paddingX 30px;

    @include media-breakpoint-down(xl) {
      padding-right: $paddingXTablet;
      padding-left: $paddingXTablet;
    }

    &-wrapper {
      height: 0;
      overflow: hidden;
      @include transition(height);

      &:not(.toggle-active) {
        display: none;
      }
    }
  }
}
