@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.modal {
  max-width: 718px;
  padding: 48px 107px;
  align-items: center;
  text-align: center;

  @include media-breakpoint-down(xs) {
    padding: 32px 24px;
  }
}

.check-icon {
  margin-bottom: 24px;
  color: #777FF9;
}

.header {
  margin-bottom: 24px;
}

.title {
  font-size: 24px;
  font-weight: 500;

  @include media-breakpoint-down(xs) {
    font-size: 20px;
  }
}

.footer {
  margin-top: 32px;
  width: 100%;
  justify-content: space-between;
}

.buttons {
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.alert-button-wrapper {
  @include media-breakpoint-down(sm) {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%
  }
}

.alert-button {
  @include media-breakpoint-down(sm) {
    width: 100%
  }
}

.content {
  max-width: 414px;
  padding-right: 0;
}
