@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.analysis-box {
  display: flex;
  padding: 12px 0;
  &__content {
    width: 100%;
  }
  &__icon {
    align-self: flex-start;
    margin-top: 5px;
    flex-shrink: 0;
    width: 23px;
    margin-right: 12px;
    color: $color-mediumslateblue-200;
    @include media-breakpoint-up(xs) {
      width: 34px;
      margin-right: 20px;
      margin-top: 2px;
    }
  }
  &__line {
    width: 100%;
    display: flex;
  }
  &__title {
    font-size: 14px;
    margin-right: 20px;
    margin-bottom: 6px;
    @include media-breakpoint-up(xs) {
      font-size: 18px;
      margin-bottom: 8px;
      margin-right: 40px;
    }
  }
  &__category {
    font-size: 10px;
    opacity: 0.6;
    @include media-breakpoint-up(xs) {
      font-size: 14px;
    }
  }
  &__date {
    margin-left: auto;
    font-size: 10px;
    opacity: 0.6;
    @include media-breakpoint-up(xs) {
      font-size: 14px;
    }
  }
  &__date-time {
    display: inline-block;
    margin-left: 16px;
  }
  &__date-day {
    display: inline-block;
  }
  &__download {
    display: block;
    cursor: pointer;
    flex-shrink: 0;
    margin-left: auto;
    color: inherit;
    color: $color-primary;
    transition: all 0.4s;
  }
}
