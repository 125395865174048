@import './../variables.scss';
@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.grid {
  margin-top: $marginTopGrid;

  @include media-breakpoint-down(xs) {
    margin-top: $marginTopGridMobile;
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 30px;
  border: 2px solid;
  padding: 40px $paddingItemX;

  &:not(&__active) {
    background-color: $color-white;
  }

  &:not(&__expiring):not(&__active) {
    border-color: $color-lavender-200;
  }

  @include media-breakpoint-down(xs) {
    padding: $paddingItemMobile;
  }

  &__active {
    background-color: $color-palesteelblue-100;
    border-color: $color-lavender-400;
  }
  &__expiring {
    border-color: $color-error;
  }
}

.title {
  font-size: 28px;
  line-height: 130%;
  font-weight: 700;
  margin-bottom: 20px;

  @include media-breakpoint-down(xs) {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.labels {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 5px;
  }
}

.label {
  border-radius: 14px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 15px;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
    border-radius: 12px;
  }
}

.type {
  color: $color-white;

  &__personal {
    background-color: $color-deepskyblue-100;
  }

  &__family {
    background-color: $color-violet-100;
  }

  &__children {
    background-color: $color-mediumturquoise-200;
  }
}

.duration {
  color: $color-primary;
  border: 1px solid currentColor;

  @include media-breakpoint-down(xs) {
    margin-left: 5px;
  }

  &-with-margin {
    margin-left: 10px;
  }
}

.description {
  margin-bottom: 40px;

  @include media-breakpoint-down(xs) {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.info {
  margin-bottom: 25px;

  @include media-breakpoint-down(xs) {
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__description {
    display: flex;
    align-items: center;
  }

  &__consultations {
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    margin-right: 5px;
  }

  &__icon {
    $size: 30px;

    width: $size;
    height: $size;
    color: $color-deepskyblue-100;
    margin-right: 20px;

    @include media-breakpoint-down(xs) {
      margin-right: 10px;
    }
  }
}

$marginBottom: 30px;
$marginBottomMobile: 20px;

.costs {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  // margin-top: auto;
  margin-bottom: $marginBottom;

  @include media-breakpoint-down(xs) {
    margin-bottom: $marginBottomMobile;
  }
}

.full-cost {
  flex-grow: 1;

  &__previous {
    margin-bottom: 15px;

    @mixin costPreviousMedia {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(xs) {
      @include costPreviousMedia();
    }

    @include media-breakpoint-between(sm, lg) {
      @include costPreviousMedia();
    }
  }
}

.by-one-cost {
  $borderRadius: 16px;

  flex-shrink: 0;
  border-radius: $borderRadius 0 0 $borderRadius;
  color: $color-darkgray-200;
  background-color: $color-palesteelblue-100;
  padding: 15px 20px;
  margin-right: -#{$paddingItemX};

  @include media-breakpoint-down(xs) {
    margin-right: -#{$paddingItemMobile};
  }

  &__bordered {
    $borderSize: 2px;

    border-style: solid;
    border-width: $borderSize 0 $borderSize $borderSize;
    border-color: $color-lavender-400;
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__description {
    width: 100%;
    font-size: 14px;
    line-height: 145%;
    margin-bottom: 5px;

    @include media-breakpoint-down(xs) {
      font-size: 12px;
    }
  }

  &__previous {
    font-size: 12px;
    line-height: 145%;
    text-decoration: line-through;
    margin-right: 10px;

    @include media-breakpoint-down(xs) {
      font-size: 10px;
    }
  }

  &__current {
    font-size: 20px;
    line-height: 120%;
    font-weight: 700;
  }
}

.cost {
  font-size: 50px;
  font-weight: 500;
  line-height: 110%;

  @mixin costPriceMedia {
    font-size: 28px;
  }

  @include media-breakpoint-down(xs) {
    @include costPriceMedia();
  }

  @include media-breakpoint-between(sm, lg) {
    @include costPriceMedia();
  }

  &__previous {
    font-size: 24px;
    line-height: 130%;
    text-decoration: line-through;
    color: $color-darkgray-200;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }

    @include media-breakpoint-between(sm, lg) {
      font-size: 18px;
    }
  }
}

.promocode {
  margin-top: auto;
  margin-bottom: 25px;
}

.one-time-cost {
  border-width: 2px 0;
  border-style: solid;
  text-align: center;
  padding: 20px 0;
  margin: 0 -#{$paddingItemX} $marginBottom;

  .item__active & {
    border-color: $color-lavender-400;
  }

  .item:not(.item__active) & {
    border-color: $color-lavender-300;
  }

  @include media-breakpoint-down(xs) {
    $paddingY: 10px;
    $margin: -#{$paddingItemMobile};

    padding-top: $paddingY;
    padding-bottom: $paddingY;
    margin-right: $margin;
    margin-bottom: $marginBottomMobile;
    margin-left: $margin;
  }

  &__prices {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__previous {
    margin-right: 25px;

    @include media-breakpoint-down(xs) {
      margin-right: 10px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 130%;
    color: $color-darkgray-200;
    margin-bottom: 10px;

    @include media-breakpoint-down(xs) {
      font-size: 10px;
      margin-bottom: 5px;
    }

    @include media-breakpoint-between(sm, lg) {
      font-size: 12px;
    }
  }
}

.active-label {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 20;
  cursor: pointer;
  border-radius: 3000px;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-white;
  padding: 5px 15px;
  transform: translate(-50%, -50%);

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }

  &:not(&__expiring) {
    background-color: $color-primary;
  }

  &__tooltip {
    $backgroundColor: $color-darkslategray-200;

    position: absolute;
    top: 50%;
    right: -22px;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    border-radius: 8px;
    font-weight: 500;
    text-transform: none;
    white-space: nowrap;
    background-color: $backgroundColor;
    padding: 15px 10px;
    transform: translate(100%, -50%);
    @include transition(opacity);

    &:before {
      $size: 12px;

      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: $size;
      height: $size;
      background-color: $backgroundColor;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    @include media-breakpoint-down(sm) {
      $paddingY: 10px;

      top: auto;
      right: auto;
      bottom: -20px;
      left: 50%;
      font-size: 14px;
      padding-top: $paddingY;
      padding-bottom: $paddingY;
      transform: translate(-50%, 100%);

      &:before {
        top: 0;
        left: 50%;
      }
    }

    .active-label:hover & {
      opacity: 1;
      z-index: 10;
      pointer-events: auto;
    }
  }

  &__expiring {
    background-color: $color-error;
  }
}
