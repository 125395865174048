@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.pulse-info-table {
  @include media-breakpoint-down(xxxs) {
    overflow-x: auto;
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    border-top: 1px solid $color-gainsboro-100;
    @include media-breakpoint-up(xxs) {
      font-size: 14px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
    td:first-child {
      vertical-align: bottom;
      color: $color-primary;
      border-right: 1px solid $color-gainsboro-100;
      font-weight: 500;
      @include media-breakpoint-up(xxxs) {
        width: 14%;
      }
    }
  }

  &__left-title {
    padding-bottom: 5px;
    padding-right: 5px;
    border-bottom: 1px solid $color-gainsboro-100;
  }
  &__data-title {
    padding: 10px 8px 0;
    color: $color-primary;
    font-weight: 500;
    text-align: center;
    @include media-breakpoint-down(xxs) {
      padding: 8px 4px 0;
    }
    &--normal {
      color: $color-limegreen-100;
    }
  }
  &__data-cells {
    display: flex;
    padding-top: 10px;
    @include media-breakpoint-down(xxs) {
      padding-top: 6px;
    }
  }
  &__data-cell {
    width: 10%;
    padding: 5px 4px;
    border-right: 1px solid $color-gainsboro-100;
    border-bottom: 1px solid $color-gainsboro-100;
    text-align: center;

    @include media-breakpoint-down(xxs) {
      padding-left: 2px;
      padding-right: 2px;
    }
    &--normal {
      border-color: $color-limegreen-100;
    }
  }
}
