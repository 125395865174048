@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.modal--restore-password,
.modal__content.modal--restore-password {
  position: static;
}
.modal--restore-password .modal__text {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.modal__popup--restore-password,
.modal__popup--restore-password.modal__popup {
  max-width: 916px;
}
.modal__popup--restore-password .modal__image {
  justify-content: flex-end;
}
.restore-password {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &--create-new {
    padding-top: 16px;
  }

  @at-root &__text,
    p#{&}__text {
    margin-bottom: 30px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-top: 20px;
  }

  &__bottom-btn {
    flex-shrink: 0;
  }

  &__bottom-timer {
    margin-left: 20px;
  }
}
