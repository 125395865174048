@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.consultation-end__mark {
  &-list {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    @include media-breakpoint-down(xl) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(xs) {
      $paddingXMobile: 20px;

      padding-right: $paddingXMobile;
      padding-left: $paddingXMobile;
      margin-bottom: 25px;
    }
  }
  &-item {
    $activeColor: $color-crimson-100;
    $disabledColor: $color-darkgray-100;

    $size: 50px;

    flex-shrink: 0;
    width: $size;
    height: $size;
    color: $activeColor;
    margin-right: 21px;

    .consultation-end__mark-list-no-mark & {
      color: $disabledColor;
    }

    &-checked ~ .consultation-end__mark-item {
      color: $disabledColor;
    }

    @include media-breakpoint-down(xxs) {
      margin-right: 9px;
    }
  }
}
