@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.explanation-gray {
  position: relative;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid $color-gainsboro-100;

  &__info {
    $size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    width: $size;
    height: $size;
    border-radius: 50%;
    color: #fff;
    background: $color-gainsboro-100;
  }

  &__icon,
  &__icon.svg {
    display: block;
    height: 8px;
  }

  &__text {
    font-size: 14px;
    color: $color-darkgray-200;
  }
}
