@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.burger {
  @include reset-button;
  display: flex;
  align-items: center;

  &__text {
    @include media-breakpoint-down(md) {
      color: currentColor;
      font-weight: 600;
      margin-left: 15px;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}
