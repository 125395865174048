@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.cough-detail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid $color-gainsboro-100;

  &__audio-label {
    @include media-breakpoint-up(sm) {
      width: 100%;
    }
  }

  &__audio-line {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 14px;
    justify-content: space-between;
  }

  &__audio-warning {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $color-error;
    color: #fff;
  }

  &__audio {
    padding: 10px 20px 10px 20px;
    min-height: 60px;
    width: 340px;
    max-width: 100%;
    border-radius: 16px;
    background-color: $color-mediumslateblue-200;
    color: #fff;

    .file__audio-play-btn {
      width: 40px;
      display: flex;
      align-items: center;
    }

    .svg-play {
      width: 23px;
    }

    .svg-pause {
      width: 16px;
    }
    .file__title {
      margin-bottom: 6px;
    }

    .file__title {
      font-size: 16px;
    }
  }
}
