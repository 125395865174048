@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.edit-medcard {
  &__contacts {
    margin-bottom: 40px;

    @include media-breakpoint-up(xs) {
      margin-bottom: 60px;
    }

    &-item:not(:last-child) {
      margin-bottom: 40px;
    }
  }
  &__delete {
    position: relative;
    cursor: pointer;

    &-icon {
      position: relative;
      top: 0;
      right: 0;
    }
    &-text {
      position: absolute;
      top: 50%;
      right: -16px;
      white-space: nowrap;
      font-size: 16px;
      line-height: 18px;
      font-weight: bold;
      color: $color-error;
      transform: translate(100%, -50%);

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid currentColor;
        transition: width $transition-main;

        .edit-medcard__delete:hover & {
          width: 0;
        }
      }
    }
  }
}
