@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.pressure-static-info-table {
  width: 100%;
  border-collapse: collapse;
  th {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-primary;
  }

  th,
  td {
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-down(sm) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include media-breakpoint-down(xxxs) {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  th {
    padding-bottom: 10px;
  }
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    @include media-breakpoint-down(xs) {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
    @include media-breakpoint-up(lg) {
      width: 55%;
    }
  }

  td {
    border: 1px solid $color-gainsboro-100;
  }
  td:not(:first-child) {
    white-space: nowrap;
  }
  td:first-child {
    border-left: 0;
  }
  td:last-child {
    border-right: 0;
  }
}
