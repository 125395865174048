@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.modules-manager__item {
  $paddingY: 15px;

  z-index: 1000 !important;
  display: flex;
  align-items: center;
  font-family: $font-main;
  font-size: 14px;
  line-height: 16px;
  color: $color-text;
  padding-top: $paddingY;
  padding-bottom: $paddingY;

  @include media-breakpoint-up(xs) {
    font-size: 18px;
    line-height: 21px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-gainsboro-100;
  }

  &-icon {
    flex-shrink: 0;
    width: 26px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 12px;

    &-index {
      background-image: url('/static/img/health-main/index-min.png');
    }
    &-pressure {
      background-image: url('/static/img/health-main/pressure-min.png');
    }
    &-accurate_pressure {
      background-image: url('/static/img/health-main/accurate_pressure-min.png');
    }
    &-pulse {
      background-image: url('/static/img/health-main/pulse-min.png');
    }
    &-oxygen {
      background-image: url('/static/img/health-main/oxygen-min.png');
    }
    &-ecg {
      background-image: url('/static/img/health-main/ecg-min.png');
    }
    &-sleep {
      background-image: url('/static/img/health-main/sleep-min.png');
    }
    &-temperature {
      background-image: url('/static/img/health-main/temperature-min.png');
    }
    &-medicines {
      background-image: url('/static/img/health-main/medicines-min.png');
    }
    &-activity {
      background-image: url('/static/img/health-main/activity-min.png');
    }
    &-weight {
      background-image: url('/static/img/health-main/weight-min.png');
    }
    &-birthmark {
      background-image: url('/static/img/health-main/birthmark-min.png');
    }
    &-analyzes {
      background-image: url('/static/img/health-main/analyzes-min.png');
    }
    &-diet {
      background-image: url('/static/img/health-main/diet-min.png');
    }
    &-womens-calendar {
      background-image: url('/static/img/health-main/womens-calendar-min.png');
    }
    &-cough {
      background-image: url('/static/img/health-main/cough-min.png');
    }
    &-x_ray {
      background-image: url('/static/img/health-main/x-ray-min.png');
    }
    &-water-balance {
      background-image: url('/static/img/health-main/water-balance-min.png');
    }
    &-workouts {
      background-image: url('/static/img/health-main/workouts-min.png');
    }
    &-food-diary {
      background-image: url('/static/img/health-main/food-diary-min.png');
    }
    &-caffeine {
      background-image: url('/static/img/health-main/caffeine-min.png');
    }
    &-blood-sugar {
      background-image: url('/static/img/health-main/blood-sugar-min.png');
    }
    &-breath {
      background-image: url('/static/img/health-main/breath-min.png');
    }
    &-tests {
      background-image: url('/static/img/health-main/tests-min.png');
    }
  }
  &-titles {
    flex-grow: 1;
  }
  &-additional {
    text-transform: lowercase;
  }
  &-replacer {
    $color: $color-gainsboro-100;

    width: 16px;
    cursor: pointer;
    color: $color;
    transition: color $transition-main;
    margin-left: 20px;

    &:hover {
      color: darken($color, 15%);
    }

    &-icon {
      width: 100%;
    }
  }
}
