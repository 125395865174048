@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.explanation {
  $size: 16px;
  $color: $color-primary;

  position: relative;
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  font-size: 11px;
  line-height: 13px;
  color: $color-white;
  background-color: $color;
  @include transition(background-color);

  &--titleline {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 19px;
    height: 19px;

    @include media-breakpoint-up(xxs) {
      width: 26px;
      height: 26px;
    }

    & .explanation__tooltip {
      max-width: calc(100vw - 50px);
      width: 425px;
    }
    .svg-info {
      width: 3px;

      @include media-breakpoint-up(xxs) {
        width: 4px;
      }
    }
  }

  &:hover {
    background-color: darken($color, 15%);

    & .explanation__tooltip {
      pointer-events: auto;
      z-index: 10;
      opacity: 1;
    }
  }

  &__symbol {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tooltip {
    $borderRadius: 10px;

    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    text-transform: none;
    background-color: $color-white;
    color: $color-text;
    -webkit-box-shadow: 0 2px 10px $color;
    -moz-box-shadow: 0 2px 10px $color;
    box-shadow: 0 2px 10px $color-primary-transparent;
    @include transition(opacity);
    padding: 30px;

    @include media-breakpoint-up(xs) {
      font-size: 16px;
      line-height: 18px;
    }

    @include media-breakpoint-up(xl) {
      padding: 20px 30px;
    }

    &[data-popper-placement='top-start'] {
      border-radius: $borderRadius $borderRadius $borderRadius 0;
    }
    &[data-popper-placement='top-end'] {
      border-radius: $borderRadius $borderRadius 0 $borderRadius;
    }
    &[data-popper-placement='bottom-end'] {
      border-radius: $borderRadius 0 $borderRadius $borderRadius;
    }
  }
}
