@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.input-radio {
  padding-top: 10px;
  padding-bottom: 10px;

  &__item + &__item {
    margin-top: 10px;
  }
}

.input-radio-item {
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;

  &__label {
    display: inline-block;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    color: $color-black;

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__check {
    $size: 18px;
    $borderColor: $color-gainsboro-100;

    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    display: inline-block;
    width: $size;
    height: $size;
    border: 1px solid;
    border-color: $borderColor;
    border-radius: 50%;
    transition: $transition-main;
    margin-right: 22px;

    @include media-breakpoint-down(xs) {
      $size: 16px;

      width: $size;
      height: $size;
      margin-right: 10px;
    }
  }

  &__check:after {
    $size: 8px;
    $margin: 4px;
    $checkedColor: $color-primary;

    content: '';
    position: absolute;
    top: $margin;
    left: $margin;
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: 50%;
    opacity: 0;
    background-color: $checkedColor;
    transition: $transition-main;

    @include media-breakpoint-down(xs) {
      $margin: 3px;

      top: $margin;
      left: $margin;
    }
  }

  &__original:checked ~ .input-radio-item__check:after {
    opacity: 1;
  }
}

[class$='has-error'] .input-radio-item__check {
  border-color: $color-crimson-100;
}
