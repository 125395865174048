@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.list-cards {
  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    $paddingY: 20px;

    padding-top: $paddingY;
    padding-bottom: $paddingY;
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include media-breakpoint-down(lg) {
    $paddingX: 15px;

    &__inner {
      margin-left: -$paddingX;
      margin-right: -$paddingX;
    }

    &__item {
      padding-right: $paddingX;
      padding-left: $paddingX;
    }
  }

  @include media-breakpoint-down(xs) {
    &__item {
      $paddingY: 5px;

      flex: 0 0 100%;
      max-width: 100%;
      padding-top: $paddingY;
      padding-bottom: $paddingY;
    }
  }
}

.list-card {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  &__row {
    flex-shrink: 0;
  }
}

.list-cards.list-cards--full {
  @include white-sheet;

  .list-cards__item + .list-cards__item {
    border-top: 1px solid $color-gainsboro-100;
  }

  @include media-breakpoint-up(md) {
    .list-cards__item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    background: unset;
    box-shadow: none;
    border-radius: 0;

    .list-cards__item + .list-cards__item {
      border-top: none;
    }

    .list-card {
      @include white-sheet;
    }
  }
}
