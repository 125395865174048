@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.img-btn-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-primary;

  &:hover &__text:after {
    width: 100%;
  }

  &__text {
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 0;
      height: 1px;
      background: currentColor;
      transition: width $transition-main;
      transform: translateY(2px);
    }
  }

  &__icon {
    $size: 25px;
    width: $size;
    height: $size;
    flex-shrink: 0;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    margin-left: 20px;
  }
}
