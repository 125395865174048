@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.testimony {
  &__editor {
    display: none;

    @include media-breakpoint-down(sm) {
      $paddingX: 7.5px;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $color-primary;
      border-bottom-color: currentColor;
      padding-bottom: 20px;
      margin-top: 15px;
      margin-right: $paddingX;
      margin-left: $paddingX;
    }

    &-text {
      margin-left: 20px;
    }
  }
  &__list {
    $marginX: -7.5px;

    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: -15px;
    margin-right: $marginX;
    margin-left: $marginX;

    @include media-breakpoint-up(xs) {
      $marginX: -10px;

      margin-top: -20px;
      margin-right: $marginX;
      margin-left: $marginX;
    }
  }
}
