@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.continue-by-sms {
  margin-bottom: 16px;
}

.policy {
  margin-bottom: 32px;

  @include media-breakpoint-down(md) {
    margin-bottom: 24px;
  }
}
