@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.block-page__text {
  font-family: 'Roboto';
  font-style: normal;

  &-header {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    //padding-left: 30px;
    color: #777ff9;

    @include media-breakpoint-down(xs) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &-body {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #212121;
    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;

      opacity: 0.5;
    }
  }
}

.block-page__container {
  background-color: #fff;
  padding-top: 40px;
  // padding-left: 90px;
  border-radius: 10px;
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
  }
}

.block-page__link-qr {
  @include media-breakpoint-down(xs) {
    width: 0px;
    height: 0px;
  }
}

.block-page__image {
  width: 616px;
  height: 440px;
  position: relative;
  left: 40px;
  z-index: 1;
  @include media-breakpoint-down(xs) {
    width: 337px;
    height: 222px;
    left: 0;
  }
}

.block-page__links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 24px;
  @include media-breakpoint-down(xs) {
    flex-direction: row;
  }
}

.block-page__block-links {
  display: flex;
  flex-direction: row;
  margin-top: 63px;
  @include media-breakpoint-down(xs) {
    left: 0px;

    margin-left: -30px;
  }
}

.block-page__text-container {
  width: 276px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  justify-content: space-between;
  height: 160px;
  @include media-breakpoint-down(xs) {
    width: auto;
    padding-right: 60px;
    height: 100px;
    margin-top: 20px;
  }
}
.block-page__left-conatiner {
  position: relative;
  left: 120px;
  z-index: 2;
  @include media-breakpoint-down(xs) {
    left: 0px;
  }
}
.block-page__inner-container {
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(xs) {
    flex-direction: column-reverse;
  }
}

.block-page__link-image {
  width: 136px;
  height: 40px;
  @include media-breakpoint-down(xs) {
    width: 142px;
    height: 42px;
    margin: 5px;
  }
}
