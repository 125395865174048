::selection {
  background-color: $color-orchid-100;
  color: $color-white-100;
}
.h1 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  color: $color-black;
  margin-bottom: 40px;

  @include media-breakpoint-down(xs) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
  }
}

.h2 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-primary;
  margin-bottom: 30px;

  @include media-breakpoint-down(xs) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  color: $color-primary;
  display: block;
  margin-bottom: 12px;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  &__bigger {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 26px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }
  }
}

.footnote {
  font-size: 14px;
  line-height: 19px;
  color: rgba($color-white, 0.6);
}

.error {
  font-size: 12px;
  line-height: 14px;
  color: $color-error;

  @include media-breakpoint-down(xs) {
    font-size: 10px;
    line-height: 12px;
  }
}

.bold {
  font-weight: 700;
}

.link {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  color: $color-primary;

  &:hover {
    text-decoration: none;
  }
}

.description {
  color: rgba($color-black, 0.5);
}
