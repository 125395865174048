@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $color-darkgray-100;
  font-size: 16px;
  line-height: 120%;

  @include media-breakpoint-down(lg) {
    padding: 40px 0;
  }

  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;

    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }
  }
  &__button {
    display: inline-block;
    margin-top: 35px;
  }
}
