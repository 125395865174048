.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.svg {
  position: relative;
  display: inline-block;

  &>svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    fill: currentColor;
  }
}

.icon-airplane {
  font-size: (35/30) * 1em;
  width: (35/30) * 1em;
}

.svg-airplane {
  width: (35) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (30/35) * 100%;
  }
}

.icon-camera {
  font-size: (24/24) * 1em;
  width: (24/24) * 1em;
}

.svg-camera {
  width: (24) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (24/24) * 100%;
  }
}

.icon-cards {
  font-size: (27/18) * 1em;
  width: (27/18) * 1em;
}

.svg-cards {
  width: (27) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (18/27) * 100%;
  }
}

.icon-clip {
  font-size: (26/28) * 1em;
  width: (26/28) * 1em;
}

.svg-clip {
  width: (26) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (28/26) * 100%;
  }
}

.icon-document {
  font-size: (21/29) * 1em;
  width: (21/29) * 1em;
}

.svg-document {
  width: (21) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (29/21) * 100%;
  }
}

.icon-download {
  font-size: (18/19) * 1em;
  width: (18/19) * 1em;
}

.svg-download {
  width: (18) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (19/18) * 100%;
  }
}

.icon-eye {
  font-size: (27/19) * 1em;
  width: (27/19) * 1em;
}

.svg-eye {
  width: (27) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (19/27) * 100%;
  }
}

.icon-hamburger {
  font-size: (26/25) * 1em;
  width: (26/25) * 1em;
}

.svg-hamburger {
  width: (26) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (25/26) * 100%;
  }
}

.icon-handbook {
  font-size: (27/20) * 1em;
  width: (27/20) * 1em;
}

.svg-handbook {
  width: (27) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (20/27) * 100%;
  }
}

.icon-heart {
  font-size: (25/22) * 1em;
  width: (25/22) * 1em;
}

.svg-heart {
  width: (25) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (22/25) * 100%;
  }
}

.icon-info {
  font-size: (4/17) * 1em;
  width: (4/17) * 1em;
}

.svg-info {
  width: (4) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (17/4) * 100%;
  }
}

.icon-upload-picture {
  font-size: (103/103) * 1em;
  width: (103/103) * 1em;
}

.svg-upload-picture {
  width: (103) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (103/103) * 100%;
  }
}

.icon-warning {
  font-size: (2/13) * 1em;
  width: (2/13) * 1em;
}

.svg-warning {
  width: (2) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (13/2) * 100%;
  }
}

.icon-logo {
  font-size: (166/106) * 1em;
  width: (166/106) * 1em;
}

.svg-logo {
  width: (166) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (106/166) * 100%;
  }
}

.icon-logout {
  font-size: (28/28) * 1em;
  width: (28/28) * 1em;
}

.svg-logout {
  width: (28) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (28/28) * 100%;
  }
}

.icon-microphone {
  font-size: (19/28) * 1em;
  width: (19/28) * 1em;
}

.svg-microphone {
  width: (19) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (28/19) * 100%;
  }
}

.icon-pause {
  font-size: (18/30) * 1em;
  width: (18/30) * 1em;
}

.svg-pause {
  width: (18) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (30/18) * 100%;
  }
}

.icon-phone {
  font-size: (28/28) * 1em;
  width: (28/28) * 1em;
}

.svg-phone {
  width: (28) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (28/28) * 100%;
  }
}

.icon-play {
  font-size: (21/26) * 1em;
  width: (21/26) * 1em;
}

.svg-play {
  width: (21) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (26/21) * 100%;
  }
}

.icon-profile {
  font-size: (20/23) * 1em;
  width: (20/23) * 1em;
}

.svg-profile {
  width: (20) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (23/20) * 100%;
  }
}

.icon-search {
  font-size: (20/20) * 1em;
  width: (20/20) * 1em;
}

.svg-search {
  width: (20) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (20/20) * 100%;
  }
}

.icon-squares {
  font-size: (24/24) * 1em;
  width: (24/24) * 1em;
}

.svg-squares {
  width: (24) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (24/24) * 100%;
  }
}

.icon-support {
  font-size: (27/27) * 1em;
  width: (27/27) * 1em;
}

.svg-support {
  width: (27) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (27/27) * 100%;
  }
}

.icon-talk {
  font-size: (26/23) * 1em;
  width: (26/23) * 1em;
}

.svg-talk {
  width: (26) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (23/26) * 100%;
  }
}

.icon-health,
.icon-health-active {
  font-size: (26/27) * 1em;
  width: (26/27) * 1em;
}

.svg-health,
.svg-health-active {
  width: (26) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (27/26) * 100%;
  }
}

.icon-squares-plus {
  font-size: (23/23) * 1em;
  width: (23/23) * 1em;
}

.svg-squares-plus {
  width: (23) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (23/23) * 100%;
  }
}

.icon-tariffs {
  font-size: (23/23) * 1em;
  width: (23/23) * 1em;
}

.svg-tariffs {
  width: (26) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (23/23) * 100%;
  }
}

.icon-crown {
  font-size: (32/32) * 1em;
  width: (32/32) * 1em;
}

.svg-crown {
  width: (32) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (32/32) * 100%;
  }
}

.icon-gear {
  font-size: (20/20) * 1em;
  width: (20/20) * 1em;
}

.svg-gear {
  width: (20) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (20/20) * 100%;
  }
}

.icon-plus {
  font-size: (16/16) * 1em;
  width: (16/16) * 1em;
}

.svg-plus {
  width: (16) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (16/16) * 100%;
  }
}

.icon-payment-success {
  font-size: (40/40) * 1em;
  width: (40/40) * 1em;
}

.svg-payment-success {
  width: (40) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (40/40) * 100%;
  }
}

.icon-payment-fail {
  font-size: (40/40) * 1em;
  width: (40/40) * 1em;
}

.svg-payment-fail {
  width: (40) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (40/40) * 100%;
  }
}

.icon-check-round {
  font-size: (40/40) * 1em;
  width: (40/40) * 1em;
}

.svg-check-round {
  width: (40) * 1px;

  &::after {
    content: '';
    display: block;
    padding-bottom: (40/40) * 100%;
  }
}
