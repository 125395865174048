@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.file {
  display: flex;
  align-items: center;
  color: currentColor;
  padding-right: 20px;

  &__title {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    font-size: 16px;
    min-height: 20px;
    line-height: 1.2;
    @include media-breakpoint-down(xs) {
      font-size: 14px;
      min-height: 18px;
    }
  }
  &__detail {
    opacity: 0.6;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.2;
    min-height: 16px;
    @include media-breakpoint-down(xs) {
      font-size: 10px;
      min-height: 14px;
    }
  }
  &__info {
    position: relative;
    flex-grow: 1;
  }
  &__audio-play-btn {
    min-height: 30px;
    min-width: 45px;

    @include media-breakpoint-down(xs) {
      min-width: 35px;
    }
  }
}
