@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.registration-form {
  &__main {
    display: flex;
    align-items: flex-start;
    margin-bottom: 60px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__avatar {
    margin-right: 14.968%;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__fields {
    flex-grow: 1;
    max-width: 440px;

    @include media-breakpoint-down(md) {
      max-width: none;
      width: 100%;
    }
  }
}
