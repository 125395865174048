@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.form {
  margin-top: 40px;

  @include media-breakpoint-down(xs) {
    margin-top: 20px;
  }
}
