@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.chat__documents {
  $marginTop: 10px;

  display: flex;
  flex-wrap: wrap;
  margin-top: -$marginTop;
  margin-bottom: 20px;

  @include media-breakpoint-down(lg) {
    padding-left: 15px;
  }

  &-item {
    $size: 80px;

    position: relative;
    width: $size;
    height: $size;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
    -moz-box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
    box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
    margin-top: $marginTop;

    &:not(:last-child) {
      margin-right: 22px;
    }
  }
  &-image {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  &-file {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: $color-white;
    background: $color-primary;
  }
}
