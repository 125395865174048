@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &__bottom {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    font-size: 14px;
    color: $color-darkgray-200;
    @include media-breakpoint-up(xxs) {
      font-size: 16px;
      margin-top: 30px;
    }
    @include media-breakpoint-up(sm) {
      display: block;
      text-align: center;
      margin-top: 36px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 40px;
    }
  }

  &__bottom-text {
    white-space: pre-line;
  }

  &__bottom-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background-color: $color-gainsboro-100;
    border-radius: 50%;
    color: $color-white-100;
    @include media-breakpoint-up(xxs) {
      width: 18px;
      height: 18px;
    }

    @include media-breakpoint-up(sm) {
      display: none;
    }

    .svg-info {
      width: 2px;
    }
  }
}
