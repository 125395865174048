@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.my-cards {
  &__item {
    $padding: 0;

    padding: $padding;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-part {
      $paddingX: 20px;

      padding-left: $paddingX;
      padding-right: $paddingX;

      @include media-breakpoint-up(xs) {
        $paddingX: 30px;

        padding-left: $paddingX;
        padding-right: $paddingX;
      }

      @include media-breakpoint-up(xl) {
        $paddingX: 120px;

        padding-left: $paddingX;
        padding-right: $paddingX;
      }
    }
    &-card {
      $paddingY: 30px;

      padding-top: $paddingY;
      padding-bottom: $paddingY;

      @include media-breakpoint-down(xs) {
        $paddingY: 20px;

        padding-top: $paddingY;
        padding-bottom: $paddingY;
      }
    }
    &-info {
      border-top: 1px solid;
      border-top-color: $color-gainsboro-100;
      padding-top: 40px;
      padding-bottom: 48px;

      @include media-breakpoint-down(xs) {
        padding-top: 20px;
        padding-bottom: 30px;
      }
    }
  }
  &__columns {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }
  }
  &__column-left {
    flex-shrink: 0;
    width: 35.56%;
    max-width: 335px;
    padding-right: 15px;

    @include media-breakpoint-down(lg) {
      width: 45%;
    }
    @include media-breakpoint-down(md) {
      width: 40%;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
      max-width: none;
      padding-right: 0;
    }
  }
  &__column-right {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 55.41%;
    max-width: 522px;

    @include media-breakpoint-down(lg) {
      width: 50%;
    }
  }
  &__toggle {
    $size: 18px;
    $borderSize: 2px;

    flex-shrink: 0;
    width: $size;
    height: $size;
    cursor: pointer;
    border-style: solid;
    border-width: 0 0 $borderSize $borderSize;
    border-color: $color-primary;
    margin-top: -14px;
    margin-right: 4px;
    margin-left: 15px;
    @include transition(transform, margin-top);
    transform: rotate(-45deg);

    @include media-breakpoint-down(xs) {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -21px;
    }

    &.toggler-checked {
      margin-top: 7px;
      transform: rotate(135deg);
    }

    &-wrapper {
      &:not(.toggle-active) {
        display: none;
      }

      height: 0;
      overflow: hidden;
      @include transition(height);
    }
  }
  &__label {
    width: 100%;
  }
  &__users-button {
    $paddingX: 5px;

    max-width: 200px;
    padding-right: $paddingX;
    padding-left: $paddingX;

    @include media-breakpoint-down(xs) {
      max-width: none;
    }
  }
  &__info {
    align-items: center;
    flex-wrap: nowrap;

    @include media-breakpoint-down(xs) {
      position: relative;
      width: 100%;
      padding-top: 20px;
    }

    &-list {
      display: flex;
      flex-direction: column;
    }
  }
  &__limit {
    align-self: flex-start;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-crimson-100;
    background-color: $color-lavender-blush-100;
    padding: 5px 15px;
    margin-bottom: 10px;

    @include media-breakpoint-down(xs) {
      $paddingX: 10px;

      font-size: 12px;
      padding-right: $paddingX;
      padding-left: $paddingX;
    }
  }
}

.ReactCollapse--collapse {
  transition: height 0.3s;
}
