@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.ecg-modal {
  padding: 0 20px;

  .result {
    &__graph {
      width: 100%;
      height: 210px;

      margin-bottom: 21px;
    }

    &__data {
      margin-bottom: 10px;

      &:last-child {
        .result__data-value {
          color: $color-text;
        }
      }

      &-label {
        margin-bottom: 8px;

        font-size: 14px;
        color: $color-text;
      }

      &-value {
        font-weight: 500;
        font-size: 14px;
        color: $color-primary;

        padding-bottom: 8px;
        border-bottom: 1px solid $color-gainsboro-100;
      }
    }
  }

  .result-table {
    &__item {
      margin-top: 30px;

      &-title {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        color: $color-primary;
      }

      .item-row {
        display: flex;
        justify-content: space-between;

        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-gainsboro-100;

        &__label {
          font-size: 14px;
          color: $color-text;
        }

        &__value {
          font-weight: 500;
          font-size: 14px;
          color: $color-text;
        }
      }
    }
  }
}

.ecg-diseas {
  margin-top: 20px;
  width: 100%;
  padding: 20px 40px;

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
  }

  &__title {
    font-weight: 500;
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-primary;

    margin-bottom: 16px;
  }

  &__text {
    font-size: $font-size;
    line-height: 19px;

    color: $color-text;
  }
}
