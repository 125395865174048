/* вырезает единицы измерения */
@function remove-unit($value) {
  @return $value / ($value * 0 + 1);
}

/* переводит px в rem */
@function rem($size) {
  @return remove-unit($size) / remove-unit($root-font-size) * 1rem;
}

/* устанавливает line-height из px */
@function line-height($line-height, $size: false) {
  @if ($size) {
    $result: remove-unit($line-height) / remove-unit($size);
    @return $result;
  }
  @return remove-unit($size) / remove-unit($root-font-size) * 1rem;
}

@mixin font($size: 1rem, $weight: 400, $line-height: 1.5) {
  font: {
    size: $size;
    weight: $weight;
  }
  line-height: $line-height;
}

@mixin trbl($arguments...) {
  @if (length($arguments) == 1) {
    top: $arguments;
    right: $arguments;
    bottom: $arguments;
    left: $arguments;
  }
}

@mixin rhythm($size, $line-height: false) {
  font-size: remove-unit($size) * 1px; //fallback
  font-size: rem($size);
  @if ($line-height) {
    line-height: line-height($line-height, $size);
  }
}

@mixin line-clamp($size) {
  display: -webkit-box;
  -webkit-line-clamp: $size;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin glitchText($offset, $text, $color, $backgroundColor) {
  content: $text;
  text-transform: inherit;
  position: absolute;
  left: $offset;
  top: $offset;
  clip: rect(0, 900px, 0, 0);
  background-color: $backgroundColor;
  color: $color;
  text-shadow: -3px 0 $color-primary;
  animation: glitch 3s infinite alternate-reverse;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin white-sheet {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: $color-white;
  box-shadow: 0 2px 10px rgba($color-black, 0.3);
}

@mixin placeholderStyles {
  opacity: 0.5;
  font-size: inherit;
  line-height: inherit;
  color: currentColor;
}

@mixin media-breakpoint-down($breakpoint, $breakpoints: $media-sizes) {
  @if index(map-keys($breakpoints), $breakpoint) {
    $normalizedBreakpoint: map-get($breakpoints, $breakpoint);
    @media (max-width: $normalizedBreakpoint) {
      @content;
    }
  } @else {
    @error "Значение #{$breakpoint} должно находиться в массиве!";
  }
}

@mixin media-breakpoint-up($breakpoint, $breakpoints: $media-sizes) {
  @if index(map-keys($breakpoints), $breakpoint) {
    $normalizedBreakpoint: map-get($breakpoints, $breakpoint);
    @media (min-width: $normalizedBreakpoint + 1) {
      @content;
    }
  } @else {
    @error "Значение #{$breakpoint} должно находиться в массиве!";
  }
}

@mixin media-breakpoint-between(
  $breakpointStart,
  $breakpointEnd,
  $breakpoints: $media-sizes
) {
  @if not(index(map-keys($breakpoints), $breakpointEnd)) {
    @error "Значение #{$breakpointStart} должно находиться в массиве!";
  } @else if not(index(map-keys($breakpoints), $breakpointEnd)) {
    @error "Значение #{$breakpointEnd} должно находиться в массиве!";
  } @else {
    $normalizedBreakpointStart: map-get($breakpoints, $breakpointStart);
    $normalizedBreakpointEnd: map-get($breakpoints, $breakpointEnd);

    @if ($normalizedBreakpointStart >= $normalizedBreakpointEnd) {
      @error "Стартовая точка ограничения должна быть меньше конечной!";
    } @else {
      @media (min-width: $normalizedBreakpointStart + 1) and (max-width: $normalizedBreakpointEnd) {
        @content;
      }
    }
  }
}

@mixin transition($transitions...) {
  $transition_result: ();
  @each $transition in $transitions {
    $transition_result: append(
      $transition_result,
      $transition $transition-main,
      comma
    );
  }
  transition: $transition_result;
}

@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name)) + 1;
  } @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}

@mixin aspect-ratio($width: 16, $height: 9) {
  position: relative;

  &::before {
    content: '';
    padding-bottom: ($height / $width) * 100%;
    display: block;
    @content;
  }
}

@mixin hover() {
  @media (hover: hover) {
    @content;
  }
}

@mixin last-child($direction: bottom, $value: 0) {
  &:last-child {
    margin-#{$direction}: #{$value};
    @content;
  }
}

@mixin squareSize($size) {
  width: $size;
  height: $size;
}

@mixin reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: left;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
