@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.data-mob {
  &__item {
    border-bottom: 1px solid $color-gainsboro-100;
    padding-top: 10px;
    padding-bottom: 8px;
    font-size: 14px;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__label {
    color: $color-text;
  }

  &__value {
    margin-top: 6px;
    color: $color-text;
    font-weight: 500;
    line-height: inherit;
  }
  &__value.status {
    font-size: 14px;
  }
}
