@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.favorite {
  $boxShadow: 0 1px 10px;
  $boxShadowColor: $color-black-200;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: $color-white;
  color: $color-darkgray-100;
  box-shadow: $boxShadow rgba($boxShadowColor, 0.25);
  @include transition(color, box-shadow);

  &:hover {
    box-shadow: $boxShadow rgba($boxShadowColor, 0.5);
  }

  &.favorite--is-active {
    color: $color-crimson-100;
  }
}
