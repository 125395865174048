@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.info {
  &__group {
    &-label {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      color: $color-primary;
      margin-bottom: 20px;

      @include media-breakpoint-up(xs) {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 30px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @include media-breakpoint-up(xs) {
        margin-bottom: 50px;
      }
    }
  }
  &__item {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    @include media-breakpoint-up(xs) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__label {
    font-weight: 500;
    color: $color-primary;
    white-space: nowrap;
  }

  &__label {
    &:not(&-empty):after {
      content: ':';
      display: inline-block;
      margin-right: 10px;
    }
  }
  &__value {
    &-undefined {
      opacity: 0.5;
    }
    &_unset {
      color: $color-darkgray-200;
    }
  }
}
