.input-date {
  .react-calendar__month-view__weekdays {
    color: #777ff9;
    border-bottom: 1px solid #dcdcdc;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view .react-calendar__tile--active {
    background: none;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.6em;
      height: 1.6em;
      z-index: 0;
      transform: translate(-0.8em, -0.8em);
      border-radius: 50%;
      background-color: #777ff9;
    }
  }

  .react-calendar__tile--now {
    background: none;
  }

  .react-calendar__month-view .react-calendar__tile--active > * {
    position: relative;
    z-index: 1;
  }

  .react-calendar__navigation {
    justify-content: center;
  }
  .react-calendar__navigation__label__labelText {
    color: #777ff9;
    font-weight: 600;
  }

  .react-calendar__tile {
    color: #777ff9;
    font-weight: 600;
    transition: 0.3s;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    color: $color-white-100;
    background-color: $color-primary;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background-color: #caceff;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    &:not(.react-calendar__tile--hasActive) {
      background-color: $color-ghostwhite-100;
    }
  }

  .react-calendar__navigation .react-calendar__navigation__label {
    flex-grow: 0 !important;
    min-width: 140px;
    @include media-breakpoint-up(xs) {
      min-width: 160px;
    }
  }

  .react-calendar__navigation__arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__navigation__arrow:after {
    $size: 10px;
    $borderSize: 2px;

    content: '';
    display: block;
    width: $size;
    height: $size;
    border-style: solid;
    border-width: 0 $borderSize $borderSize 0;
    border-color: $color-primary;
    transition: $transition-main;
  }

  .react-calendar__navigation__prev-button {
    padding-left: 5px;
  }
  .react-calendar__navigation__prev-button:after {
    transform: rotate(135deg);
  }

  .react-calendar__navigation__next-button {
    padding-right: 5px;
  }
  .react-calendar__navigation__next-button:after {
    transform: rotate(-45deg);
  }
}

.input-date .react-date-picker {
  width: 100%;
  &__wrapper {
    border: none;
  }

  &__calendar {
    z-index: 20;
  }
  &__inputGroup {
    font-size: 18px;
    line-height: 21px;
    color: $color-black;
    padding: 10px 0;

    @include placeholder() {
      @include placeholderStyles();
    }

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__button .react-date-picker__button__icon {
    fill: $color-gray-100;
    stroke: $color-gray-100;
    transition: all 0.3s;
  }

  &__button:enabled:hover .react-date-picker__button__icon,
  &__button:enabled:focus .react-date-picker__button__icon {
    fill: $color-black;
    stroke: $color-black;
  }

  .react-calendar {
    @include white-sheet;
    border-width: 0;
    padding: 10px;
    max-width: 80vw;
    @include media-breakpoint-up(xs) {
      padding: 20px;
    }
  }
}
