@import './../variables.scss';
@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.promocode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__collapse {
    width: 100%;
  }

  &__btn {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    color: #A1A1A1;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  &__btn:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-style: solid;
    border-width: 0 0 1px 1px;
    border-color: currentColor;
    transform: rotate(-45deg);
    transition: transform 0.3s cubic-bezier(0.16, 0.16, 0.4, 0.82),
    margin-top 0.3s cubic-bezier(0.16, 0.16, 0.4, 0.82);
  }

  &__btn.opened:after {
    transform: rotate(135deg);
    margin-top: 5px;
  }

  &__input {
    padding-top: 10px;
    margin-bottom: 0;
    width: 100%;
  }
}
