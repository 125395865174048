@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.datetime-picker {
  &__title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    margin-bottom: 18px;
    font-size: 14px;
  }

  &__calendar-block {
    margin-bottom: 30px;
  }
  &__btn-block {
    text-align: center;
  }
  &__confirm-btn {
    width: 250px;
  }
  .react-calendar__navigation button {
    border-radius: 6px;
    transition: 0.3s;
  }
  .react-calendar__navigation button[disabled] {
    background-color: transparent;
    opacity: 0.5;
  }
  .react-calendar {
    border: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .react-calendar__tile {
    border-radius: 6px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  .react-calendar__tile:disabled {
    background-color: transparent;
    color: $color-gray-100;
    opacity: 0.6;
  }

  .react-calendar__month-view .react-calendar__tile--active,
  .react-calendar__month-view .react-calendar__tile--active:enabled:hover,
  .react-calendar__month-view .react-calendar__tile--active:enabled:focus {
    background-color: $color-primary;
  }
  .react-calendar__month-view .react-calendar__tile--active::after {
    display: none;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 10px;
  }
  &__times-block {
    width: 350px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 20px;
    min-height: 210px;
    text-align: center;
  }
  &__times {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__time {
    width: 33.33%;
    text-align: center;
  }
  &__time-btn {
    padding: 10px 12px;
    margin-bottom: 4px;
    border-radius: 6px;
    transition: 0.3s;
    color: $color-primary;
  }
  &__time-btn:hover {
    cursor: pointer;
    background-color: $color-ghostwhite-100;
  }
  &__time-btn--active,
  &__time-btn--active:hover {
    background-color: $color-primary;
    color: #fff;
  }
  &__info {
    @include placeholderStyles();
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 20px;
  }
}
.modal__popup.modal__popup--datetime-picker {
  max-width: 430px;
  padding: 40px 10px;
}
.modal__content.modal__content--dateime-picker {
  position: static;
}
