@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.title {
  margin-bottom: 16px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 12px;
  }
}

.description {
  font-size: 18px;
  margin-bottom: 16px;

  &__bold {
    font-weight: 500;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
}
.steps {
  font-size: 18px;
  font-weight: 500;
  color: $color-primary;
  margin-left: auto;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}
.footnote {
  font-size: 12px;
  color: $color-darkgray-200;
  margin-bottom: 32px;

  &__link {
    $color: $color-primary;

    font-weight: 600;
    color: $color;
    text-decoration: none;
    @include transition(color);

    &:hover {
      color: darken($color, 15);
    }
  }
}
