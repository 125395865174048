@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.footer {
  height: 220px;
  left: 0px;
  top: 1263px;
  padding-top: 40px;
  background: linear-gradient(90.97deg, #8da0ff 0.19%, #b982ff 98.52%);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 30px;

  &__links {
    padding-left: 40px;
  }
  &__footnote-full {
    padding-left: 40px;
    padding-right: 39px;
    height: 79px;
    left: 40px;
    top: 1378px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: #ffffff;

    mix-blend-mode: normal;
    opacity: 0.6;
  }
}
