@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.ecg-result {
  width: 100%;
  padding: 20px 40px;

  @include media-breakpoint-down(lg) {
    padding: 20px;
  }

  &__graph {
    width: 100%;
    height: 200px;

    margin-bottom: 35px;
  }

  table {
    width: 100%;

    font-size: $font-size;

    color: $color-text;

    tr {
      &:first-child {
        border-bottom: 1px solid $color-gainsboro-100;

        td:last-child {
          color: $color-primary;
        }
      }

      td {
        padding: 10px 0;

        &:last-child {
          font-weight: 500;
          text-align: right;
        }
      }
    }
  }
}

.ecg-table {
  margin-top: 20px;
  width: 100%;
  height: 184px;
  padding: 20px 40px;

  @include media-breakpoint-down(lg) {
    padding: 20px;
  }

  table {
    width: 100%;
    height: 100%;
    tr {
      border-bottom: 1px solid $color-gainsboro-100;
      font-size: $font-size;
      color: $color-text;
      text-align: center;

      &:first-child {
        font-weight: bold;
        color: $color-primary;
      }

      &:last-child {
        border-color: transparent;
      }

      td {
        border-right: 1px solid $color-gainsboro-100;

        &:first-child {
          font-weight: bold;
          color: $color-primary;
          text-align: left;
        }

        &:last-child {
          border-color: transparent;
        }
      }
    }
  }
}

.ecg-diseas {
  margin-top: 20px;
  width: 100%;
  padding: 20px 40px;

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
  }

  &__title {
    font-weight: 500;
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-primary;

    margin-bottom: 16px;
  }

  &__text {
    font-size: $font-size;
    line-height: 19px;

    color: $color-text;
  }
}
