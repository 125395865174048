// prettier-ignore
html, body, div, span, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, b, u, i,
dl, dt, dd, ol, ul, li,
form, label,
table, tbody, tfoot, thead, tr, th, td,
input, textarea, button, select,
figure {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  // outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

ul,
ol {
  list-style-type: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-ms-clear {
  display: none;
}

input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input,
select,
textarea,
a {
  outline: none;
  border: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}

select,
textarea,
input {
  width: 100%;
}

html {
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
}

body {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}
