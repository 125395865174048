@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.support {
  margin-bottom: 60px;

  .titleline__title {
    @include media-breakpoint-down(xs) {
      padding-right: 0;
      margin-bottom: 0;
    }
  }

  &__form-block {
    margin-bottom: 60px;
  }
  &__list-wrapper {
    padding-top: 64px;
    position: relative;
  }
}

.support-form-block {
  @include white-sheet;
  display: flex;
  padding: 50px 160px 50px 120px;

  &__description {
    width: 280px;
    margin-right: 14.968%;
  }

  &__content {
    flex-grow: 1;
    max-width: 440px;
  }

  @include media-breakpoint-down(lg) {
    $paddingX: 5.085%;

    padding-right: $paddingX;
    padding-left: $paddingX;

    &__description {
      margin-right: 30px;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;

    &__description {
      margin-right: 0;
      margin-bottom: 30px;
    }

    &__content {
      max-width: none;
      width: 100%;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 20px;
  }
}

.svg-info-with-circle {
  width: 24px;
  height: 24px;
}

.support-description {
  &__image {
    text-align: center;
    margin-bottom: 40px;

    @include media-breakpoint-down(xs) {
      max-width: 108px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__text {
    text-align: center;
    color: rgba($color-text, 0.5);

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
