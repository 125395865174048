@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.field-array {
  margin-bottom: 30px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 24px;
  }

  &__list {
    margin-bottom: 30px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 24px;
    }
  }

  &__item {
    position: relative;
  }

  &__delete-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-white-100;

    &::before,
    &::after {
      height: 24px;
      background-color: $color-crimson-100;
    }

    &:hover::before,
    &:hover::after {
      background-color: $color-lightpink-100;
    }
  }

  &__alert {
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding: 40px;
    }
  }

  &__alert-btn-wrapper {
    @include media-breakpoint-up(xs) {
      flex: 1;
    }
  }

  &__alert-btn {
    @include media-breakpoint-up(xs) {
      width: 100%;
    }
  }
}
