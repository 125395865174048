@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.swiper-pagination {
  display: flex;
}
.swiper-pagination-bullet {
  $size: 8px;

  display: block;
  width: $size;
  height: $size;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba($color-primary, 0.5);
  @include transition(background-color);

  &:not(:last-child) {
    margin-right: 10px;
  }

  &-active {
    background-color: rgba($color-primary, 1);
  }
}
.navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}
.navigation__el {
  $size: 20px;
  $borderWidth: 3px;

  position: absolute;
  z-index: 20;
  pointer-events: auto;
  opacity: 1;
  width: $size;
  height: $size;
  border-color: currentColor;
  border-style: solid;
  border-width: 0 0 $borderWidth $borderWidth;
  border-radius: 2px;
  cursor: pointer;
  color: $color-primary;
  transform-origin: center;
  @include transition(opacity);
}

.navigation__prev {
  transform: rotate(45deg);
}
.navigation__next {
  transform: rotate(-135deg);
}
.navigation__disabled {
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
