@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

$maxWidthBlock: 348px;

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 448px;
  width: 100%;
  text-align: center;
  color: $color-black-200;
  margin: auto;
}
.icon {
  $size: 40px;

  width: $size;
  height: $size;
  margin-bottom: 20px;
}
.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}
.description {
  font-size: 20px;
  line-height: 145%;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}
.info {
  max-width: $maxWidthBlock;
  width: 100%;
  border: 2px solid $color-lavender-400;
  border-radius: 10px;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  background-color: $color-palesteelblue-100;
  padding: 10px 20px;
  margin-bottom: 30px;
}
.button {
  max-width: $maxWidthBlock;
  width: 100%;
}
.icon-success {
  color: $color-mediumslateblue-300;
}
.icon-fail {
  color: $color-lightpink-100;
}
