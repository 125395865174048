@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.card {
  position: relative;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba($color-primary, 0);
  overflow: hidden;
  @include transition(box-shadow, border-color);

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: (40 / 59) * 100%;
  }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__logo {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    @supports (object-fit: cover) {
      object-fit: cover;
    }
  }
}
