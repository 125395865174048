@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.pick-avatar {
  &__icon {
    $size: 42px;

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
    -moz-box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
    box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
    background: $color-white;
    color: $color-primary;
    cursor: pointer;
    @include transition(color);
  }
}
