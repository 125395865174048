@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.policy {
  text-align: center;
  font-size: 14px;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.text {
  display: block;
  color: #979797;
}

.link {
  font-weight: 500;
  color: #777FF9;
  text-decoration: none;
}
