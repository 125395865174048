@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

$unreadMessagesIndicatorSize: 20px;
$cardPaddingXTablet: 20px;
$cardPaddingXDesktop: 40px;

.consultations-list {
  .list-card {
    display: block;
    text-decoration: none;
    position: relative;
    font-size: 18px;
    // line-height: 21px;
    padding: 10px 40px 20px;

    @include white-sheet;
  }

  .list-card__row {
    width: 100%;
  }

  .list-card__row--half {
    width: 50%;
  }

  .list-card__row--status {
    margin-bottom: 16px;
    .status {
      line-height: inherit;
    }
  }

  .list-card__row--name {
    font-weight: 500;
    margin-bottom: 10px;
    color: $color-black;
  }

  .list-card__row--description {
    margin-bottom: 20px;
    color: $color-black;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .list-card__row--id,
  .list-card__row--created {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $color-lightslategray-100;
  }

  .list-card__row--created {
    justify-content: flex-end;
  }

  .list-card__unread-messages {
    $size: $unreadMessagesIndicatorSize;
    line-height: 1;

    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    // line-height: $size;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    color: $color-white;
    background-color: $color-crimson-100;
    margin-left: 50px;

    @include media-breakpoint-down(lg) {
      margin-left: 26.6%;
    }
  }

  @supports (display: grid) {
    .list-card {
      height: 100%;
    }

    .list-card__inner {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto 1fr auto;
    }

    .list-card__row--status,
    .list-card__row--name,
    .list-card__row--description {
      grid-column: 1 / span 2;
    }

    .list-card__row--half {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    .list-card {
      padding: 10px 20px;
    }
  }
  @include media-breakpoint-down(xs) {
    .list-card {
      font-size: 14px;
    }

    .list-card__row--status,
    .list-card__row--name {
      margin-bottom: 6px;
    }

    .list-card__row--description {
      margin-bottom: 10px;
    }
  }
}

.consultations-list[data-is-list='true'] {
  $unreadMessagesIndicatorRight: 50px;

  @include media-breakpoint-up(lg) {
    $cardPaddingX: $cardPaddingXTablet;

    .list-cards__item {
      max-width: 100%;
      flex: 0 0 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .list-card {
      padding: 20px $cardPaddingX;
    }

    .list-card__inner {
      display: flex;
      flex-wrap: wrap;
    }

    .list-card__row {
      margin-bottom: 0;
    }

    .list-card__row--id {
      order: 1;
      width: 10%;
    }
    .list-card__row--status {
      order: 2;
      width: 15%;
      padding-left: 20px;
    }
    .list-card__row--created {
      order: 3;
      width: 15%;
      text-align: left;
    }
    .list-card__row--name {
      order: 4;
      font-weight: 400;
      width: 20%;
      padding-right: 20px;

      span {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
      }
    }
    .list-card__row--description {
      order: 5;
      width: 40%;
      padding-right: $unreadMessagesIndicatorRight +
        $unreadMessagesIndicatorSize - $cardPaddingXTablet + 5;
    }

    .list-card__row--created {
      display: block;
    }
    .list-card__row--id {
      display: flex;
      flex-direction: column;
    }
  }

  @include media-breakpoint-up(xl) {
    $cardPaddingX: $cardPaddingXDesktop;
    .list-card {
      padding: 20px $cardPaddingX 30px;
    }
    .list-card__row--description {
      padding-right: $unreadMessagesIndicatorRight +
        $unreadMessagesIndicatorSize - $cardPaddingXDesktop + 5;
    }
  }
  @include media-breakpoint-up(lg) {
    .list-card__unread-messages {
      margin: 8px auto 0;
      // position: absolute;
      // top: 6px;
      // right: $unreadMessagesIndicatorRight;
      // z-index: 10;
      // margin: 0;
    }
  }
}

.consultations-list[data-is-list='false'] {
  $listMarginX: 20px;

  .list-cards__item {
    padding-right: $listMarginX;
    padding-left: $listMarginX;
  }

  .list-cards__inner {
    margin-right: -$listMarginX;
    margin-left: -$listMarginX;
  }

  .list-card__row--id,
  .list-card__row--created {
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-down(lg) {
    $listMarginX: 15px;

    .list-cards__item {
      padding-right: $listMarginX;
      padding-left: $listMarginX;
    }

    .list-cards__inner {
      margin-right: -$listMarginX;
      margin-left: -$listMarginX;
    }
  }
}

.consultations-list__header {
  @include media-breakpoint-up(lg) {
    $cardPaddingX: $cardPaddingXTablet;

    padding-left: $cardPaddingX;
    padding-right: $cardPaddingX;
    margin-bottom: 10px;
  }
  @include media-breakpoint-up(xl) {
    $cardPaddingX: $cardPaddingXDesktop;
    padding-left: $cardPaddingX;
    padding-right: $cardPaddingX;
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }

  .labels-header__item--id {
    width: 10%;
  }
  .labels-header__item--status {
    width: 15%;
    padding-left: 20px;
  }
  .labels-header__item--created {
    width: 15%;
  }
  .labels-header__item--name {
    width: 20%;
  }
  .labels-header__item--description {
    width: 40%;
  }
}
