@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

:root {
  --fc-today-bg-color: #fff;
}
.med-calendar {
  padding-left: 10px;
  padding-right: 10px;
  color: $color-primary;

  @include media-breakpoint-up(xxs) {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 40px;
    padding-top: 0;
  }
  @include media-breakpoint-up(xl) {
    max-width: 660px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
  }
  .fc-toolbar-chunk:nth-child(2) {
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }

  .fc .fc-toolbar-title {
    display: inline-block;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    min-width: 140px;

    @include media-breakpoint-up(xs) {
      font-size: 16px;
      min-width: 170px;
    }
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }
  .fc-button.fc-button-primary {
    background-color: #fff;
    outline: 0;
    color: $color-primary;
    border: 0;

    &:focus,
    &:active {
      background-color: #fff;
      box-shadow: none !important;
    }

    &::before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      cursor: pointer;
      border-style: solid;
      border-width: 0 0 2px 2px;
      border-color: #777ff9;
    }

    &.fc-prev-button {
      &::before {
        transform: rotate(45deg);
      }
    }
    &.fc-next-button {
      &::before {
        transform: rotate(-135deg);
      }
    }
    .fc-icon {
      display: none;
    }
  }

  .fc .fc-day-other {
    color: $color-black-100;
    font-weight: 500;
  }
  .fc-view-harness {
    font-weight: 700;
    font-size: 12px;
    @include media-breakpoint-up(xxs) {
      font-size: 14px;
    }
    @include media-breakpoint-up(xs) {
      font-size: 18px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 20px;
    }
  }
  .fc .fc-daygrid-day-top {
    padding-top: 4px;
    display: block;
    text-align: center;
    @include media-breakpoint-up(xxs) {
      margin-bottom: 4px;
    }
  }
  .fc .fc-event-title {
    position: absolute;
    z-index: 1;
    padding: 4px 6px;
    bottom: calc(100% + 5px);
    background-color: #fff;
    border-radius: 4px;
    left: 50%;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    color: $color-black-100;
    font-weight: 400;
    border: 1px solid $color-gainsboro-100;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    overflow: visible;
    transform: translate(-50%, 5px);

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 10px;
      height: 10px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      border-right: 1px solid $color-gainsboro-100;
      border-bottom: 1px solid $color-gainsboro-100;
      background-color: #fff;
    }
  }

  .fc-event:hover .fc-event-title {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }

  .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .fc-daygrid-dot-event:hover {
    background-color: transparent;
  }
  .fc .fc-daygrid-day-bottom {
    display: none;
  }
  .fc .fc-daygrid-day-frame {
    cursor: pointer;
    transition: all 0.3s;
    padding-bottom: 4px;
    min-height: 11vw;
    height: 100%;

    &:hover {
      background-color: $color-ghostwhite-100;
    }

    @include media-breakpoint-up(md) {
      min-height: 9vw;
    }
    @include media-breakpoint-up(lg) {
      min-height: 70px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 80px;
    }
  }
  .fc-daygrid-event-dot {
    border-color: currentColor;
    margin: 1px;
    border-width: 5px;
    border-radius: 50%;
    @include media-breakpoint-down(xs) {
      border-width: 3px;
    }
    @include media-breakpoint-down(xxs) {
      border-width: 2px;
      margin: 0;
    }
  }
  .fc-daygrid-day-number {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    align-items: center;
    justify-content: center;
    position: relative;
    line-height: 1;
    border-radius: 50%;
    z-index: 1;

    @include media-breakpoint-up(xxs) {
      width: 32px;
      height: 32px;
    }
    @include media-breakpoint-up(xs) {
      width: 36px;
      height: 36px;
    }
    @include media-breakpoint-up(xl) {
      width: 40px;
      height: 40px;
    }
  }

  .fc-day-today .fc-daygrid-day-number {
    color: #fff;
    background-color: $color-primary;
  }
  .fc .fc-daygrid-event-harness-abs {
    position: relative;
    visibility: visible !important;
  }
  .fc .fc-daygrid-day-events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    margin: 0;
  }

  .fc-daygrid-day-frame:before,
  .fc-daygrid-day-events:before,
  .fc-daygrid-event-harness:before,
  .fc-daygrid-day-frame:after,
  .fc-daygrid-day-events:after,
  .fc-daygrid-event-harness:after {
    display: none;
  }
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: auto;
  }
  .fc-theme-standard .fc-scrollgrid {
    border: 0;
  }
  .fc-theme-standard th {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    text-transform: uppercase;
    @include media-breakpoint-up(xxs) {
      height: 50px;
    }
  }
  .fc-theme-standard td {
    border-left: 1px solid $color-gainsboro-100;
  }
  .fc .fc-highlight {
    background-color: $color-primary-transparent;
  }
}
