@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.input-text {
  font-size: 18px;
  line-height: 21px;
  color: $color-black;
  border-radius: 0;
  padding: 10px 0;

  @include placeholder() {
    @include placeholderStyles();
  }
  @include media-breakpoint-down(xs) {
    font-size: 14px;
    line-height: 16px;
  }
}

.input-password {
  $iconWidth: 30px;
  display: flex;
  position: relative;
  padding-right: $iconWidth;

  &__icon {
    color: var(--color-ui-border);
    transition: color $transition-main;
  }

  &__icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: $iconWidth;
  }
  &__icon-wrapper:hover .input-password__icon {
    color: $color-primary;
  }

  &--active &__icon {
    color: $color-primary;
  }
}
