@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.plug {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  padding-bottom: 30px;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-down(lg) {
    flex-grow: 1;
  }

  &__image {
    display: inline-block;
    margin-bottom: 30px;
  }

  &__text {
    max-width: 620px;
    display: inline;
    font-size: 20px;
    font-family: $font-secondary;
    font-weight: 600;
    line-height: 27px;
    text-align: center;

    @include media-breakpoint-down(xs) {
      max-width: 100%;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
