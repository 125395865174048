@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.titleline.titleline--profile-edit {
  justify-content: space-between;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    align-items: unset;

    .titleline__button {
      margin-left: 0;
    }

    .breadcrumbs {
      margin-bottom: 30px;
    }
  }
}
