@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.cards-tabs {
  &__tab {
    width: 200px;

    &-list {
      margin-bottom: 40px !important;

      @include media-breakpoint-down(xs) {
        margin-bottom: 30px;
      }
    }
  }
}
