@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.input-phone {
  display: flex;
  width: 100%;

  &__select {
    @include reset-button;
    position: relative;
    flex: 1 0 92px;
    padding-right: 15px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-basis: 80px;
    }
  }

  &__select,
  &__input {
    border-bottom: 1px solid;
    transition: border-bottom-color $transition-main;
    border-bottom-color: $color-gainsboro-100;
  }

  &__input {
    padding: 10px 0;
  }

  &--has-error &__select,
  &--has-error &__input {
    border-bottom-color: $color-error;
  }

  &:not(.input-phone--has-error):hover .input-phone__select,
  &:not(.input-phone--has-error):hover .input-phone__input {
    border-bottom-color: $color-primary;
  }

  &__select-arrow {
    position: absolute;
    right: 0;
  }

  &__select-arrow:after {
    $size: 10px;
    $borderSize: 2px;

    content: '';
    display: block;
    width: $size;
    height: $size;
    border-style: solid;
    border-width: 0 $borderSize $borderSize 0;
    border-color: $color-primary;
    transform: rotate(45deg);
    transition: $transition-main;
  }

  &--is-modal-open &__select-arrow:after {
    transform: rotate(225deg);
  }
}

.input-phone-modal {
  .modal__footer .button {
    min-width: 200px;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  .modal__popup {
    max-height: 800px;
    height: 100%;
  }

  .modal__content {
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
  }

  &__search-bar {
    padding-right: (30px - $scrollbar-width);

    .field__input {
      display: flex;
    }

    .search-icon {
      margin-right: 20px;
      color: $color-gray-100;
    }
  }

  &__list {
    padding-right: 20px;
  }

  &__item {
    padding: 15px 10px;
    position: relative;
    transition: background-color 0.3s;

    &:hover {
      background-color: $color-palesteelblue-100;
    }
  }

  &__item:after {
    $borderSize: 2px;
    display: none;
    content: '';
    position: absolute;
    top: calc(50% - 14px);
    right: 4px;
    z-index: 10;
    width: 12px;
    height: 17px;
    border-style: solid;
    border-width: 0 $borderSize $borderSize 0;
    border-color: $color-primary;
    transform: rotate(45deg);
  }

  &__item[data-is-selected='true']:after {
    display: block;
  }

  &__item:not(:last-child) {
    border-bottom: 1px solid $color-gainsboro-100;
  }
}

.country-code {
  display: flex;
  cursor: pointer;

  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }

  &__country {
    margin-right: 20px;
  }

  &__code {
    font-weight: 700;
    color: $color-primary;
  }
}
