@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

// Block start

.block__content {
  @include media-breakpoint-up(xs) {
    padding-top: 40px;
    padding-bottom: 183px;
  }

  @include media-breakpoint-up(lg) {
    $paddingX: 80px;

    padding-right: $paddingX;
    padding-left: $paddingX;
  }

  @include media-breakpoint-up(xl) {
    $paddingX: 160px;

    padding-right: $paddingX;
    padding-left: $paddingX;

    & .block__column-right {
      max-width: 478px;
    }
  }
}

// Block end

// Personal block start

.personal {
  &__logo {
    display: block;
    width: 121px;
    margin: 0 auto 40px;
    @include transition(width, margin-bottom);

    @include media-breakpoint-down(md) {
      width: 70px;
      margin-bottom: 0;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media-breakpoint-down(md) {
      flex-grow: 1;
      width: 100%;
    }
  }
}

// Personal block end
