@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.tooltip {
  display: flex;
  border-radius: 10px;
  font-size: 14px;
  background-color: $color-palesteelblue-100;
  padding: 8px 12px;
  margin-bottom: 16px;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
    margin-bottom: 16px;
  }

  &__icon {
    $size: 22px;

    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    border-radius: 50%;
    color: $color-white;
    background-color: $color-deepskyblue-100;
    margin-right: 8px;

    &:after {
      content: '!';
      font-weight: 600;
    }
  }
}
