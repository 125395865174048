@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.pagination {
  display: flex;
  align-items: center;

  &__item {
    $fastjumpMarginX: 8px;
    $fastjumpIconMarginX: 2px;
    $fastjumpIconWidth: 2px;

    display: inline-block;
    text-decoration: none;

    &-fastjump {
      $size: 29px;
      $boxShadowParams: 0 1px 10px;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $size;
      height: $size;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: $color-white;
      box-shadow: $boxShadowParams rgba($color-black-200, 0.25);
      @include transition(box-shadow);

      &:hover {
        box-shadow: $boxShadowParams rgba($color-black-200, 0.45);
      }
    }
    &-start {
      margin-right: $fastjumpMarginX;

      & .pagination__item-icon {
        background-image: url('../../../static/img/src_img_static_nav-start.png');
      }
    }
    &-end {
      margin-left: $fastjumpMarginX;

      & .pagination__item-icon {
        background-image: url('../../../static/img/src_img_static_nav-end.png');
      }
    }
    &-icon {
      width: 11px;
      height: 16px;
      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &__link {
    width: 31px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-family: $font-secondary;
    color: $color-darkslategray-100;
    padding: 0 2px;

    &:hover,
    &-active {
      font-weight: 700;
      color: $color-primary;
    }
  }
}
