@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-chart__table {
  $paddingX: 5px;
  padding: 20px;
  overflow-y: auto;
  @include media-breakpoint-up(md) {
    padding: 40px 20px 40px;
  }
  @include media-breakpoint-up(xl) {
    padding: 40px 20px 40px 40px;
  }
  &-inner {
    @include media-breakpoint-up(lg) {
      overflow-y: auto;
      height: 100%;
      padding-right: 20px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: $color-text;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    &:not(:first-child) {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid var(--color-ui-border);
    }
    &._clickable {
      cursor: pointer;
    }
  }

  &-name {
    padding-right: $paddingX;
    width: 25%;
  }

  &-description {
    padding: 0 $paddingX;
    width: 25%;
  }

  &-value {
    padding-left: $paddingX;
    font-weight: bold;
    color: $color-primary;
    width: 35%;
  }
  &-arrow {
    flex-shrink: 0;
    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-right: 1px solid $color-primary;
      border-bottom: 1px solid $color-primary;
      transform: rotate(-45deg);
    }
  }

  &-data:last-child {
    text-align: right;
    min-width: 15%;
  }
  &-arrow:last-child {
    min-width: auto;
  }
}
