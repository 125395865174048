@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-chart__toggler {
  display: flex;
  margin: 0 auto 20px;
  width: 200px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 85px;
    width: 240px;
  }

  &-button {
    $size: 15px;
    $borderSize: 2px;
    flex-shrink: 0;
    width: $size;
    height: $size;
    cursor: pointer;
    border-style: solid;
    border-width: 0 0 $borderSize $borderSize;
    border-color: $color-primary;

    @include media-breakpoint-up(sm) {
      $size: 18px;

      width: $size;
      height: $size;
    }
  }

  &-right {
    transform: rotate(-135deg);
  }

  &-left {
    transform: rotate(45deg);
  }

  &-disabled {
    visibility: hidden;
    pointer-events: none;
  }

  &-text {
    width: 180px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: $color-primary;

    @include media-breakpoint-up(xs) {
      font-size: 16px;
      width: 190px;
    }

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      width: 200px;
    }
  }
}
