@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.avatar {
  $size: 200px;
  position: relative;
  width: $size;
  height: $size;

  @include media-breakpoint-down(xs) {
    $size: 190px;
    width: $size;
    height: $size;
  }

  &__image {
    border-radius: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: inherit;
    height: inherit;
    position: relative;
    background-color: $color-darkgray-100;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 0;
      opacity: 1;
      @include transition(opacity);
    }
  }

  &__image:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -300px;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e8e8e8 50%,
      transparent 100%
    );
    z-index: 2;
    opacity: 0;
    @include transition(opacity);
  }

  &--is-loading &__image:before {
    opacity: 1;
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  &--is-loading &__image img {
    opacity: 0;
  }

  &__edit-image {
    margin-bottom: 50px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }
  }
  &__edit-button {
    text-align: center;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
