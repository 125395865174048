@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.support-24h {
  display: flex;
  align-items: center;
  color: rgba($color-text, 0.5);

  @include media-breakpoint-down(lg) {
    flex-shrink: 0;
  }

  &__text {
    margin-right: 46px;

    @include media-breakpoint-down(xl) {
      margin-right: 40px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-weight: 700;
    text-decoration: none;
    color: $color-black-200;

    @include media-breakpoint-down(md) {
      color: $color-white;
    }

    &:hover .support-24h__icon {
      transform: scale(1.1);
    }
  }

  &__icon {
    color: $color-primary;
    @include transition(transform);
    margin-right: 18px;

    @include media-breakpoint-down(md) {
      color: currentColor;
    }

    @include media-breakpoint-down(xs) {
      margin-right: 0;
    }
  }

  &__tel {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}
