@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';
@import './../../variables';

.item {
  display: flex;
  align-items: center;

  @include userItemMargin();

  @include media-breakpoint-down(xs) {
    &:not(:last-child) {
      border-bottom: 1px solid;
      border-bottom-color: $color-gainsboro-100;
    }
  }
}
.pointer {
  cursor: pointer;
}
.avatar {
  $size: $avatarSize;

  width: $size;
  height: $size;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;

  @include media-breakpoint-down(xs) {
    $size: $avatarSizeMobile;

    width: $size;
    height: $size;
  }
}
