$avatarSize: 48px;
$avatarSizeMobile: 40px;

@mixin userItemMargin {
  &:not(:last-child) {
    margin-right: 12px;

    @include media-breakpoint-down(xs) {
      margin-right: 10px;
    }
  }
}
