@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.diet-markups {
  cursor: auto;

  h1 {
    color: $color-primary;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;

    @include media-breakpoint-down(xs) {
      font-size: 12px;
    }
  }

  h2 {
    margin: 20px 0;

    font-weight: bold;
    color: $color-text;
  }

  ul {
    padding-left: 20px;

    @include media-breakpoint-down(xs) {
      padding-left: 12px;
    }

    li {
      position: relative;
      margin-bottom: 10px;

      color: #212121;

      &::before {
        position: absolute;
        top: 7px;
        left: -20px;
        height: 8px;
        width: 8px;
        background: $color-primary;
        border-radius: 50%;
        content: '';

        @include media-breakpoint-down(xs) {
          height: 6px;
          width: 6px;
          left: -12px;
          top: 5px;
        }
      }
    }
  }
}
