@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.consultation-info {
  @mixin border($side) {
    border-#{$side}: 1px solid $color-gainsboro-100;
  }

  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  padding: 20px 40px;

  @include media-breakpoint-down(lg) {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 16px;
  }

  &__title {
    font-weight: 600;
    line-height: 21px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  &__block {
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      height: auto !important;
    }

    @include media-breakpoint-down(lg) {
      $paddingX: 40px;

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 20;
      height: 0;
      overflow: hidden;
      background: $color-white;
      @include border(top);
      @include transition(height, padding-top);
      transform: translateY(100%);
      padding-top: 20px;
      padding-right: $paddingX;
      padding-left: $paddingX;
      margin-right: 0;

      &:not(.toggle-active) {
        padding-top: 0;
      }
    }
  }
  &__list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      $paddingX: 40px;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 30px;
    }
  }

  &__item {
    font-size: 18px;
    line-height: 21px;
    &_question {
      &-number {
        order: -2;
      }
      &-answer-way {
        order: -1;
      }
    }

    @include media-breakpoint-down(lg) {
      @include border(bottom);
      padding-bottom: 10px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    @include media-breakpoint-between(sm, lg) {
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__chosen {
    $size: 55px;

    flex-shrink: 0;
    width: $size;
    height: $size;

    & .svg {
      width: 31px;
    }

    @include media-breakpoint-down(lg) {
      margin-left: auto;
      margin-right: 24px;
    }

    @include media-breakpoint-down(xs) {
      $size: 30px;

      width: $size;
      height: $size;
      margin-right: 20px;

      & .svg {
        width: 18px;
      }
    }
  }
  &__toggle {
    display: none;

    @include media-breakpoint-down(lg) {
      $size: 16px;
      $borderSize: 2px;

      flex-shrink: 0;
      cursor: pointer;
      margin-left: auto;
      display: block;
      position: absolute;
      right: 30px;
      top: 25px;
      width: $size;
      height: $size;
      border-style: solid;
      border-width: 0 0 $borderSize $borderSize;
      border-color: $color-primary;
      margin-top: -($size / 2);
      @include transition(transform, margin-top);
      transform: rotate(-45deg);

      &.toggler-checked {
        margin-top: ($size / 2);
        transform: rotate(-225deg);
      }
    }
  }
  .titleline__button {
    font-weight: 500;
    margin-bottom: 16px;
    margin-left: 0;
    text-transform: none;
    padding: 10px 28px;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .titleline__button + .titleline__button {
    @include media-breakpoint-up(xs) {
      margin-left: 20px;
    }
  }
}
