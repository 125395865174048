@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

@mixin menu-link {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: $color-white;
  padding: 16px 40px;
  padding-right: 0;
  @include transition(padding);
}

@mixin menu-link-text {
  width: 100%;
  overflow: hidden;
  @include transition(width);

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.menu-link {
  @include menu-link;
  border-radius: 0 10px 10px 0;

  @include media-breakpoint-down(md) {
    justify-content: center;
    padding: 15px 14px;
  }

  &__icon {
    color: currentColor;
    margin-right: 20px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;

    @include transition(margin-right);

    & [class*='active'] {
      display: none;

      .menu-link--is-active & {
        display: inline-block;

        & ~ .svg {
          display: none;
        }
      }
    }
  }

  &__text {
    @include menu-link-text;
  }

  &--is-active &__icon,
  &:hover &__icon {
    margin-right: 28px;
  }

  &--is-active {
    font-weight: 600;
    color: $color-primary;
    background: $color-white;
  }
}

// consultations unread comments

.menu-link[data-route='tasks']:not(.menu-link[data-unread-comments='0']):after {
  position: absolute;
  right: 14px;
  content: attr(data-unread-comments);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid;
  font-size: 12px;
  line-height: 12px;
  color: $color-white;
  font-weight: 700;
  box-shadow: 0 1px 10px rgba($color-black-200, 0.25);
  background: $color-crimson-100;
  border-color: $color-white;

  .is-short & {
    @include media-breakpoint-up(md) {
      right: 8px;
    }
  }
}

[data-sidebar-short='true'] .menu-link {
  padding-right: 46px;
  padding-left: 46px;

  &__text {
    width: 0;
  }

  &__icon {
    margin-right: 0;
  }

  &--is-active .menu-link__icon,
  &:hover .menu-link__icon {
    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }
}

.hide-menu {
  @include reset-button;
  @include menu-link;
  width: 100%;

  @include media-breakpoint-down(md) {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 16px 14px;
  }

  &__icon {
    color: currentColor;
    margin-right: 15px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
  }

  &__icon:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: currentColor;
    transform: rotate(45deg);

    @include transition(transform, margin-left);

    @include media-breakpoint-down(md) {
      transform: rotate(-135deg);
    }
  }

  &__text {
    @include menu-link-text;
    display: inline-block;
    color: rgba($color-white, 0.5);
    @include transition(margin-left, width, color);

    &:hover {
      color: $color-white;
    }
  }
}

[data-sidebar-short='true'] .hide-menu {
  &__icon {
    margin-right: 0;
  }

  &__icon:after {
    transform: rotate(-135deg);

    @include media-breakpoint-down(md) {
      transform: rotate(45deg);
    }
  }

  &__text {
    width: 0;
  }
}
