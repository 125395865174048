@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.metric-info {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  &__button {
    $size: 19px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: $color-white;
    background: $color-primary;
    transition: background-color $transition-main;

    @include media-breakpoint-up(xs) {
      $size: 26px;

      width: $size;
      height: $size;
    }

    &:hover {
      background-color: darken($color-primary, 15%);
    }
  }

  @include media-breakpoint-down(xs) {
    &__icon {
      width: 3px;
    }
  }
}
