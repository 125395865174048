@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.expand {
  $color: $color-darkgray-200;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: $color;
  @include transition(color);

  &:hover {
    color: darken($color, 15);
  }

  &__icon {
    $borderWidth: 2px;

    @include squareSize(16px);
    display: inline-block;
    border: solid currentColor;
    border-width: 0 $borderWidth $borderWidth 0;
    transform: rotate(45deg) translateY(-50%);
    margin-left: 4px;
    @include transition(transform, margin);

    &-opened {
      transform: rotate(-135deg) translateY(-50%);
      margin-left: 16px;
    }
  }
}
