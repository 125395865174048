@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.detail {
  flex-shrink: 0;
  max-width: 356px;
  width: 100%;
  border-radius: 20px;
  border: 2px solid $color-lavender-200;
  padding: 30px 25px;
  margin-left: 40px;

  @include media-breakpoint-down(lg) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: none;
    margin-top: 20px;
    margin-left: 0;
  }

  @include media-breakpoint-down(xs) {
    padding: 20px 15px;
  }
}
.card {
  margin-bottom: 20px;

  @include media-breakpoint-down(lg) {
    max-width: 356px;
    width: 100%;
    margin-bottom: 20px;
  }

  @include media-breakpoint-between(sm, lg) {
    margin-right: 20px;
  }
}
.list {
  @include media-breakpoint-down(lg) {
    flex-grow: 1;
  }

  @mixin listWithMedia() {
    width: 100%;
  }

  @include media-breakpoint-between(md, lgs) {
    @include listWithMedia();
  }
  @include media-breakpoint-down(sm) {
    @include listWithMedia();
  }
}
