@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.register {
  @include white-sheet;

  position: relative;
  width: 100%;
  max-width: $block-max-width;
  margin-right: auto;
  margin-left: auto;

  padding: 50px 160px 50px 120px;

  @include media-breakpoint-down(lg) {
    $paddingX: 5.085%;

    padding-right: $paddingX;
    padding-left: $paddingX;
  }

  @include media-breakpoint-down(xs) {
    padding: 20px;
  }
}
