@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.form {
  width: 100%;
}

.button {
  @include media-breakpoint-up(md) {
    margin-top: 50px;
  }
}
