@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.one-time {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  border-radius: 20px;
  color: $color-mediumslateblue-300;
  background-color: $color-lavender-500;
  padding: 20px;
  margin-bottom: 30px;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
  }

  &__icon {
    $size: 24px;

    width: $size;
    height: $size;
    color: currentColor;
    margin-right: 10px;

    @include media-breakpoint-down(xs) {
      margin-right: 10px;
    }
  }
}
