.support-about {
  &__list {
    margin-top: 30px;
    padding-left: 20px;
    list-style: disc;
    & li:not(:first-child) {
      margin-top: 15px;
    }
  }
}
