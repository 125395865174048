/**
 * Palette
 */

$color-lightskyblue-100: #8da0ff;
$color-orchid-100: #b982ff;
$color-mediumorchid-100: #b435f0;
$color-medium-orchid-200: #c15cff;
$color-violet-100: #d380fa;
$color-magnolia-100: #fee5ff;
$color-mediumvioletred-100: #ad2a9c;
$color-darkviolet: #9e1fda;
$color-pure-red: #ee1717;
$color-slateblue-100: #5d65e1;
$color-palesteelblue-100: #f5f6ff;
$color-lightsteelblue-100: #a1a7fd;
$color-cornflowerblue-100: #8da0ff;

$color-mediumslateblue-100: rgb(119, 127, 249);
$color-mediumslateblue-transparent-100: rgba(119, 127, 249, 0.48);
$color-mediumslateblue-transparent-200: rgba(119, 127, 249, 0.2);

$color-mediumslateblue-200: #7155ef;
$color-mediumslateblue-300: #777ff9;
$color-thistle-100: #d0c2ff;
$color-skyblue-100: #8ae0ec;
$color-mediumturquoise-100: #2bbae8;
$color-mediumturquoise-200: #34cbc2;
$color-limegreen-100: #2edf40;
$color-limegreen-200: #19bb4f;
$color-lightseagreen-100: #009cc6;
$color-aquamarine-100: #69e5de;
$color-aliceblue-100: #f0f6ff;
$color-azure-100: #f0fdff;
$color-crimson-100: #f02660;
$color-black-100: #212121;
$color-black-200: #000000;
$color-palevioletred-100: #eb6088;
$color-lightpink-100: #fdaaaa;
$color-yellow: #ffe600;
$color-white-100: #fff;
$color-ghostwhite-100: #f5f5f5;
$color-ghostwhite-200: #fbfcff;
$color-lavender-100: #e9ecef;
$color-lavender-200: #ebedff;
$color-lavender-300: #dee1fd;
$color-lavender-400: #cbd0ff;
$color-lavender-500: #e4e7ff;
$color-lavender-blush-100: #ffebf4;
$color-gainsboro-100: #dcdcdc;
$color-snow-100: #fff8fa;
$color-silver-100: #c4c4c4;
$color-darkgray-100: #b2b2b2;
$color-darkgray-200: #979797;
$color-lightslategray-100: #949494;
$color-lightslategray-200: #78879f;
$color-lightslategray-300: #809090;
$color-gray-100: #909090;
$color-gray-50: #868686;
$color-darkslateblue-100: #677a8e;
$color-darkslategray-100: #262626;
$color-darkslategray-200: #464d5d;
$color-orange: #ff8125;
$color-deepskyblue-100: #1dc3f8;

/**
* Theming
*/

$color-primary: $color-mediumslateblue-100;
$color-primary-transparent: $color-mediumslateblue-transparent-100;
$color-black: $color-black-100;
$color-white: $color-white-100;
$color-text: $color-black-100;
$color-error: $color-crimson-100;
$color-disabled: #e9ecef;
//$color-success: $color-green-400;

/**
 * Animation
 */

$transition-main: 0.3s cubic-bezier(0.16, 0.16, 0.4, 0.82);

/**
 * Font
 */

// sizes

$root-font-size: 16px;
$font-size: 16px;

// families

$font-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-roboto: 'Roboto';
$font-opensans: 'Open Sans';
$font-main: $font-roboto, $font-fallback;
$font-secondary: $font-opensans, $font-fallback;

/**
 * Grid
 */

$container-max-width: 1920px;
$block-max-width: 1240px;
$grid-columns-count: 12;
$grid-gap: 15px;
$grid-gap-small: 12px;
$grid-gap-medium: 30px;
$grid-gap-large: 40px;

/**
 * Breakpoins
 */

$media-xxl: 1700px;
$media-xl: 1440px;
$media-lg: 1280px;
$media-lgs: 1040px;
$media-md: 992px;
$media-sm: 767px;
$media-xs: 576px;
$media-xxs: 480px;
$media-xxxs: 374px;

$media-sizes: (
  'xxl': $media-xxl,
  'xl': $media-xl,
  'lg': $media-lg,
  'lgs': $media-lgs,
  'md': $media-md,
  'sm': $media-sm,
  'xs': $media-xs,
  'xxs': $media-xxs,
  'xxxs': $media-xxxs,
);

/**
 * Mixed
 */

$scrollbar-width: 5px;
