@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.sidebar {
  $sidebarWidth: 300px;
  $mobileMenuMarginTop: 61px;
  height: 100%;
  ::selection {
    background-color: $color-slateblue-100;
    color: $color-white-100;
  }

  @include media-breakpoint-down(md) {
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      left: -100vw;
      top: $mobileMenuMarginTop;
      z-index: 10;
      display: block;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      border-top: 1px solid #fff;
      background: rgba(0, 0, 0, 0.5);
      @include transition(opacity);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: $sidebarWidth;
    height: inherit;
    font-family: $font-secondary;
    color: $color-white;
    background: linear-gradient(
      180deg,
      $color-lightskyblue-100 0%,
      $color-orchid-100 100%
    );
    padding: 30px 0 20px 0;
    @include transition(width, padding-top);

    @include media-breakpoint-down(md) {
      position: relative;
      z-index: 20;
      height: calc(100vh - #{$mobileMenuMarginTop});
      width: 267px;
      will-change: auto;
      border-top: 1px solid;
      border-color: $color-white;
      padding-top: 34px;
      padding-right: 15px;
      padding-bottom: 30px;
      margin-top: $mobileMenuMarginTop;
      overflow: auto;
      @include transition(width, padding-top, transform);
    }
  }

  &__logo-wrapper {
    font-size: 0;
    line-height: 1;
    margin: 0 auto 40px;
    transition: margin-bottom $transition-main;
  }

  &__logo {
    width: 121px;
    @include transition(width, margin-bottom);
  }

  &__main {
    flex-grow: 1;
    padding-right: 20px;

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-grow: 0;
    }
  }

  &__footer {
    padding: 0 20px;
    max-width: 270px;
  }

  &__logout-btn {
    padding-top: 32px;

    @include media-breakpoint-down(xs) {
      padding-top: 16px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__footnote {
    transition: opacity 0.1s linear 0.3s;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__footnote-full {
    position: static;
    z-index: 1;
    opacity: 1;
    pointer-events: auto;
  }

  &__footnote-short {
    z-index: -1;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transition: opacity 0.1s linear 0.3s;
  }
}

.sidebar.is-visible {
  @include media-breakpoint-down(md) {
    pointer-events: auto;

    &:after {
      opacity: 1;
    }

    .sidebar__wrapper {
      transform: translateX(-100%);
    }
  }
}

[data-sidebar-short='true'] .sidebar {
  .sidebar__wrapper {
    width: 140px;
    padding-top: 40px;

    @include media-breakpoint-down(md) {
      width: 143px;
      padding-top: 34px;
    }
  }

  .sidebar__footnote-short {
    position: static;
    z-index: 1;
    opacity: 1;
    pointer-events: auto;
  }

  .sidebar__footnote-full {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  .sidebar__logo-wrapper {
    margin-bottom: 50px;
  }

  .sidebar__logo {
    width: 90px;
  }
}
