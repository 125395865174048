@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.switcher {
  display: flex;
  align-items: center;
  $color: $color-gainsboro-100;

  &__line {
    position: relative;
    width: 40px;
    height: 10px;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: rgba($color, 0.5);

    .switcher__changing & {
      transition: background-color $transition-main;
    }

    &:after {
      $size: 20px;

      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 10;
      width: $size;
      height: $size;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: $color;
      box-shadow: 1px 1px 2px rgba($color-black-200, 0.25);
      transform: translate(0, -50%);

      .switcher__changing & {
        @include transition(left, transform, background-color);
      }
    }
  }
  &__original:checked ~ .switcher__line {
    $color: $color-primary;
    background: $color;
    &:after {
      left: 100%;
      background: $color;
      transform: translate(-100%, -50%);
    }
  }
}
