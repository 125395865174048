.block {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include white-sheet;
  @include media-breakpoint-down(lg) {
    $paddingX: 5.085%;

    padding-right: $paddingX;
    padding-left: $paddingX;
  }

  @include media-breakpoint-down(xs) {
    padding: 20px;
  }

  &__columns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__column-left {
    margin-right: 15px;

    @include media-breakpoint-down(xl) {
      margin-right: 14.968%;
    }

    @include media-breakpoint-down(lg) {
      flex-shrink: 0;
      margin-right: 30px;
    }

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  &__column-right {
    flex-grow: 1;

    @include media-breakpoint-down(md) {
      max-width: none;
      width: 100%;
    }
  }
}
