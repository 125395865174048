@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.notifications {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 40;

  &__position-top {
    top: 0;
  }
  &__position-bottom {
    bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    color: $color-white;
    padding: 7px 40px;
  }

  &__item-error {
    background-color: $color-crimson-100;
  }

  &__item-done {
    background-color: $color-limegreen-200;
  }

  &__item-text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
  &__item:not(:last-child) {
    border-bottom: 1px solid $color-white;
  }

  &__item-delete {
    $size: 10px;
    $margin: -3px;

    flex-shrink: 0;
    position: relative;
    width: $size;
    height: $size;
    cursor: pointer;
    margin-right: 30px;

    @mixin pseudo {
      content: '';
      position: absolute;
      top: 50%;
      z-index: 10;
      display: block;
      width: 16px;
      height: 1px;
      background: $color-white;
      transition: background-color 0.2s linear;
    }

    @mixin transform($revert: false) {
      $degree: 45deg;
      $rotate: $degree;

      @if $revert {
        $rotate: -$degree;
      }

      transform: translateY(-50%) rotate(#{$rotate});
    }

    &:before {
      @include pseudo;
      @include transform();
      left: $margin;
    }
    &:after {
      @include pseudo;
      @include transform(true);
      right: $margin;
    }

    &:hover:before,
    &:hover:after {
      background: $color-primary;
    }
  }
}

.notifications-anim-enter {
  opacity: 0.01;
}

.notifications-anim-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
}

.notifications-anim-exit {
  opacity: 1;
}

.notifications-anim-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
}
