@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.image {
  width: unset;

  @include media-breakpoint-up(sm) {
    align-self: flex-start;
  }

  @include media-breakpoint-down(sm) {
    max-width: 151px;
    padding: 11px 14px;
    margin-bottom: 32px;
    align-self: center;
    display: block;

    & :global(img) {
      width: 100%;
    }
  }
}

.content {
  padding: 0;

  @include media-breakpoint-up(sm) {
    flex: 1;
    justify-content: space-between;
  }
}

.modal {
  & :global(.modal__text) {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    /* min-height: 530px; */
  }
}

.form {
  height: 100%;

  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    align-items: flex-start;
  }

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }
}

.header {
  @include media-breakpoint-up(md) {
    margin-bottom: 5px;
  }
}
