@import '@styles/abstracts/variables.scss';
@import '@styles/abstracts/mixins.scss';

.data-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;

  @include media-breakpoint-down(sm) {
    padding-right: 10px;
    height: 77%;
  }

  &__item-container {
    transition: background-color 0.4s, border 0.2s;
  }

  &__item-container.active {
    width: 100%;
    background-color: $color-palesteelblue-100;
    border-radius: 0 10px 10px 0;
    border-color: transparent;
  }

  &__item-container:first-child > &__item,
  &__item-container.active > &__item,
  &__item-container.active + &__item-container > &__item {
    border-color: transparent;
  }
  &__item {
    cursor: pointer;
    padding: 16px 23px 14px 0;
    margin-left: 30px;
    width: calc(100% - 30px);
    border-top: 1px solid $color-gainsboro-100;
    display: flex;
    justify-content: space-between;
    transition: border-color 0.4s;

    @include media-breakpoint-down(md) {
      margin-left: 20px;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 10px;
      padding-bottom: 8px;
    }
  }
  &__item-container--decryption &__item {
    padding-left: 20px;
  }
  &__block {
    width: calc(100% - 10px);
    padding-right: 10px;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  &__name {
    font-weight: 500;
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-text;
    word-break: break-word;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
  &__status {
    margin-bottom: 8px;
    color: $color-darkgray-200;
    position: relative;
  }
  &__status-svg {
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-40%);
    .svg-status-processing {
      width: 20px;
      height: 20px;
      color: $color-primary;
    }
    .svg-status-fail {
      width: 17px;
      height: 17px;
      color: $color-error;
    }
    .svg-status-done {
      width: 20px;
      height: 20px;
      color: $color-limegreen-100;
    }
  }

  &__date {
    font-size: $font-size;
    color: $color-darkgray-200;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  &__circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 6px;
    margin-bottom: 4px;
    text-align: center;
    &--error {
      color: $color-white;
      background-color: $color-crimson-100;
    }
  }

  &__arrow-btn {
    $size: 10px;
    display: flex;
    width: $size;
    flex-shrink: 0;
    &::after {
      content: '';
      display: block;
      margin: auto 0;
      $size: 10px;
      $borderSize: 1px;
      width: $size;
      height: $size;
      border-style: solid;
      border-width: 0 0 $borderSize $borderSize;
      border-color: $color-primary;
      transform: rotate(-135deg);
    }
  }
}
